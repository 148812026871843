import React from "react";
import { Redirect, Route } from "react-router-dom";

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  const accessToken = localStorage.getItem("access-token");

  return (
    <Route
      {...rest}
      render={(props) =>
        !accessToken ? <Redirect to="/" /> : <Component {...props} />
      }
    />
  );
};
