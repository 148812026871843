import axios from "axios";
import API_URL from "../../api/api.constants";
import { FETCH_DASHBOARD_SUCCESS } from "./actions.constants";

export const fetchDashboardItems = () => (dispatch) => {
  return axios
    .get(`${API_URL.dashboard}`)
    .then((res) => {
      return (
        res.status === 200 &&
        dispatch({
          type: FETCH_DASHBOARD_SUCCESS,
          operational: res.data?.data?.operational || {},
          revenue: res.data?.data?.revenue || {},
        })
      );
    })
    .catch((error) => console.log("error", error));
};
