import React, { useEffect, useCallback, useState } from "react";

import { Categories, MainContent, Grid } from "@vms-admin/components";
import { useDispatch, useSelector } from "react-redux";
import {
  createClientCategory,
  deleteClientCategory,
  editClientCategory,
  fetchClientCategories,
} from "../../redux/actions";
import toast from "react-hot-toast";
import { dismissToast, somethignWentWrong } from "../../helpers";

export const ClientSettings = () => {
  const [isClientEditModalOpen, setClientEditIsModalOpen] = useState(false);
  const [isClientCreateOpen, setIsClientCreateOpen] = useState(false);
  const dispatch = useDispatch();
  const clientCategories = useSelector(({ clients }) => clients.categories);
  const handleClientEditModal = useCallback(
    () => setClientEditIsModalOpen(!isClientEditModalOpen),
    [isClientEditModalOpen]
  );

  const handleClientModal = useCallback(
    () => setIsClientCreateOpen(!isClientCreateOpen),
    [isClientCreateOpen]
  );

  const handleCreateClientCategory = useCallback(
    (data) => {
      dismissToast();
      dispatch(createClientCategory(data))
        .then((res) => {
          if (res.status === 500) {
            setIsClientCreateOpen(true);
            toast.error("Category Name already exists");
          } else {
            setIsClientCreateOpen(false);
            dispatch(fetchClientCategories());
            toast.success(`${data.categoryName} has been created Successfully`);
          }
        })
        .catch(() => somethignWentWrong());
    },
    [dispatch]
  );

  const handleDeleteClientCategory = useCallback(
    (id) => {
      dismissToast();
      dispatch(deleteClientCategory(id))
        .then((res) => {
          if (res.status === 200) {
            dispatch(fetchClientCategories());
            toast.success("Client Category has been deleted Successfully");
          }
        })
        .catch(() => somethignWentWrong());
    },
    [dispatch]
  );

  const handleEditClientCategory = useCallback(
    (id, categoryName) => {
      dismissToast();
      dispatch(editClientCategory(id, categoryName))
        .then((res) => {
          if (res.status === 500) {
            setClientEditIsModalOpen(true);
            toast.error("Client Category already exists");
          } else {
            setClientEditIsModalOpen(false);
            dispatch(fetchClientCategories());
            toast.success(`${categoryName} has been updated Successfully`);
          }
        })
        .catch((error) => console.log("error", error));
    },
    [dispatch]
  );

  useEffect(() => dispatch(fetchClientCategories()), [dispatch]);

  return (
    <MainContent>
      <Grid>
        <Categories
          title="Client Categories"
          categories={clientCategories}
          type="Client"
          createCategory={handleCreateClientCategory}
          deleteCategory={handleDeleteClientCategory}
          editCategory={handleEditClientCategory}
          handleModal={handleClientModal}
          isModalOpen={isClientCreateOpen}
          isEditModalOpen={isClientEditModalOpen}
          handleIsEditModal={handleClientEditModal}
        />
      </Grid>
    </MainContent>
  );
};

export default ClientSettings;
