import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  TableCard,
  MainContent,
  Button,
  Modal,
  ToolbarButton,
  Buttons,
  TableCardLoader,
} from "@vms-admin/components";
import { deleteCustomer, fetchCustomers } from "../../redux/actions";
import toast from "react-hot-toast";
import {
  active,
  dismissToast,
  inActive,
  somethignWentWrong,
} from "../../helpers";

export const Customers = () => {
  const customers = useSelector(({ customer }) => customer);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [target, setTarget] = useState({ name: "", id: 0 });
  const dispatch = useDispatch();

  const toggleModal = useCallback(
    () => setIsModalOpen(!isModalOpen),
    [isModalOpen]
  );

  const handleDeleteCustomer = useCallback(
    (id) => {
      dismissToast();
      dispatch(deleteCustomer(id))
        .then((res) => {
          if (res.status === 204) {
            toggleModal();
            dispatch(fetchCustomers());
            toast.success("Customer Deleted Successfully");
          }
        })
        .catch(() => somethignWentWrong());
    },
    [dispatch, toggleModal]
  );

  useEffect(() => dispatch(fetchCustomers()), [dispatch]);

  return (
    <MainContent>
      <TableCard title="Customer Management">
        {!customers?.customers?.length && !customers.customersLoading && (
          <tbody>
            <tr>
              <td className="empty_list_message">No Customers found</td>
            </tr>
          </tbody>
        )}
        {!customers.customersLoading ? (
          <>
            <thead>
              <tr>
                <th>#</th>
                <th>Customer Name</th>
                <th>Email Address</th>
                <th>Status</th>
                <td>Actions</td>
              </tr>
            </thead>
            <tbody>
              {customers?.customers?.map(
                ({ firstname, lastname, email, confirmed, id }, index) => (
                  <tr key={id}>
                    <td>{index + 1}</td>
                    <td>
                      {firstname} {lastname}
                    </td>
                    <td>{email}</td>
                    <td>
                      {confirmed ? active("Confirmed") : inActive("Pending")}
                    </td>
                    <td>
                      <ToolbarButton
                        btns={[
                          {
                            btnUrl: `/customer/${id}`,
                            btnText: "View",
                          },
                          {
                            handleClick: () => {
                              toggleModal();
                              setTarget({ id, name: firstname });
                            },
                            btnText: "Delete",
                          },
                        ]}
                      />
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </>
        ) : (
          <TableCardLoader />
        )}
      </TableCard>
      {isModalOpen && (
        <Modal title="Delete">
          Are you sure you want to delete {target.name}?
          <Buttons>
            <Button variant="secondary" handleClick={toggleModal}>
              Cancel
            </Button>
            <Button
              variant="primary"
              handleClick={() => handleDeleteCustomer(target.id)}
            >
              Confirm
            </Button>
          </Buttons>
        </Modal>
      )}
    </MainContent>
  );
};

export default Customers;
