import axios from "axios";
import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams, useHistory, Link } from "react-router-dom";
import API_URL from "../../api/api.constants";
import { loginUser } from "../../redux/actions";

import { Header } from "@vms-admin/components";
import { Success } from "@vms-admin/icons";

import * as Styled from "./confirmation.style";

export const ConfirmationSuccessful = () => {
  const { token } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const confirmEmail = useCallback(
    () =>
      axios(`${API_URL.confirmEmail}/${token}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          if (res.status === 200) {
            dispatch(loginUser());
            if (res.headers.get("access-token")) {
              localStorage.setItem(
                "access-token",
                res.headers.get("access-token")
              );
            }
            setTimeout(() => {
              history.push("/change-password");
            }, 10000);
          }
          if (res.status === 401) {
            history.push("/resend-confirmation");
          }
        })
        .catch((error) => console.log("Error", error)),
    [token, history, dispatch]
  );

  useEffect(() => confirmEmail(), [confirmEmail]);

  return (
    <>
      <Header />
      <Styled.Wrapper>
        <Styled.Icon>
          <Success />
        </Styled.Icon>
        <Styled.Title>Password Reset</Styled.Title>
        <Styled.Text>
          Your new password has been reset successfully. You will be redirected
          soon.
        </Styled.Text>
      </Styled.Wrapper>
    </>
  );
};

export default ConfirmationSuccessful;
