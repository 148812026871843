import {
  FETCH_SUBSCRIPTIONS_SUCCESS,
  FETCH_SUBSCRIPTIONS_LOADING,
} from "../actions/actions.constants";

const initialState = {
  subscriptions: [],
  loading: false,
};

const subscriptionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SUBSCRIPTIONS_SUCCESS:
      return { ...state, subscriptions: action.data, loading: false };
    case FETCH_SUBSCRIPTIONS_LOADING:
      return { ...state, loading: true };
    default:
      return state;
  }
};
export default subscriptionsReducer;
