import {
  FETCH_COUNTRIES_INFO_LOADING,
  FETCH_COUNTRIES_INFO_SUCCESS,
  FETCH_COUNTRIES_SUCCESS,
  FETCH_CURRENCIES_SUCCESS,
} from "../actions/actions.constants";

const initialState = {
  countries: [],
  currencies: [],
  countryInfo: {},
};

const countriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COUNTRIES_SUCCESS:
      return { ...state, countries: action.countries };
    case FETCH_CURRENCIES_SUCCESS:
      return { ...state, currencies: action.currencies };
    case FETCH_COUNTRIES_INFO_SUCCESS:
      return {
        ...state,
        countryInfo: action.countryInfo,
        countryInfoLoading: false,
      };
    case FETCH_COUNTRIES_INFO_LOADING:
      return {
        ...state,
        countryInfo: {
          cities: [],
          currencies: [],
        },
        countryInfoLoading: true,
      };
    default:
      return state;
  }
};
export default countriesReducer;
