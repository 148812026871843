import React from "react";

export const Notification = ({ width = 19, height = 18 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 19 18"
  >
    <g
      id="Group_20186"
      data-name="Group 20186"
      transform="translate(-1634 -32)"
    >
      <path
        id="Path_25544"
        data-name="Path 25544"
        d="M11.627,2.817a1.66,1.66,0,0,0,.01-.18,1.636,1.636,0,1,0-3.273,0,1.66,1.66,0,0,0,.01.18A7.373,7.373,0,0,0,2.7,10.938,2.456,2.456,0,0,0,1,13.273v3.273A2.455,2.455,0,0,0,3.455,19H16.545A2.454,2.454,0,0,0,19,16.545V13.273a2.456,2.456,0,0,0-1.7-2.335,7.373,7.373,0,0,0-5.678-8.121ZM10,4.273A5.727,5.727,0,0,0,4.273,10a5.79,5.79,0,0,0,.058.818H15.67A5.791,5.791,0,0,0,15.727,10,5.727,5.727,0,0,0,10,4.273Zm-7.364,9a.818.818,0,0,1,.818-.818H16.545a.818.818,0,0,1,.818.818v3.273a.818.818,0,0,1-.818.818H3.455a.818.818,0,0,1-.818-.818Z"
        transform="translate(1633 31)"
        fill="#333"
        fillRule="evenodd"
      />
      <g
        id="Ellipse_475"
        data-name="Ellipse 475"
        transform="translate(1646 33)"
        fill="#ff3636"
        stroke="#f4f4f4"
        strokeWidth="1.2"
      >
        <circle cx="3.5" cy="3.5" r="3.5" stroke="none" />
        <circle cx="3.5" cy="3.5" r="2.9" fill="none" />
      </g>
    </g>
  </svg>
);
