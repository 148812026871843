import { motion } from "framer-motion";
import styled from "styled-components";

export const Label = styled.label`
  margin-bottom: 10px;
  display: block;
  font-size: ${(props) => props.theme.fontSizes[2]}px;
`;

export const UploadWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export const UploadField = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 200px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23ACACACFF' stroke-width='1' stroke-dasharray='4' stroke-dashoffset='38' stroke-linecap='square'/%3e%3c/svg%3e");
  width: 100%;

  border-radius: 7px;
  margin-bottom: ${(props) => props.theme.space[3]}px;
  transition: 0.3s;
  margin-right: ${(props) => props.theme.space[3]}px;
  &:last-child {
    margin-right: 0;
  }
`;

export const UploadedImages = styled.div`
  display: flex;
  img {
    width: 100px;
  }
`;

export const ImgWrapper = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 200px;
  width: 100%;
  border: 1px dashed #eaeaea;
  border-radius: 7px;
  transition: 0.3s;
`;

export const Input = styled.input`
  position: relative;
  z-index: 999;
  width: 100%;
  height: 100%;
  opacity: 0;
`;

export const ButtonsWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const UWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 25px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  background: rgba(255, 255, 255, 0.5);
`;
export const Icon = styled.div`
  margin-bottom: ${(props) => props.theme.space[2]}px;
`;
export const Text = styled.span`
  color: #8e8e8e;
  font-size: 14px;
  max-width: 217px;
  text-align: center;
  line-height: 20px;
`;

export const UploadedImg = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

export const ImagesPreview = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Image = styled.img`
  width: 36px;
  border-radius: 5px;
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eaeaea;
  padding: 12px 0;
  &:last-child {
    border-bottom: none;
  }
`;
