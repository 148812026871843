import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { MainContent, TableCard, TableCardLoader } from "@vms-admin/components";
import { formatDate, formatTime } from "../../helpers";
import { fetchRedeemedVouchers } from "../../redux/actions";

export const VoucherRedemptions = () => {
  const dispatch = useDispatch();
  const vouchers = useSelector(({ voucher }) => voucher);

  useEffect(() => dispatch(fetchRedeemedVouchers()), [dispatch]);

  return (
    <MainContent>
      <TableCard title="Voucher Redemptions">
        {!vouchers?.redeemedVouchers?.length &&
          !vouchers.redeemedVouchersLoading && (
            <tbody>
              <tr>
                <td className="empty_list_message">
                  No Redeemed Vouchers found
                </td>
              </tr>
            </tbody>
          )}
        {!vouchers.redeemedVouchersLoading ? (
          <>
            <thead>
              <tr>
                <th>#</th>
                <th>Customer Name</th>
                <th>Customer Email</th>
                <th>Voucher Name</th>
                <th>Merchant Name</th>
                <th>Merchant Email</th>
                <th>Redeemption Date</th>
              </tr>
            </thead>
            <tbody>
              {vouchers?.redeemedVouchers?.map(
                ({ customers, voucher, redemption_time, id }, index) => (
                  <tr key={id}>
                    <td>{index + 1}</td>
                    <td>
                      {customers.firstname} {customers.lastname}
                    </td>
                    <td>{customers.email}</td>
                    <td>{voucher.voucher_set_name}</td>
                    <td>{voucher.merchant.merchant_name}</td>
                    <td>{voucher.merchant.email}</td>
                    <td>
                      {formatDate(redemption_time)}{" "}
                      {formatTime(redemption_time)}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </>
        ) : (
          <TableCardLoader />
        )}
      </TableCard>
    </MainContent>
  );
};

export default VoucherRedemptions;
