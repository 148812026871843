import { css } from "styled-components";

const pagination = css`
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 25px;
    padding: 16px;

    li {
      color: ${(props) => props.theme.colors.primary};
      border: 1px solid ${(props) => props.theme.colors.primary};
      border-radius: 7px;
    }

    li a {
      cursor: pointer;
      padding: 10px 20px;
      display: inline-block;
      transition: 0.3s;
    }

    .next,
    .previous {
      background-color: ${(props) => props.theme.colors.primary};
      color: ${(props) => props.theme.colors.white};
      border-radius: 7px;
      font-size: 16px;

      &.disabled {
        color: ${(props) => props.theme.colors.black};
        background-color: ${(props) => props.theme.colors.gray};
        border-color: ${(props) => props.theme.colors.gray};
      }
    }

    .selected {
      background-color: ${(props) => props.theme.colors.primary};
      color: ${(props) => props.theme.colors.white};
      border-radius: 7px;
    }
  }
`;

export default pagination;
