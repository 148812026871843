import React from "react";

export const Mastercard = ({ width = 19, height = 18 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21.082"
    height="13.029"
    viewBox="0 0 21.082 13.029"
  >
    <g id="mastercard-seeklogo.com" transform="translate(0 0)">
      <rect
        id="Rectangle_7892"
        data-name="Rectangle 7892"
        width="5.7"
        height="10.242"
        transform="translate(7.691 1.393)"
        fill="#ff5f00"
      />
      <path
        id="Path_25652"
        data-name="Path 25652"
        d="M8.053,6.514a6.532,6.532,0,0,1,2.479-5.121A6.512,6.512,0,1,0,6.514,13.029a6.475,6.475,0,0,0,4.017-1.393A6.5,6.5,0,0,1,8.053,6.514Z"
        fill="#eb001b"
      />
      <path
        id="Path_25653"
        data-name="Path 25653"
        d="M138.515,6.514a6.508,6.508,0,0,1-10.532,5.121,6.529,6.529,0,0,0,0-10.242,6.508,6.508,0,0,1,10.532,5.121Z"
        transform="translate(-117.433)"
        fill="#f79e1b"
      />
    </g>
  </svg>
);
