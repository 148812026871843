import {
  LOGGED_IN_SUCCESS,
  LOGGED_OUT_SUCCESS,
  FETCH_USER_INFO_SUCCESS,
  SET_USER_ROLE_SUCCESS,
} from "../actions/actions.constants";

const initialState = {
  isAuthenticated: false,
  userInfo: {},
  role: "",
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGGED_IN_SUCCESS:
      return { ...state, ...action.payload };
    case LOGGED_OUT_SUCCESS:
      return { ...state, ...action.payload };
    case FETCH_USER_INFO_SUCCESS:
      return { ...state, userInfo: action.data.data };
    case SET_USER_ROLE_SUCCESS:
      return { ...state, role: { [`is_${action.role}`]: true } };
    default:
      return state;
  }
};
export default authReducer;
