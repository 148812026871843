import { React } from "react";

import { Grid, Card } from "@vms-admin/components";
import Skeleton from "react-loading-skeleton";

export const MerchantDetailsLoader = () => (
  <div className="cards_wrapper">
    <Card>
      <Grid columns={2}>
        <div>
          <Skeleton
            width={100}
            height={15}
            style={{ marginBottom: 10, marginTop: 8 }}
          />
          <Skeleton width="100%" height={40} />
        </div>
        <div>
          <Skeleton
            width={100}
            height={15}
            style={{ marginBottom: 10, marginTop: 8 }}
          />
          <Skeleton width="100%" height={40} />
        </div>
      </Grid>
      <>
        <div>
          <Skeleton
            width={100}
            height={15}
            style={{ marginBottom: 10, marginTop: 32 }}
          />
          <Skeleton width={60} height={60} style={{ borderRadius: "50%" }} />
        </div>
      </>
    </Card>
    <Card title="Location">
      <Skeleton width="100%" height={37} style={{ marginBottom: 10 }} />
      <Skeleton width="100%" height={400} />
    </Card>
  </div>
);
