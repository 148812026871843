import axios from "axios";
import React, { useCallback } from "react";
import { useHistory } from "react-router";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import API_URL from "../../api/api.constants";
import { Input, Button } from "@vms-admin/components";
import { Key, LeftArrow } from "@vms-admin/icons";
import { changePasswordSchema } from "../../schema";
import { dismissToast, somethignWentWrong } from "../../helpers";
import * as Styled from "./change-password-form.style";
import { Link } from "react-router-dom";

export const ChangePasswordForm = () => {
  const accessToken = localStorage.getItem("access-token");
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(changePasswordSchema),
  });

  const changePassword = useCallback(
    (data) => {
      dismissToast();
      axios(`${API_URL.changePassword}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          old_password: data.oldPassword,
          new_password: data.newPassword,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            history.push("/dashboard-operational");
          }
          if (res.status === 409) {
            toast.error("Old password is not correct");
          } else if (res.status === 401) {
            history.push("/");
          }
        })
        .catch(() => somethignWentWrong());
    },
    [accessToken, history]
  );
  return (
    <Styled.Wrapper>
      <Styled.Icon>
        <Key />
      </Styled.Icon>
      <Styled.Title>Set new password</Styled.Title>
      <Styled.Text>
        Your new password must be different to the previously used password
      </Styled.Text>
      <Styled.Form onSubmit={handleSubmit(changePassword)}>
        <Input
          type="password"
          label="Old Password"
          placeholder="Old Password"
          name="oldPassword"
          register={register}
          errors={errors}
        />
        <Input
          type="password"
          label="New Password"
          placeholder="New Password"
          name="newPassword"
          register={register}
          errors={errors}
        />
        <Input
          type="password"
          label="Confirm New Password"
          placeholder="Confirm New Password"
          name="confirmPassword"
          register={register}
          errors={errors}
        />
        <Button type="submit" variant="primary" block>
          Reset Password
        </Button>
      </Styled.Form>
      <Link to="/">
        <Styled.Link>
          <LeftArrow />
          <Styled.LinkText>Back to login</Styled.LinkText>
        </Styled.Link>
      </Link>
    </Styled.Wrapper>
  );
};

export default ChangePasswordForm;
