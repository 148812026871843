import React from "react";

import { ChangePasswordForm, Header } from "@vms-admin/components";

export const ChangePassword = () => (
  <>
    <Header />
    <ChangePasswordForm />
  </>
);

export default ChangePassword;
