import React from "react";

export const Subscription = ({ width = 19, height = 18 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
  >
    <g
      id="Group_19936"
      data-name="Group 19936"
      transform="translate(20194 4190)"
    >
      <rect
        id="Rectangle_5824"
        data-name="Rectangle 5824"
        width="25"
        height="25"
        transform="translate(-20194 -4190)"
        fill="rgba(255,255,255,0)"
      />
      <g id="noun_Repeat_2732766" transform="translate(-20230.26 -4226.146)">
        <path
          id="Path_25579"
          data-name="Path 25579"
          d="M41.972,41.436a.634.634,0,0,1,.9,0L45.4,43.972a.634.634,0,0,1-.9.9l-1.454-1.454v11.36h6.974a.634.634,0,0,1,0,1.268H42.42a.634.634,0,0,1-.634-.634V43.415l-1.454,1.454a.634.634,0,1,1-.9-.9Zm4.886.448a.634.634,0,0,1,.634-.634H55.1a.634.634,0,0,1,.634.634V53.878l1.454-1.454a.634.634,0,1,1,.9.9l-2.536,2.536a.634.634,0,0,1-.9,0l-2.536-2.536a.634.634,0,0,1,.9-.9l1.454,1.454V42.518H47.492A.634.634,0,0,1,46.858,41.884Z"
          fill="#333"
          fillRule="evenodd"
        />
      </g>
    </g>
  </svg>
);
