import { FETCH_SIDEBAR_SUCCESS } from "../actions/actions.constants";

const initialState = {
  sidebarItems: [],
};

const sidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SIDEBAR_SUCCESS:
      return { ...state, sidebarItems: action.sidebarItems };
    default:
      return state;
  }
};
export default sidebarReducer;
