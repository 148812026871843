import { css } from "styled-components";

const datepicker = css`
  .react-datepicker-wrapper {
    font-family: "Yellix";
    .react-datepicker__input-container input {
      width: 100%;
      height: 40px;
      color: ${(props) => props.theme.colors.black};
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 0.25rem;
      padding-top: 11px;
      padding-bottom: 14px;
      padding-left: ${(props) => props.theme.space[3]}px;
      border-radius: 7px;
    }
  }
  .react-datepicker {
    border: 1px solid ${(props) => props.theme.colors.gray};
    .react-datepicker__header {
      background-color: ${(props) => props.theme.colors.white};
      padding-top: ${(props) => props.theme.space[3]}px;
      padding-bottom: 0;
      border-bottom: none;
      .react-datepicker__current-month {
        font-weight: 600;
        color: #5f5f5f;
        font-size: 13px;
        margin-top: -5px;
        margin-bottom: ${(props) => props.theme.space[2]}px;
      }
    }
    .react-datepicker__triangle {
      transform: translate3d(0, 0, 0) !important;
      left: auto !important;
      &:before,
      &:after {
        left: 0;
      }
    }
    .react-datepicker__navigation-icon:before,
    .react-datepicker__navigation-icon:after {
      border-color: #333;
      height: 6px;
      width: 6px;
    }
  }
  react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::before,
  react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::after {
    left: 8px;
  }

  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::before {
    border-bottom-color: ${(props) => props.theme.colors.gray};
  }
  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::after {
    border-bottom-color: ${(props) => props.theme.colors.white};
  }
  .react-datepicker__day {
    transition: 0.3s;
    margin: 5px;

    &:hover {
      border-radius: 50%;
    }
  }
  .react-datepicker__day-name {
    margin: 5px;
    font-weight: 600;
    color: #5f5f5f;
  }
  .react-datepicker__day--selected {
    border-radius: 50%;
    background-color: #4b42ae;
    &:hover {
      border-radius: 50%;
      background-color: #3c358b;
    }
  }
`;

export default datepicker;
