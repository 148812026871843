import React from "react";
import Skeleton from "react-loading-skeleton";

import * as Styled from "./mobile.style";

export const MobileLoader = () => {
  return (
    <Styled.Mobile>
      <Styled.SwiperWrapper>
        <Skeleton
          height={200}
          width={200}
          style={{
            borderRadius: "50%",
            margin: "auto",
            marginBottom: 30,
            marginTop: 30,
          }}
        />
      </Styled.SwiperWrapper>
      <Styled.Body>
        <Styled.MerchantName>
          <Skeleton height={15} width="100%" />
        </Styled.MerchantName>
        <Styled.MerchantAddress>
          <Skeleton height={15} width="100%" style={{ marginBottom: 8 }} />
          <Skeleton height={15} width={100} />
        </Styled.MerchantAddress>
        <Styled.Category>
          <Skeleton height={15} width={100} />
          <Skeleton height={25} width={25} style={{ borderRadius: "50%" }} />
        </Styled.Category>
        <Styled.VoucherDetails>
          <Styled.Heading>
            <Skeleton height={15} width={100} />
          </Styled.Heading>
          <Styled.Wrapper>
            <Styled.Title>
              <Skeleton height={15} width={80} />
            </Styled.Title>
            <Styled.Text>
              <Skeleton height={15} width={200} />
            </Styled.Text>
          </Styled.Wrapper>
          <Styled.Wrapper>
            <Styled.Title>
              <Skeleton height={15} width={80} />
            </Styled.Title>
            <Styled.Text>
              <Skeleton height={15} width={200} />
            </Styled.Text>
          </Styled.Wrapper>
        </Styled.VoucherDetails>
      </Styled.Body>
    </Styled.Mobile>
  );
};

export default MobileLoader;
