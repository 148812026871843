import React, { useMemo, useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { useInView } from "react-intersection-observer";
import Select from "react-select";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "react-datepicker/dist/react-datepicker.css";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";

import {
  Switch,
  MultipleUpload,
  Mobile,
  Card,
  Grid,
  Map,
  ErrorMessage,
  InputRangeField,
  Input,
  Textarea,
  Button,
  MainContent,
  Buttons,
  Datepicker,
} from "@vms-admin/components";
import API_URL from "../../../api/api.constants";
import {
  fetchVoucherCategories,
  fetchVoucherScopes,
  fetchPlans,
  fetchPlansByCityCode,
  fetchPlansByCountryCode,
  fetchBrand,
  fetchMerchant,
  fetchBrandMerchants,
  fetchBrandUserMerchants,
} from "../../../redux/actions";

import {
  getMerchantOptions,
  getPlansOptions,
  getVoucherScopesOptions,
} from "../../../utils";
import {
  AdminVoucherSchema,
  merchantVoucherSchema,
  voucherSchema,
} from "../../../schema";
import { dismissToast, somethignWentWrong } from "../../../helpers";
import { MerchantDetailsLoader } from "../merchant-detials-loader";
import axios from "axios";

const weekdays = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const CreateVoucherStepTwo = ({ voucherDetails, prevStep }) => {
  const accessToken = localStorage.getItem("access-token");
  const [tabIndex, setTabIndex] = useState(0);
  const dispatch = useDispatch();
  const [voucherImages, setVoucherImages] = useState([]);
  const [getPlanBy, setGetPlansBy] = useState(null);
  const userInfo = useSelector(({ auth }) => auth.userInfo);
  const voucherCategories = useSelector(({ voucher }) => voucher.categories);
  const userRole = useSelector(({ auth }) => auth.role);
  const { ref, inView } = useInView();

  const merchantId = userRole.is_admin
    ? voucherDetails?.merchantName?.id
    : userInfo?.id;

  const {
    watch,
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(
      userRole.is_admin
        ? AdminVoucherSchema
        : userRole.is_merchant
        ? merchantVoucherSchema
        : voucherSchema
    ),
  });

  const voucherDetailsisInvalidForAdmin =
    !watch("voucherName") ||
    !watch("voucherDesc") ||
    !watch("voucherCount") ||
    !watch("pushTo") ||
    !watch("subscriptionPlans");
  // !watch("voucherCategory");

  const voucherDetailsisInvalidForOthers =
    !watch("voucherName") ||
    !watch("voucherDesc") ||
    !watch("voucherCount") ||
    !watch("pushTo") ||
    !watch("subscriptionPlans") ||
    // !watch("voucherCategory") ||
    !watch("getSubscriptionPlan");

  const [openingHours, setOpeningHours] = useState({
    Saturday: { min: 0, max: 1 },
    Sunday: { min: 0, max: 1 },
    Monday: { min: 0, max: 1 },
    Tuesday: { min: 0, max: 1 },
    Wednesday: { min: 0, max: 1 },
    Thursday: { min: 0, max: 1 },
    Friday: { min: 0, max: 1 },
  });

  const voucherName = watch("voucherName");
  const voucherDesc = watch("voucherDesc");
  const history = useHistory();

  const [voucherCategory, setVoucherCategory] = useState(null);
  const voucher = useSelector(({ voucher }) => voucher);
  const brands = useSelector(({ brands }) => brands);
  const merchant = useSelector(({ merchant }) => merchant);

  const plansByCountry = useSelector(
    ({ plans }) => plans?.plansByCountry?.data
  );
  const plansByCity = useSelector(({ plans }) => plans?.plansByCity?.data);
  const plans = useSelector(({ plans }) => plans.plans?.data);

  const activePlans = useMemo(
    () => plans?.filter((plan) => plan.is_active === true),
    [plans]
  );

  const handleChange = (value, name) =>
    setOpeningHours({ ...openingHours, [name]: { ...value } });

  const formatOpeningHours = useCallback(
    () =>
      weekdays.map((weekday) => ({
        name: weekday,
        value: {
          close: openingHours[weekday].max,
          open: openingHours[weekday].min,
        },
      })),
    [openingHours]
  );

  const selectedPlansId = useCallback(
    (plans) => getPlansOptions(plans)?.map(({ id }) => id),
    []
  );

  const setSubscriptionPlanOptions = useCallback(() => {
    if (getPlanBy === "country") return getPlansOptions(plansByCountry);
    if (getPlanBy === "city") return getPlansOptions(plansByCity);
    if (!getPlanBy && userRole.is_admin) return getPlansOptions(activePlans);
  }, [getPlanBy, userRole, activePlans, plansByCity, plansByCountry]);

  useEffect(() => {
    setValue("voucherName", voucherDetails?.voucherName);
    setValue("voucherDesc", voucherDetails?.voucherDesc);
  }, [
    voucherDetails?.voucherName,
    voucherDetails?.voucherDesc,
    setValue,
    userInfo?.id,
  ]);

  const createVoucher = useCallback(
    (data) => {
      dismissToast();
      axios(`${API_URL.vouchers}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        data: {
          voucher_set_name: voucherDetails?.voucherName
            ? voucherDetails?.voucherName
            : voucherName,
          voucher_set_details: formatOpeningHours(),
          voucher_set_category: voucherCategory,
          merchant_id: userRole.is_brand ? data.merchantName.id : merchantId,
          start_datetime: data.startDate,
          end_datetime: data.endDate,
          description: voucherDetails?.voucherDesc
            ? voucherDetails?.voucherDesc
            : data.voucherDesc,
          push_to: data.pushTo.id,
          images: voucherImages,
          count: parseInt(data.voucherCount),
          subscription_plans: selectedPlansId(data.subscriptionPlans),
          exclude_holidays: data.excludeHolidays,
        },
      })
        .then((res) => {
          if (res.status === 201) {
            setTimeout(() => history.push("/vouchers"), [2000]);
            toast.success(`Voucher has been created Successfully`);
          } else if (res.status === 401) {
            localStorage.removeItem("access-token");
            history.push("/");
          } else {
            somethignWentWrong();
          }
        })
        .catch(() => somethignWentWrong());
    },
    [
      accessToken,
      voucherDetails?.voucherName,
      voucherDetails?.voucherDesc,
      voucherName,
      formatOpeningHours,
      voucherCategory,
      userRole.is_brand,
      merchantId,
      voucherImages,
      selectedPlansId,
      history,
    ]
  );

  useEffect(() => {
    dispatch(fetchVoucherCategories());
    dispatch(fetchVoucherScopes());
    dispatch(fetchMerchant(merchantId));
    userRole.is_brand_user &&
      dispatch(fetchBrandUserMerchants(userInfo?.brand_id));
    userRole.isBrand && dispatch(fetchBrand(userInfo?.id));
    userRole.isBrand && dispatch(fetchBrandMerchants(userInfo?.id));
    userRole.is_admin && dispatch(fetchPlans());
    if (!userRole.is_admin) {
      getPlanBy === "city"
        ? dispatch(fetchPlansByCityCode(userInfo?.city))
        : dispatch(fetchPlansByCountryCode(userInfo?.country));
    }
  }, [
    dispatch,
    userInfo?.country,
    userInfo?.city,
    getPlanBy,
    userRole,
    userInfo?.id,
    userInfo?.brand_id,
    tabIndex,
    merchantId,
  ]);

  const selectedVoucherCategory = voucher.categories.filter(
    ({ id }) => id === parseInt(voucherCategory)
  );

  const nextTab = () => {
    if (tabIndex === 2) return;
    setTabIndex(tabIndex + 1);
  };

  const prevTab = () => {
    if (tabIndex === 0) {
      prevStep();
    } else {
      setTabIndex(tabIndex - 1);
    }
  };

  return (
    <MainContent>
      <form onSubmit={handleSubmit(createVoucher)}>
        <div className="container_mobile">
          <div className="page-header" ref={ref}>
            <h1>Create Voucher</h1>
            <div className={inView ? `` : `sticky-action-bar`}>
              <Buttons noMargin>
                {userRole.is_admin && (
                  <Button
                    type="button"
                    variant="secondary"
                    handleClick={prevTab}
                  >
                    Back
                  </Button>
                )}
                {tabIndex === 2 && (
                  <Button type="submit" variant="primary">
                    Create Voucher
                  </Button>
                )}
                {tabIndex !== 2 && (
                  <Button
                    type="button"
                    variant="primary"
                    handleClick={nextTab}
                    disabled={
                      tabIndex === 0
                        ? false
                        : userRole.is_admin
                        ? voucherDetailsisInvalidForAdmin
                        : voucherDetailsisInvalidForOthers
                    }
                  >
                    Next
                  </Button>
                )}
              </Buttons>
            </div>
          </div>
        </div>
        <Grid>
          <div className="cards_wrapper">
            <Tabs
              selectedIndex={tabIndex}
              onSelect={(tabIndex) => setTabIndex(tabIndex)}
            >
              <TabList>
                {userRole.is_admin && <Tab>Merchant Details</Tab>}
                <Tab>Voucher Details</Tab>
                <Tab
                  disabled={
                    userRole.is_admin
                      ? voucherDetailsisInvalidForAdmin
                      : voucherDetailsisInvalidForOthers
                  }
                >
                  Status
                </Tab>
              </TabList>

              {userRole.is_admin && (
                <TabPanel>
                  {merchant.merchantLoading ? (
                    <MerchantDetailsLoader />
                  ) : (
                    <div className="cards_wrapper">
                      <Card>
                        <Grid columns={2}>
                          <Input
                            type="email"
                            name="emailAddress"
                            register={register}
                            value={merchant?.merchant?.email}
                            disabled={true}
                            label="Merchant Email Address"
                          />
                          <Input
                            type="tel"
                            name="contactNumber"
                            register={register}
                            value={merchant?.merchant?.telephone}
                            disabled={true}
                            label="Merchant Contact Number"
                          />
                        </Grid>
                        <>
                          <label
                            style={{ display: "block", marginBottom: "8px" }}
                          >
                            Merchant Logo
                          </label>
                          <img
                            src={merchant?.merchant?.image}
                            alt={merchant?.merchant?.email}
                            style={{ width: "80px" }}
                          />
                        </>
                      </Card>
                      <Card title="Location">
                        <Map
                          address={merchant?.merchant?.address}
                          userLat={merchant?.merchant?.lat}
                          userLng={merchant?.merchant?.long}
                          disabled={true}
                        />
                      </Card>
                    </div>
                  )}
                </TabPanel>
              )}
              <TabPanel>
                <div className="cards_wrapper">
                  <Card>
                    {!userRole.is_admin && (
                      <>
                        <Input
                          type="text"
                          name="voucherName"
                          register={register}
                          placeholder="Add title here"
                          label="Voucher Name"
                          errors={errors}
                        />

                        <Textarea
                          type="text"
                          name="voucherDesc"
                          register={register}
                          placeholder="Voucher Description"
                          label="Voucher Description"
                          errors={errors}
                        />
                      </>
                    )}
                    <Grid columns={3}>
                      <Input
                        type="number"
                        register={register}
                        name="voucherCount"
                        placeholder="Number of Vouchers"
                        label="Number of Vouchers"
                        errors={errors}
                      />
                      <div className="input_holder">
                        <label>Push To</label>
                        <Controller
                          name="pushTo"
                          errors={errors}
                          control={control}
                          render={({ field }) => (
                            <Select
                              menuPortalTarget={document.body}
                              {...field}
                              options={getVoucherScopesOptions(voucher.scopes)}
                              placeholder="Select Category"
                              className="kmmrce-select-container"
                              classNamePrefix="kmmrce-select"
                            />
                          )}
                        />
                        {errors && errors["pushTo"] && (
                          <ErrorMessage text="Push to is Required" />
                        )}
                      </div>
                      {!userRole.is_merchant && !userRole.is_admin && (
                        <div className="input_holder">
                          <label>Merchants</label>
                          <Controller
                            name="merchantName"
                            control={control}
                            render={({ field }) => (
                              <Select
                                menuPortalTarget={document.body}
                                {...field}
                                options={getMerchantOptions(
                                  userRole.is_brand
                                    ? brands?.merchants
                                    : brands.userMerchants
                                )}
                                placeholder="Select Merchant"
                                className="kmmrce-select-container"
                                classNamePrefix="kmmrce-select"
                              />
                            )}
                          />
                          {errors && errors["merchantName"] && (
                            <ErrorMessage text="Merchant Name is Required" />
                          )}
                        </div>
                      )}
                    </Grid>
                  </Card>
                  <Card title="Upload Voucher Images">
                    <MultipleUpload
                      label="Voucher Images"
                      name="voucherImages"
                      register={register}
                      getImages={(images) => {
                        console.log("imaaaages", images);
                        setVoucherImages(images);
                      }}
                    />
                  </Card>
                  <Card title="Subscription Plans">
                    {!userRole.is_admin && (
                      <div
                        style={{
                          display: "flex",
                          marginTop: 10,
                          marginBottom: 40,
                        }}
                      >
                        <div className="kmmrce-radio radio">
                          <input
                            type="radio"
                            name="getSubscriptionPlan"
                            value="country"
                            register={register}
                            id="radioCountry"
                            {...register("getSubscriptionPlan")}
                            className="radio_input"
                          />
                          <label
                            htmlFor="radioCountry"
                            style={{ display: "flex", alignItems: "center" }}
                            className="radio_label"
                          >
                            By Country
                          </label>
                        </div>

                        <div
                          className="kmmrce-radio radio"
                          style={{ marginLeft: "16px" }}
                        >
                          <input
                            type="radio"
                            name="getSubscriptionPlan"
                            value="city"
                            register={register}
                            id="radioCity"
                            {...register("getSubscriptionPlan", {
                              onChange: ({ target: { value } }) =>
                                setGetPlansBy(value),
                            })}
                            className="radio_input"
                          />
                          <label
                            htmlFor="radioCity"
                            style={{ display: "flex", alignItems: "center" }}
                            className="radio_label"
                          >
                            By City
                          </label>
                        </div>
                      </div>
                    )}
                    {errors && errors["getSubscriptionPlan"] && (
                      <ErrorMessage text="Please select a filter" />
                    )}
                    <div className="input_holder">
                      <label htmlFor="">Select Subscription Plans</label>
                      <Controller
                        name="subscriptionPlans"
                        errors={errors}
                        control={control}
                        render={({ field }) => (
                          <Select
                            menuPortalTarget={document.body}
                            {...field}
                            isMulti
                            options={setSubscriptionPlanOptions()}
                            placeholder="Select Subscription Plan"
                            className="kmmrce-select-container"
                            classNamePrefix="kmmrce-select"
                            isDisabled={!getPlanBy && !userRole.is_admin}
                          />
                        )}
                      />
                      {errors && errors["subscriptionPlans"] && (
                        <ErrorMessage text="Subscription Plans are Required" />
                      )}
                    </div>
                  </Card>
                  {console.log("vvvv", voucherCategory)}
                  <Card title="Categories">
                    <div className="input_holder">
                      <Grid columns={2}>
                        {voucherCategories?.map(
                          ({ category_name, voucher_category_icon, id }) => {
                            return (
                              <Switch
                                handleChange={({ target }) =>
                                  setVoucherCategory(target.value)
                                }
                                name="voucherCategory"
                                value={id}
                                icon={voucher_category_icon}
                                register={register}
                                label={category_name}
                                key={id}
                                asRadio
                              />
                            );
                          }
                        )}
                        {errors && errors["voucherCategory"] && (
                          <ErrorMessage text="Voucher Category is Required" />
                        )}
                      </Grid>
                    </div>
                  </Card>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="cards_wrapper">
                  <Card>
                    <Switch
                      label="Exclude National Holidays"
                      register={register}
                      name="excludeHolidays"
                    />
                  </Card>
                  <Card noOverflow>
                    <Grid columns={2}>
                      <Datepicker
                        label="Start at"
                        name="startDate"
                        errors={errors}
                        control={control}
                        errorMessage="Start Date is Required"
                      />
                      <Datepicker
                        label="End at"
                        name="endDate"
                        errors={errors}
                        control={control}
                        errorMessage="End Date is Required"
                      />
                    </Grid>
                  </Card>
                  <Card>
                    {weekdays.map((weekday, index) => (
                      <InputRangeField
                        key={index}
                        step={1}
                        maxValue={24}
                        minValue={0}
                        label={weekday}
                        name={weekday.toLowerCase()}
                        handleChange={(value) => handleChange(value, weekday)}
                        value={openingHours[weekday]}
                      />
                    ))}
                  </Card>
                </div>
              </TabPanel>
            </Tabs>
          </div>
          <Mobile
            voucherName={
              voucherDetails?.voucherName
                ? voucherDetails?.voucherName
                : voucherName
            }
            voucherDesc={
              voucherDetails?.voucherDesc
                ? voucherDetails?.voucherDesc
                : voucherDesc
            }
            voucherImages={voucherImages}
            voucherCategory={selectedVoucherCategory}
          />
        </Grid>
      </form>
    </MainContent>
  );
};

export default CreateVoucherStepTwo;
