import React, { useCallback, useEffect, useState, useMemo } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useDispatch } from "react-redux";

import {
  Card,
  BrandsTable,
  MerchantsTable,
  VouchersTable,
  UsersTable,
  TableCard,
  MainContent,
  PlansTable,
  Button,
} from "@vms-admin/components";
import API_URL from "../../api/api.constants";
import { useSelector } from "react-redux";
import {
  fetchBrandMerchants,
  fetchBrandUsers,
  fetchMerchantUsers,
  fetchClient,
  fetchClientBrands,
  fetchClientMerchants,
  fetchClientUsers,
  fetchClientUserBrands,
  fetchClientUserMerchants,
  fetchClientUserVouchers,
  fetchMerchantUserVouchers,
  fetchBrandUserMerchants,
  fetchBrandUserVouchers,
  fetchMerchantVouchers,
  fetchBrandVouchers,
  fetchClientVouchers,
  fetchCustomerVouchers,
  fetchCustomerPlans,
  fetchCustomerNew,
} from "../../redux/actions";

import { capitalize, somethignWentWrong } from "../../helpers";
import axios from "axios";
import { Link } from "react-router-dom";
import { active, inActive, formatDate } from "../../helpers";
import { useHistory } from "react-router";
import { subscribe } from "../../redux/actions";

export const Profile = () => {
  const accessToken = localStorage.getItem("access-token");
  const [userOverview, setUserOverview] = useState({});
  const [users, setUsers] = useState({});
  const [userBrands, setUserBrands] = useState([]);
  const [userMerchants, setUserMerchants] = useState([]);
  const [userVouchers, setUserVouchers] = useState([]);
  const [customerPlans, setCustomerPlans] = useState([]);
  const [customerSubscribedPlans, setSubscribedCustomerPlans] = useState([]);
  const [customerNew, setCustomerNew] = useState([]);
  const clientData = useSelector(({ clients }) => clients);
  const customer = useSelector(({ customer }) => customer);
  const brandData = useSelector(({ brands }) => brands);
  const merchantData = useSelector(({ merchant }) => merchant);
  const vouchersData = useSelector(({ voucher }) => voucher);
  const userInfo = useSelector(({ auth }) => auth.userInfo);
  const userRole = useSelector(({ auth }) => auth.role);
  const dispatch = useDispatch();

  const history = useHistory();

  const getUserOverview = useCallback(() => {
    axios(`${API_URL.usersOverview}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.status === 200 && res.data)
      .then((data) => setUserOverview(data.data))
      .catch(() => somethignWentWrong());
  }, [accessToken]);

  const deleteUser = useCallback(
    (id) => {
      axios(`${API_URL[`${userInfo?.role}s`]}/${userInfo?.id}/users/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          id,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            userRole.is_client && dispatch(fetchClientUsers(userInfo?.id));
            userRole.is_brand && dispatch(fetchBrandUsers(userInfo?.id));
            userRole.is_merchant && dispatch(fetchMerchantUsers(userInfo?.id));
          }
        })
        .catch((error) => console.log("error", error));
    },
    [accessToken, userInfo, dispatch, userRole]
  );

  useEffect(() => {
    if (userRole.is_client) {
      dispatch(fetchClient(userInfo?.id));
      dispatch(fetchClientUsers(userInfo?.id));
      dispatch(fetchClientBrands(userInfo?.id));
      dispatch(fetchClientMerchants(userInfo?.id));
      dispatch(fetchClientVouchers(userInfo?.id));
    }

    if (userRole.is_client_user) {
      dispatch(fetchClientUsers(userInfo?.client_id));
      dispatch(fetchClientUserBrands(userInfo?.client_id));
      dispatch(fetchClientUserMerchants(userInfo?.client_id));
      dispatch(fetchClientUserVouchers(userInfo?.client_id));
    }

    if (userRole.is_brand) {
      dispatch(fetchBrandUsers(userInfo?.id));
      dispatch(fetchBrandMerchants(userInfo?.id));
      dispatch(fetchBrandVouchers(userInfo?.id));
    }

    if (userRole.is_brand_user) {
      dispatch(fetchBrandUsers(userInfo?.brand_id));
      dispatch(fetchBrandUserMerchants(userInfo?.brand_id));
      dispatch(fetchBrandUserVouchers(userInfo?.brand_id));
    }

    if (userRole.is_merchant) {
      dispatch(fetchMerchantUsers(userInfo?.id));
      dispatch(fetchMerchantVouchers(userInfo?.id));
    }

    if (userRole.is_merchant_user) {
      dispatch(fetchMerchantUsers(userInfo?.merchant_id));
      dispatch(fetchMerchantUserVouchers(userInfo?.merchant_id));
    }

    if (userRole.is_customer) {
      dispatch(fetchCustomerVouchers(userInfo?.id));
      dispatch(fetchCustomerPlans(userInfo?.id));
      dispatch(fetchCustomerNew());
    }
  }, [dispatch, userRole, userInfo]);

  useEffect(() => {
    getUserOverview();
  }, [getUserOverview, dispatch]);

  useEffect(() => {
    if (userRole.is_client) {
      setUsers(clientData.users);
      setUserBrands(brandData.clientBrands);
      setUserVouchers(vouchersData.clientVouchers);
      setUserMerchants(merchantData.clientMerchants);
    }

    if (userRole.is_client_user) {
      setUsers(clientData.users);
      setUserBrands(clientData.userBrands);
      setUserMerchants(clientData.userMerchants);
      setUserVouchers(clientData.userVouchers);
    }

    if (userRole.is_brand) {
      setUsers(brandData.users);
      setUserMerchants(merchantData.brandMerchants);
      setUserVouchers(vouchersData.brandVouchers);
    }

    if (userRole.is_brand_user) {
      setUsers(brandData.users);
      setUserMerchants(merchantData.brandUserMerchants);
      setUserVouchers(vouchersData.brandUserVouchers);
    }

    if (userRole.is_merchant) {
      setUsers(merchantData.users);
      setUserVouchers(vouchersData.merchantVouchers);
    }

    if (userRole.is_merchant_user) {
      setUsers(merchantData.users);
      setUserVouchers(vouchersData.merchantUserVouchers);
    }

    if (userRole.is_customer) {
      setUserVouchers(vouchersData?.customerVouchers);
      setCustomerPlans(customer?.plans?.all_plans);
      setSubscribedCustomerPlans(customer?.plans?.subscribed_plans);
      setCustomerNew(customer?.new);
    }
  }, [
    brandData.clientBrands,
    brandData.userMerchants,
    brandData.userVouchers,
    brandData.users,
    clientData.userBrands,
    clientData.userMerchants,
    clientData.userVouchers,
    clientData.users,
    customer?.customerVouchers,
    customer?.new,
    customer?.plans?.all_plans,
    customer?.plans?.subscribed_plans,
    merchantData.brandMerchants,
    merchantData.brandUserMerchants,
    merchantData.clientMerchants,
    merchantData.userVouchers,
    merchantData.users,
    userRole.is_brand,
    userRole.is_brand_user,
    userRole.is_client,
    userRole.is_client_user,
    userRole.is_customer,
    userRole.is_merchant,
    userRole.is_merchant_user,
    vouchersData.brandUserVouchers,
    vouchersData.brandVouchers,
    vouchersData.clientVouchers,
    vouchersData?.customerVouchers,
    vouchersData.merchantUserVouchers,
    vouchersData.merchantVouchers,
  ]);

  const handleSubscribe = useCallback(
    (id) =>
      dispatch(subscribe(id))
        .then((data) => {
          if (data) {
            window.open(data.payUrl, "_blank");
            // history.push("/subscription-payment");
            // localStorage.setItem("subscription_id", data.subscription_id);
          }
        })
        .catch((error) => console.log("error", error)),
    [dispatch, history]
  );

  const subscribedPlansIds = useMemo(
    () => customerSubscribedPlans?.map((plan) => plan.id),
    [customerSubscribedPlans]
  );

  if (userRole.is_customer) {
    return (
      <MainContent>
        <div className="mt-3">
          <h2 className="text-2xl font-semibold">My Vouchers</h2>
          <div className="mt-5 grid gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 pb-5">
            {userVouchers?.map((voucher) => (
              <Link
                to={`/voucher/${voucher.id}`}
                className="block relative rounded-lg   bg-[#fff] pb-2 overflow-hidden"
                key={voucher.id}
              >
                <div className="h-[10.938rem]  w-full rounded-t-md absolute bg-[#000] opacity-5"></div>
                <span className="absolute mt-2 ml-2 whitespace-nowrap max-w-[200px] text-ellipsis !w-[unset] rounded-md bg-[#fff] px-2.5 py-0.5 text-sm ">
                  {voucher.merchant?.merchant_name}
                </span>
                <img
                  alt=""
                  src={voucher.images?.[0] || voucher?.merchant?.image}
                  className="h-[10.938rem] w-full rounded-t-md object-cover"
                />

                <div className="mt-2 px-2">
                  <dl>
                    {/* <div>
                      <dt class="sr-only">Price</dt>

                      <dd class="text-sm text-gray-500">$240,000</dd>
                    </div> */}

                    {voucher.is_active
                      ? active("Active")
                      : inActive("In Active")}
                    <div>
                      <dt className="sr-only">Voucher Name</dt>

                      <dd className="font-medium mt-2">
                        {voucher.voucher_set_name}
                      </dd>
                    </div>
                  </dl>
                </div>
                <div className="flex justify-end mr-2">
                  {voucher.used === voucher.count ? (
                    <span className="btn btn-tertiary">Redeemed</span>
                  ) : (
                    <Button
                      variant="tertiary"
                      handleClick={(e) => {
                        e.preventDefault();
                        history.push("/otp");
                        localStorage.setItem("voucher_id", voucher.id);
                      }}
                    >
                      Redeem
                    </Button>
                  )}
                </div>
              </Link>
            ))}
          </div>
        </div>
        <div className="mt-3">
          <h2 className="text-2xl font-semibold">My Plans</h2>
          <div className="mt-5 grid gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 pb-5">
            {customerPlans?.map((plan) => (
              <Link
                to={`/plan/${plan.id}`}
                className="block relative rounded-lg   bg-[#fff] py-2 overflow-hidden"
                key={plan.id}
              >
                <div className="h-[10.938rem]  w-full rounded-t-md absolute bg-[#000] opacity-5"></div>
                <span className="absolute mt-2 ml-2  rounded-md bg-[#fff] px-2.5 py-0.5 text-sm ">
                  {`From: ${formatDate(plan.start_date)} To: ${formatDate(
                    plan.end_date
                  )}`}
                </span>
                <img
                  alt=""
                  src={plan.image || `/images/image-placeholder.png`}
                  className="h-[10.938rem] w-full rounded-t-md object-cover"
                />

                <div className="mt-2 px-2">
                  <dl>
                    {/* <div>
                      <dt class="sr-only">Price</dt>

                      <dd class="text-sm text-gray-500">$240,000</dd>
                    </div> */}

                    {plan.is_active ? active("Active") : inActive("In Active")}
                    <div>
                      <dt className="sr-only">Plan Name</dt>

                      <dd className="font-medium mt-2">{plan.plan_name}</dd>
                    </div>
                  </dl>
                </div>
                <div className="flex justify-end mr-2">
                  {subscribedPlansIds?.includes(plan.id) ? (
                    <span className="btn btn-tertiary">Subscribed</span>
                  ) : (
                    <Button
                      variant="tertiary"
                      handleClick={(e) => {
                        e.preventDefault();
                        handleSubscribe(plan.id);
                      }}
                    >
                      Subscribe
                    </Button>
                  )}
                </div>
              </Link>
            ))}
          </div>
        </div>
        <div className="mt-3">
          <h2 className="text-2xl font-semibold">What's New?</h2>
          <div className="mt-5 grid gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 pb-5">
            {customerNew?.map((voucher) => (
              <Link to={`/whatsNew/${voucher.id}`} key={voucher.id}>
                <div className="block relative rounded-lg   bg-[#fff] pb-4 overflow-hidden">
                  <div className="h-[10.938rem]  w-full rounded-t-md absolute bg-[#000] opacity-5"></div>

                  <span className="absolute mt-2 ml-2 whitespace-nowrap max-w-[200px] text-ellipsis !w-[unset] rounded-md bg-[#fff] px-2.5 py-0.5 text-sm">
                    {voucher.merchant?.merchant_name}
                  </span>

                  <img
                    alt=""
                    src={voucher.images?.[0] || voucher?.merchant?.image}
                    className="h-[10.938rem] w-full rounded-t-md object-cover"
                  />

                  <div className="mt-2 px-2">
                    <dl>
                      {/* <div>
                      <dt class="sr-only">Price</dt>

                      <dd class="text-sm text-gray-500">$240,000</dd>
                    </div> */}

                      {voucher.is_active
                        ? active("Active")
                        : inActive("In Active")}
                      <div>
                        <dt className="sr-only">Voucher Name</dt>

                        <dd className="font-medium mt-2">
                          {voucher.voucher_set_name}
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </MainContent>
    );
  }

  return (
    <MainContent>
      <Card>
        <Tabs>
          <TabList>
            {userRole.is_customer && <Tab>What's New?</Tab>}
            {!userRole.is_customer && <Tab>Stats</Tab>}
            {userRole.is_client && <Tab>Brands</Tab>}

            {!userRole.is_merchant &&
              !userRole.is_merchant_user &&
              !userRole.is_customer && <Tab>Merchants</Tab>}

            <Tab>{userRole.is_customer ? "My Vouchers" : "Vouchers"}</Tab>
            {userRole.is_customer && <Tab>Plans</Tab>}
          </TabList>

          {userRole.is_customer && (
            <TabPanel>
              <VouchersTable
                title="Latest Vouchers"
                vouchers={customerNew}
                whatsNew
              />
            </TabPanel>
          )}

          {!userRole.is_customer && (
            <TabPanel>
              <div className="cards_wrapper">
                <UsersTable users={users} deleteUser={deleteUser} />

                <TableCard title={`${capitalize(userInfo?.role)} Stats`}>
                  <tbody>
                    {userRole.is_client && (
                      <tr>
                        <td>Brands</td>
                        <td className="align_right value_td">
                          {userOverview?.brands_count
                            ? userOverview?.brands_count
                            : 0}
                        </td>
                      </tr>
                    )}
                    {!userRole.is_merchant && !userRole.is_merchant_user && (
                      <tr>
                        <td>Merchants</td>
                        <td className="align_right value_td">
                          {userOverview?.merchants_count
                            ? userOverview?.merchants_count
                            : 0}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td>Vouchers</td>
                      <td className="align_right value_td">
                        {userOverview?.vouchers_summary?.vouchers_count
                          ? userOverview?.vouchers_summary?.vouchers_count
                          : 0}
                      </td>
                    </tr>
                  </tbody>
                </TableCard>
              </div>
            </TabPanel>
          )}

          {userRole.is_client && (
            <TabPanel>
              <div className="cards_wrapper">
                <BrandsTable brands={userBrands} />
              </div>
            </TabPanel>
          )}

          {!userRole.is_merchant &&
            !userRole.is_merchant_user &&
            !userRole.is_customer && (
              <TabPanel>
                <div className="cards_wrapper">
                  <MerchantsTable merchants={userMerchants} />
                </div>
              </TabPanel>
            )}

          <TabPanel>
            <div className="cards_wrapper">
              <VouchersTable
                vouchers={userVouchers}
                title={userRole.is_customer ? "My Vouchers" : "Vouchers"}
                showUsed
              />

              {userOverview?.vouchers_summary && (
                <TableCard title="Voucher Stats">
                  <tbody>
                    <tr>
                      <td>Active</td>
                      <td className="align_right value_td">
                        {userOverview?.vouchers_summary?.active_vouchers_count
                          ? userOverview?.vouchers_summary
                              ?.active_vouchers_count
                          : 0}
                      </td>
                    </tr>
                    <tr>
                      <td>InActive</td>
                      <td className="align_right value_td">
                        {userOverview?.vouchers_summary?.inactive_vouchers_count
                          ? userOverview?.vouchers_summary
                              ?.inactive_vouchers_count
                          : 0}
                      </td>
                    </tr>
                    <tr>
                      <td>Pending</td>
                      <td className="align_right value_td">
                        {userOverview?.vouchers_summary?.pending_vouchers_count
                          ? userOverview?.vouchers_summary
                              ?.pending_vouchers_count
                          : 0}
                      </td>
                    </tr>
                    <tr>
                      <td>Total Vouchers</td>
                      <td className="align_right value_td">
                        {userOverview?.vouchers_summary?.vouchers_count
                          ? userOverview?.vouchers_summary?.vouchers_count
                          : 0}
                      </td>
                    </tr>
                  </tbody>
                </TableCard>
              )}
            </div>
          </TabPanel>

          {userRole.is_customer && (
            <TabPanel>
              <div className="cards_wrapper">
                <PlansTable
                  plans={customerPlans}
                  subscribePlans={customerSubscribedPlans}
                />
              </div>
            </TabPanel>
          )}
        </Tabs>
      </Card>
    </MainContent>
  );
};

export default Profile;
