import React, { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";

import { getBase64 } from "../../helpers";
import { createUserSchema } from "../../schema";
import API_URL from "../../api/api.constants";
import {
  Card,
  Input,
  Grid,
  UploadField,
  Button,
  MainContent,
  Switch,
  Buttons,
} from "@vms-admin/components";
import axios from "axios";
import { toast } from "react-hot-toast";

export const CreateUser = () => {
  const accessToken = localStorage.getItem("access-token");
  const userInfo = useSelector(({ auth }) => auth.userInfo);
  const usersApi = useMemo(() => `${userInfo?.role}s`, [userInfo]);
  const history = useHistory();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: "onChange", resolver: yupResolver(createUserSchema) });

  const createUser = useCallback(
    async (data) => {
      const avatar = await getBase64(data?.userAvatar[0]);
      axios(`${API_URL[usersApi]}/${userInfo?.id}/users`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          first_name: data?.firstName,
          last_name: data?.lastName,
          user_admin: data.isAdmin,
          email: data.email,
          avatar,
        },
      })
        .then((res) => {
          if (res?.status === 201) {
            toast.success(res?.data?.msg);
          } else {
            toast.error(res?.data?.msg);
          }
        })
        .then((data) => console.log("dataaa", data))
        .catch((error) => {
          console.log("Error", error);
        });
    },
    [accessToken, userInfo, history, usersApi]
  );

  const handleChange = useCallback(() => {}, []);

  return (
    <MainContent>
      <Card title="Create User">
        <form onSubmit={handleSubmit(createUser)}>
          <Grid columns={2}>
            <Input
              label="First Name"
              type="text"
              placeholder="First Name"
              name="firstName"
              register={register}
              errors={errors}
            />
            <Input
              label="Last Name"
              type="text"
              placeholder="Last Name"
              name="lastName"
              register={register}
              errors={errors}
            />
          </Grid>
          <Grid columns={2}>
            <Input
              label="Email Address"
              type="text"
              placeholder="Email Address"
              name="email"
              register={register}
              errors={errors}
            />
            <Switch
              label="Is Admin"
              register={register}
              name="isAdmin"
              handleChange={handleChange}
            />
          </Grid>

          <UploadField
            label="User Avatar"
            name="userAvatar"
            errors={errors}
            register={register}
          />
          <Buttons>
            <Button variant="primary" type="submit">
              Create User
            </Button>
          </Buttons>
        </form>
      </Card>
    </MainContent>
  );
};

export default CreateUser;
