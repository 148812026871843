import React from "react";

export const Card = ({
  children,
  title,
  subtitle,
  footerContent,
  noOverflow,
  ...rest
}) => (
  <div
    {...rest}
    className={`card ${noOverflow ? `no-overflow` : ``} ${
      rest?.className || ""
    }`}
  >
    {title && (
      <div className="card-header">
        <div>
          <h3 style={{ fontWeight: "normal" }}>{title}</h3>
          {subtitle && <h3 className="mt20">{subtitle}</h3>}
        </div>
      </div>
    )}
    <div className="card-body">{children}</div>
    {footerContent && <div className="card-footer">{footerContent}</div>}
  </div>
);

export default Card;
