import axios from "axios";
import API_URL from "../../api/api.constants";
import { FETCH_ALL_USERS_SUCCESS } from "./actions.constants";

export const fetchAllUsers = (page) => (dispatch) => {
  return axios
    .get(`${API_URL.users}?page=${page}&per_page=50`)
    .then(
      (res) =>
        res.status === 200 &&
        dispatch({ type: FETCH_ALL_USERS_SUCCESS, data: res.data.data })
    )
    .catch((error) => console.log("error", error));
};
