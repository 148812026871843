import axios from "axios";
import React, { useCallback, useState } from "react";
import toast from "react-hot-toast";

import { Button, Input, Header } from "@vms-admin/components";
import { Key, LeftArrow } from "@vms-admin/icons";
import API_URL from "../../api/api.constants";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { resendEmailSchema } from "../../schema";
import { dismissToast } from "../../helpers";

import * as Styled from "./confirmation.style";
import { Link } from "react-router-dom";

export const ResendConfirmation = () => {
  const [emailSent, setEmailSent] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(resendEmailSchema) });

  const resendEmail = useCallback((data) => {
    dismissToast();
    axios(API_URL.resendEmail, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        email: data.email,
      },
    })
      .then((res) => {
        res.status === 200 && setEmailSent(true);
        res.status === 409 && toast.error("User Already confirm");
        res.status === 404 && toast.error("User doesn't exist");
      })
      .catch((error) => console.log("Error", error));
  }, []);

  return (
    <>
      <Header />
      {emailSent ? (
        <Styled.Wrapper>
          <Styled.Icon>
            <Key />
          </Styled.Icon>
          <Styled.Title>Resend</Styled.Title>
          <Styled.Text>
            Didn't receive the email? please check the email address you used to
            make sure it matches the address on your account, look in your spam
            folder, or request another e-mail below.
          </Styled.Text>
          <Button handleClick={() => setEmailSent(false)} block>
            Send Again
          </Button>
        </Styled.Wrapper>
      ) : (
        <Styled.Wrapper>
          <Styled.Icon>
            <Key />
          </Styled.Icon>
          <Styled.Title>Send Email</Styled.Title>
          <Styled.Text>
            No worries, we'll send you reset instructions.
          </Styled.Text>
          <Styled.Form onSubmit={handleSubmit(resendEmail)}>
            <Input
              type="email"
              placeholder="Email Address"
              label="Email"
              name="email"
              register={register}
              errors={errors}
              block
            />
            <Button type="submit" variant="primary" block>
              Send Email
            </Button>
          </Styled.Form>
          <Link to="/">
            <Styled.Link>
              <LeftArrow />
              <Styled.LinkText>Back to login</Styled.LinkText>
            </Styled.Link>
          </Link>
        </Styled.Wrapper>
      )}
    </>
  );
};

export default ResendConfirmation;
