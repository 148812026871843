import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";

import {
  Categories,
  Grid,
  Modal,
  Button,
  Input,
  UploadField,
  MainContent,
  TableCard,
  Buttons,
} from "@vms-admin/components";
import {
  createMerchantAmenity,
  createMerchantAttribute,
  createMerchantCategory,
  deleteMerchantAmenity,
  deleteMerchantAttribute,
  deleteMerchantCategory,
  editMerchantAmenity,
  editMerchantAttribute,
  editMerchantCategory,
  fetchMerchantAmenities,
  fetchMerchantAmenity,
  fetchMerchantAttribute,
  fetchMerchantAttributes,
  fetchMerchantCategories,
} from "../../redux/actions";
import toast from "react-hot-toast";
import { dismissToast, somethignWentWrong } from "../../helpers";

export const MerchantSettings = () => {
  const [isMerchantCreateOpen, setIsMerchantCreateOpen] = useState(false);
  const [isMerchantEditModalOpen, setMerchantEditIsModalOpen] = useState(false);
  const [isCreateAmenityOpen, setIsCreateAmenityOpen] = useState(false);
  const [isCreateAttributeOpen, setIsCreateAttributeOpen] = useState(false);
  const [isEditAmenityOpen, setIsEditAmenityOpen] = useState(false);
  const [isEditAttributeOpen, setIsEditAttributeOpen] = useState(false);
  const { handleSubmit, register, reset, watch, setValue } = useForm();
  const [amenityId, setAmenityId] = useState(null);
  const [attributeId, setAttributeId] = useState(null);
  const [attributeIcon, setAttributeIcon] = useState(null);
  const merchant = useSelector(({ merchant }) => merchant);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEditAmenityOpen) {
      setValue("amenityName", merchant?.selectedAmenity?.amenity_name);
    }
    if (isEditAttributeOpen) {
      setValue("attributeName", merchant?.selectedAttribute?.attribute_name);
    }
  }, [
    setValue,
    isEditAmenityOpen,
    isEditAttributeOpen,
    merchant?.selectedAmenity,
    merchant?.selectedAttribute,
  ]);

  const getFilteredAmenities = useCallback(() => {
    if (!watch("search")) {
      return merchant.amenities;
    } else {
      return merchant.amenities.filter(({ amenity_name }) =>
        amenity_name.toLowerCase().includes(watch("search").toLowerCase())
      );
    }
  }, [watch, merchant.amenities]);

  const getFilteredAttributes = useCallback(() => {
    if (!watch("searchAttributes")) {
      return merchant.attributes;
    } else {
      return merchant.attributes.filter(({ attribute_name }) =>
        attribute_name
          .toLowerCase()
          .includes(watch("searchAttributes").toLowerCase())
      );
    }
  }, [watch, merchant.attributes]);

  const handleMerchantEditModal = useCallback(
    () => setMerchantEditIsModalOpen(!isMerchantEditModalOpen),
    [isMerchantEditModalOpen]
  );

  const handleMerchantModal = useCallback(
    () => setIsMerchantCreateOpen(!isMerchantCreateOpen),
    [isMerchantCreateOpen]
  );

  const handleCreateMerchantCategory = useCallback(
    (data) => {
      dismissToast();
      dispatch(createMerchantCategory(data))
        .then((res) => {
          if (res.status === 500) {
            setIsMerchantCreateOpen(true);
            toast.error("Category Name already exists");
          } else {
            setIsMerchantCreateOpen(false);
            dispatch(fetchMerchantCategories());
            toast.success(`${data.categoryName} has been created Successfully`);
          }
        })
        .catch(() => somethignWentWrong());
    },
    [dispatch]
  );

  const handleEditMerchantAmenity = useCallback(
    (data) => {
      console.log("Dataaaa", data);
      dismissToast();
      dispatch(
        editMerchantAmenity(
          data,
          amenityId,
          merchant?.selectedAmenity?.amenity_icon
        )
      )
        .then((res) => {
          if (res.status === 500) {
            setIsEditAmenityOpen(true);
            toast.error(`${data.amenityName} already exists`);
          } else {
            setIsEditAmenityOpen(false);
            dispatch(fetchMerchantAmenities());
            toast.success(`${data.amenityName} has been updated Successfully`);
          }
        })
        .catch((error) => console.log("error", error));
    },
    [dispatch, amenityId, merchant?.selectedAmenity]
  );

  const handleEditMerchantAttribute = useCallback(
    (data) => {
      dismissToast();
      dispatch(
        editMerchantAttribute(
          data,
          attributeId,
          merchant?.selectedAttribute?.attribute_icon
        )
      )
        .then((res) => {
          if (res.status === 500) {
            setIsEditAttributeOpen(true);
            toast.error(`${data.attributeName} already exists`);
          } else {
            setIsEditAttributeOpen(false);
            dispatch(fetchMerchantAttributes());
            toast.success(
              `${data.attributeName} has been updated Successfully`
            );
          }
        })
        .catch((error) => console.log("error", error));
    },
    [dispatch, attributeId, attributeIcon]
  );

  const handleEditMerchantCategory = useCallback(
    (id, categoryName) => {
      dismissToast();
      dispatch(editMerchantCategory(id, categoryName))
        .then((res) => {
          if (res.status === 500) {
            setMerchantEditIsModalOpen(true);
            toast.error("Category Name already exists");
          } else {
            setMerchantEditIsModalOpen(false);
            dispatch(fetchMerchantCategories());
            toast.success(`${categoryName} has been updated Successfully`);
          }
        })
        .catch((error) => console.log("error", error));
    },
    [dispatch]
  );

  const handleDeleteMerchantCategory = useCallback(
    (id) => {
      dismissToast();
      dispatch(deleteMerchantCategory(id))
        .then((res) => {
          if (res.status === 200) {
            dispatch(fetchMerchantCategories());
            toast.success("Merchant Category has been Deleted Successfully");
          }
        })
        .catch(() => somethignWentWrong());
    },
    [dispatch]
  );

  const handleDeleteMerchantAmenity = useCallback(
    (id) => {
      dismissToast();
      dispatch(deleteMerchantAmenity(id))
        .then((res) => {
          if (res.status === 200) {
            dispatch(fetchMerchantAmenities());
            toast.success("Merchant Amenity has been Deleted Successfully");
          }
        })
        .catch(() => somethignWentWrong());
    },
    [dispatch]
  );

  const handleDeleteMerchantAttribute = useCallback(
    (id) => {
      dismissToast();
      dispatch(deleteMerchantAttribute(id))
        .then((res) => {
          if (res.status === 200) {
            dispatch(fetchMerchantAttributes());
            toast.success("Merchant Attribute has been Deleted Successfully");
          }
        })
        .catch(() => somethignWentWrong());
    },
    [dispatch]
  );

  const handleCreateMerchantAmenity = useCallback(
    (data) => {
      dismissToast();
      dispatch(createMerchantAmenity(data))
        .then((res) => {
          if (res.status === 500) {
            setIsCreateAmenityOpen(true);
            toast.error("Merchant Amenity already exists");
          } else {
            setIsCreateAmenityOpen(false);
            reset();
            dispatch(fetchMerchantAmenities());
          }
        })
        .catch(() => somethignWentWrong());
    },
    [dispatch, reset]
  );

  const handleCreateMerchantAttribute = useCallback(
    (data) => {
      dismissToast();
      dispatch(createMerchantAttribute(data))
        .then((res) => {
          if (res.status === 500) {
            setIsCreateAttributeOpen(true);
            toast.error("Merchant Attribute already exists");
          } else {
            setIsCreateAttributeOpen(false);
            reset();
            dispatch(fetchMerchantAttributes());
          }
        })
        .catch(() => somethignWentWrong());
    },
    [dispatch, reset]
  );

  useEffect(() => {
    dispatch(fetchMerchantCategories());
    dispatch(fetchMerchantAmenities());
    dispatch(fetchMerchantAttributes());
  }, [dispatch]);

  const handleCreateAmenityModal = useCallback(
    () => setIsCreateAmenityOpen(true),
    []
  );

  const handleCreateAttributeModal = useCallback(
    () => setIsCreateAttributeOpen(true),
    []
  );

  return (
    <MainContent>
      <Grid columns={2}>
        <Categories
          categories={merchant?.categories}
          title="Merchant Categories"
          type="Merchant"
          createCategory={handleCreateMerchantCategory}
          deleteCategory={handleDeleteMerchantCategory}
          editCategory={handleEditMerchantCategory}
          handleModal={handleMerchantModal}
          isModalOpen={isMerchantCreateOpen}
          isEditModalOpen={isMerchantEditModalOpen}
          handleIsEditModal={handleMerchantEditModal}
        />

        <TableCard
          title="Merchant Amenities"
          btnText="Create"
          btnAction={handleCreateAmenityModal}
          hasAction
        >
          <tbody>
            {getFilteredAmenities()?.map(
              ({ id, amenity_name, amenity_icon }) => (
                <tr key={id}>
                  <td>
                    <img
                      src={amenity_icon}
                      alt={amenity_name}
                      style={{ width: "30px" }}
                    />
                  </td>
                  <td>{amenity_name}</td>
                  <td className="align_right">
                    <Buttons>
                      <Button
                        variant="danger"
                        handleClick={() => handleDeleteMerchantAmenity(id)}
                      >
                        Delete
                      </Button>
                      <Button
                        variant="tertiary"
                        handleClick={() => {
                          setIsEditAmenityOpen(true);
                          setAmenityId(id);
                          dispatch(fetchMerchantAmenity(id));
                        }}
                      >
                        Edit
                      </Button>
                    </Buttons>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </TableCard>

        <TableCard
          title="Merchant Attributes"
          hasAction
          btnText="Create"
          btnAction={handleCreateAttributeModal}
        >
          <tbody>
            {getFilteredAttributes()?.map(
              ({ id, attribute_name, attribute_icon }) => (
                <tr key={id}>
                  <td>
                    <img
                      src={attribute_icon}
                      alt={attribute_name}
                      style={{ width: "30px" }}
                    />
                  </td>
                  <td>{attribute_name}</td>
                  <td className="align_right">
                    <Buttons>
                      <Button
                        variant="danger"
                        handleClick={() => handleDeleteMerchantAttribute(id)}
                      >
                        Delete
                      </Button>
                      <Button
                        variant="tertiary"
                        handleClick={() => {
                          setIsEditAttributeOpen(true);
                          setAttributeId(id);
                          setAttributeIcon(attribute_icon);
                          dispatch(fetchMerchantAttribute(id));
                        }}
                      >
                        Edit
                      </Button>
                    </Buttons>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </TableCard>
      </Grid>
      {isCreateAmenityOpen && (
        <Modal title="Create Merchant Amenities">
          <form onSubmit={handleSubmit(handleCreateMerchantAmenity)} noValidate>
            <Input
              label="Merchant Aminity Name"
              register={register}
              name="amenityName"
              type="text"
              placeholder="Merchant Aminity Name"
            />
            <UploadField
              name="amenityIcon"
              register={register}
              label="Merchant Amenity Icon"
            />
            <Buttons>
              <Button
                variant="secondary"
                handleClick={() => setIsCreateAmenityOpen(false)}
              >
                Cancel
              </Button>
              <Button variant="primary" type="submit">
                Create
              </Button>
            </Buttons>
          </form>
        </Modal>
      )}
      {isEditAmenityOpen && (
        <Modal title="Edit Merchant Amenities">
          <form onSubmit={handleSubmit(handleEditMerchantAmenity)} noValidate>
            <Input
              label="Merchant Aminity Name"
              register={register}
              name="amenityName"
              type="text"
              placeholder="Merchant Aminity Name"
            />
            <UploadField
              name="amenityIcon"
              register={register}
              label="Merchant Amenity Icon"
              image={merchant?.selectedAmenity?.amenity_icon}
            />
            <Buttons>
              <Button
                variant="secondary"
                handleClick={() => setIsEditAmenityOpen(false)}
              >
                Cancel
              </Button>
              <Button variant="primary" type="submit">
                Edit
              </Button>
            </Buttons>
          </form>
        </Modal>
      )}

      {isCreateAttributeOpen && (
        <Modal title="Create Merchant Attributes">
          <form
            onSubmit={handleSubmit(handleCreateMerchantAttribute)}
            noValidate
          >
            <Input
              label="Merchant Attribute Name"
              register={register}
              name="attributeName"
              type="text"
              placeholder="Merchant Attribute Name"
            />
            <UploadField
              name="attributeIcon"
              register={register}
              label="Merchant Attribute Icon"
            />
            <Buttons>
              <Button
                variant="secondary"
                handleClick={() => setIsCreateAttributeOpen(false)}
              >
                Cancel
              </Button>
              <Button variant="primary" type="submit">
                Create
              </Button>
            </Buttons>
          </form>
        </Modal>
      )}

      {isEditAttributeOpen && (
        <Modal title="Edit Merchant Attributes">
          <form onSubmit={handleSubmit(handleEditMerchantAttribute)} noValidate>
            <Input
              label="Merchant Attribute Name"
              register={register}
              name="attributeName"
              type="text"
              placeholder="Merchant Attribute Name"
            />
            <UploadField
              name="attributeIcon"
              register={register}
              label="Merchant Attribute Icon"
              image={merchant?.selectedAttribute?.attribute_icon}
            />
            <Buttons>
              <Button
                variant="secondary"
                handleClick={() => setIsEditAttributeOpen(false)}
              >
                Cancel
              </Button>
              <Button variant="primary" type="submit">
                Edit
              </Button>
            </Buttons>
          </form>
        </Modal>
      )}
    </MainContent>
  );
};

export default MerchantSettings;
