import React, { useCallback, useMemo, useState, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { getBase64 } from "../../helpers";
import { userSchema } from "../../schema";
import API_URL from "../../api/api.constants";
import {
  Card,
  Input,
  Grid,
  UploadField,
  Button,
  MainContent,
  Switch,
  Buttons,
} from "@vms-admin/components";
import toast from "react-hot-toast";
import axios from "axios";

export const EditUser = () => {
  const accessToken = localStorage.getItem("access-token");
  const userInfo = useSelector(({ auth }) => auth.userInfo);
  const usersApi = useMemo(() => `${userInfo?.role}s`, [userInfo]);
  const [user, setUser] = useState({});

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    control,
  } = useForm({ mode: "onChange", resolver: yupResolver(userSchema) });
  const history = useHistory();
  const { id } = useParams();

  const getUser = useCallback(async () => {
    axios(`${API_URL[usersApi]}/${userInfo?.id}/users/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => res.status === 200 && res.data)
      .then(({ data }) => setUser(data))
      .catch((error) => console.log("Error", error));
  }, [accessToken, userInfo, id, usersApi]);

  const editUser = useCallback(
    async (data) => {
      console.log("user", data?.userAvatar[0]);
      const avatar = data?.userAvatar[0]
        ? await getBase64(data?.userAvatar[0])
        : user?.avatar;
      axios(`${API_URL[usersApi]}/${userInfo?.id}/users/${id}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          first_name: data?.firstName,
          last_name: data?.lastName,
          user_admin: data?.isAdmin,
          email: data.email,
          avatar,
        },
      })
        .then((res) => {
          res.status === 200 && history.push("/profile");
          res.status === 401 && history.push("/");
        })
        .then((data) => console.log("dataaa", data))
        .catch((error) => console.log("Error", error));
    },
    [accessToken, userInfo, history, id, usersApi, user?.avatar]
  );

  useEffect(() => getUser(), [getUser]);
  useEffect(() => {
    setValue("firstName", user?.first_name);
    setValue("lastName", user?.last_name);
    setValue("email", user?.email);
    setValue("isAdmin", user?.user_admin);
  }, [setValue, user]);

  useEffect(
    () =>
      !user &&
      toast.error(
        "It looks like there is an issue, please try again in few minutes "
      ),
    [user]
  );

  return (
    <MainContent>
      <Card title="Edit User">
        <form onSubmit={handleSubmit(editUser)}>
          {user && (
            <>
              <Grid columns={2}>
                <Input
                  label="First Name"
                  type="text"
                  placeholder="First Name"
                  name="firstName"
                  register={register}
                  errors={errors}
                />
                <Input
                  label="Last Name"
                  type="text"
                  placeholder="Last Name"
                  name="lastName"
                  register={register}
                  errors={errors}
                />
              </Grid>
              {user.user_admin ? "yes" : "no"}
              <Grid columns={2}>
                <Input
                  label="Email Address"
                  type="text"
                  placeholder="Email Address"
                  name="email"
                  register={register}
                  errors={errors}
                />
                <Controller
                  name="category"
                  errors={errors}
                  control={control}
                  render={({ field }) => (
                    <Switch
                      {...field}
                      label="Is Admin"
                      register={register}
                      name="isAdmin"
                      value={user?.user_admin ? user?.user_admin : false}
                    />
                  )}
                />
              </Grid>
              <UploadField
                name="userAvatar"
                register={register}
                errors={errors}
                label="User Avatar"
                image={user?.avatar}
              />
              <Buttons>
                <Button variant="primary" type="submit">
                  Save Changes
                </Button>
              </Buttons>
            </>
          )}
        </form>
      </Card>
    </MainContent>
  );
};

export default EditUser;
