import { Grid, Card, StatsCard } from "@vms-admin/components";
import CountUp from "react-countup";
import Chart from "react-apexcharts";
import styled from "styled-components";
import { useEffect, useMemo, useState } from "react";

function AdminOperationalDashboard({ operational, revenue }) {
  const [instance, setInstance] = useState("");

  useEffect(() => {
    if (window.location.href.includes("incontrol")) {
      setInstance("in-control");
    }
    if (window.location.href.includes("rokit")) {
      setInstance("rokit");
    }
    if (window.location.href.includes("afritech")) {
      setInstance("starlink");
    }
  }, []);

  const barOptions = {
    chart: {
      type: "bar",
      fontFamily: "Yellix",
    },
    plotOptions: {
      bar: {
        columnWidth: "20%",
      },
    },
    colors: instance === "starlink" ? ["#fff"] : ["#3CD17F"],
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      customLegendItems: ["Subscriptions"],
      markers: {
        fillColors: instance === "starlink" ? ["#fff"] : ["#3CD17F"],
      },
    },
  };

  const data = useMemo(() => {
    return revenue?.subscriptions?.reduce((acc, val) => {
      const date = new Date(val.date);

      const year = date.getFullYear();
      const month = date.getMonth();

      const index = acc.findIndex((item) => item.x === `${year}-${month}`);

      if (index === -1) {
        acc.push({
          x: `${year}-${month}`,
          y: val.count,
        });
      } else {
        acc[index].y += val.count;
      }

      return acc;
    }, []);
  }, [revenue?.subscriptions]);

  const barData = [
    {
      name: "Subscriptions",
      data: data,
    },
  ];

  return (
    <>
      <Grid columns={2}>
        <Grid columns={2}>
          <StatsCard
            title="Total Revenue"
            hasDetails={false}
            decimals={2}
            value={operational?.total_revenue}
          />
          <StatsCard
            title="Total Customers"
            hasDetails={false}
            decimals={0}
            value={operational?.total_customers}
          />
          <StatsCard
            title="Total Clients"
            decimals={0}
            value={operational?.total_clients}
            hasDetails={false}
          />
          <StatsCard
            title="Total Redemptions"
            hasDetails={false}
            decimals={0}
            value={operational?.total_redemptions}
          />
          <StatsCard
            title="Total Brands"
            hasDetails={false}
            decimals={0}
            value={operational?.total_brands}
          />
          <StatsCard
            title="Total Vouchers"
            hasDetails={false}
            decimals={0}
            value={operational?.total_vouchers}
          />
          <StatsCard
            title="Total Merchants"
            hasDetails={false}
            decimals={0}
            value={operational?.total_merchants}
          />
          <StatsCard
            title="Total Subscription Plan"
            hasDetails={false}
            decimals={0}
            value={operational?.toal_subscription_plans}
          />
        </Grid>

        <Card
          title="Total Subscriptions"
          subtitle={
            <span
              style={{
                fontSize: "30px",
                fontWeight: "normal",
              }}
            >
              <CountUp
                className="animate_number_decima"
                end={revenue.total_subscriptions}
              />
            </span>
          }
          className="height_full"
        >
          <ChartWrapper>
            <Chart
              options={barOptions}
              series={barData}
              type="bar"
              width="100%"
            />
          </ChartWrapper>
        </Card>
      </Grid>
    </>
  );
}

export const ChartWrapper = styled.div`
  padding: 24px 0;
  padding-right: ${(props) => props.theme.space[4]}px;
`;

export default AdminOperationalDashboard;
