import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { fetchBrand } from "../../redux/actions";
import {
  MainContent,
  TableCard,
  Map,
  UsersTable,
  MerchantsTable,
} from "@vms-admin/components";
import BrandDetailsLoader from "./brand-details-loader";

export const BrandDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const brand = useSelector(({ brands }) => brands.brand);
  const brandLoading = useSelector(({ brands }) => brands.brandLoading);

  useEffect(() => dispatch(fetchBrand(id)), [dispatch, id]);

  return (
    <MainContent>
      {brandLoading ? (
        <BrandDetailsLoader />
      ) : (
        <div className="cards_wrapper">
          <TableCard title={`${brand?.brand_name} Details`}>
            <tbody>
              <tr>
                <td>
                  <strong>Brand UID</strong>
                </td>
                <td>{brand?.brand_uid}</td>
              </tr>
              <tr>
                <td>
                  <strong>Brand Name</strong>
                </td>
                <td>{brand?.brand_name}</td>
              </tr>
              <tr>
                <td>
                  <strong>Brand Email</strong>
                </td>
                <td>{brand?.email}</td>
              </tr>
              <tr>
                <td>
                  <strong>Brand Category</strong>
                </td>
                <td>{brand?.brand_category?.category_name}</td>
              </tr>
              <tr>
                <td>
                  <strong>Client Name</strong>
                </td>
                <td>{brand?.client?.client_name}</td>
              </tr>
              <tr>
                <td>
                  <strong>Confirmed</strong>
                </td>
                <td>
                  {brand?.confirmed ? (
                    <span className="label label-create">True</span>
                  ) : (
                    <span className="label label-delete">False</span>
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Brand P.O Box</strong>
                </td>
                <td>{brand?.po_box}</td>
              </tr>
              <tr>
                <td>
                  <strong>Brand Telephone</strong>
                </td>
                <td>{brand?.telephone}</td>
              </tr>
              <tr>
                <td>
                  <strong>Brand Country</strong>
                </td>
                <td>{brand?.country}</td>
              </tr>
              <tr>
                <td>
                  <strong>Brand city</strong>
                </td>
                <td>{brand?.city}</td>
              </tr>
              <tr>
                <td>
                  <strong>Brand Image</strong>
                </td>
                <td>
                  <img src={brand?.image} alt={brand?.brand_name} width="150" />
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <Map
                    userLat={parseInt(brand?.lat)}
                    userLng={parseInt(brand?.long)}
                    noAddressField
                  />
                </td>
              </tr>
            </tbody>
          </TableCard>
          {brand?.brand_users && (
            <UsersTable users={brand.brand_users} hasActions={false} />
          )}

          {brand?.merchants && (
            <MerchantsTable merchants={brand.merchants} hasActions={false} />
          )}
        </div>
      )}
    </MainContent>
  );
};

export default BrandDetails;
