export const routingAnimation = {
  hidden: {
    y: 20,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
  },
  exit: { y: 20, opacity: 0 },
  transition: {
    duration: 0.5,
  },
};

export const switchAnimation = { type: "spring", stiffness: 700, damping: 30 };
