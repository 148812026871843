import React from "react";

export const Button = ({
  type,
  handleClick,
  disabled,
  className,
  icon,
  children,
  variant,
  block,
  ...rest
}) => (
  <button
    type={type}
    onClick={handleClick}
    disabled={disabled}
    className={`btn btn-${variant} ${className}`}
    style={{ width: block ? `100%` : `auto` }}
    {...rest}
  >
    {icon && icon}
    {children}
  </button>
);

export default Button;
