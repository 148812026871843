import axios from "axios";
import API_URL from "../../api/api.constants";
import { getBase64 } from "../../helpers";
import {
  APPROVE_VOUCHER_SUCCESS,
  CREATE_VOUCHER_CATEGORY_SUCCESS,
  DELETE_VOUCHER_CATEGORY_SUCCESS,
  DELETE_VOUCHER_SUCCESS,
  EDIT_VOUCHER_CATEGORY_SUCCESS,
  FETCH_REDEEMED_VOUCHERS_SUCCESS,
  FETCH_VOUCHERS_SUCCESS,
  FETCH_VOUCHER_CATEGORIES_SUCCESS,
  FETCH_VOUCHER_SCOPES_SUCCESS,
  REDEEM_VOUCHER_SUCCESS,
  FETCH_VOUCHER_SUCCESS,
  CREATE_VOUCHER_SCOPE_SUCCESS,
  DELETE_VOUCHER_SCOPE_SUCCESS,
  EDIT_VOUCHER_SCOPE_SUCCESS,
  FETCH_VOUCHER_SCOPE_SUCCESS,
  FETCH_VOUCHERS_LOADING,
  FETCH_REDEEMED_VOUCHERS_LOADING,
  FETCH_BRAND_VOUCHERS_SUCCESS,
  FETCH_CLIENT_VOUCHERS_SUCCESS,
  FETCH_BRAND_USER_VOUCHERS_SUCCESS,
  FETCH_MERCHANT_VOUCHERS_SUCCESS,
  FETCH_MERCHANT_USER_VOUCHERS_SUCCESS,
  FETCH_CLIENT_USER_VOUCHERS_SUCCESS,
  FETCH_CUSTOMER_VOUCHERS_SUCCESS,
  FETCH_VOUCHER_LOADING,
} from "./actions.constants";

export const fetchVouchers = () => (dispatch) => {
  dispatch({ type: FETCH_VOUCHERS_LOADING });
  const accessToken = localStorage.getItem("access-token");
  let config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios(`${API_URL.vouchers}`, config)
    .then((res) => res.status === 200 && res.data)
    .then(({ data }) => dispatch({ type: FETCH_VOUCHERS_SUCCESS, data }))
    .catch((error) => console.log("error", error));
};

export const fetchVoucher = (scopeId) => (dispatch) => {
  dispatch({ type: FETCH_VOUCHER_LOADING });
  const accessToken = localStorage.getItem("access-token");
  let config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios(`${API_URL.vouchers}/${scopeId}`, config)
    .then((res) => res.status === 200 && res.data)
    .then(({ data }) => dispatch({ type: FETCH_VOUCHER_SUCCESS, data }))
    .catch((error) => console.log("error", error));
};

export const fetchVoucherCategories = () => (dispatch) => {
  const accessToken = localStorage.getItem("access-token");
  let config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios(`${API_URL.voucherCategories}`, config)
    .then((res) => res.status === 200 && res.data)
    .then((data) => dispatch({ type: FETCH_VOUCHER_CATEGORIES_SUCCESS, data }))
    .catch((error) => console.log("error", error));
};

export const fetchVoucherScopes = () => (dispatch) => {
  const accessToken = localStorage.getItem("access-token");
  let config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios(`${API_URL.voucherScopes}`, config)
    .then((res) => res.status === 200 && res.data)
    .then(({ data }) => dispatch({ type: FETCH_VOUCHER_SCOPES_SUCCESS, data }))
    .catch((error) => console.log("error", error));
};

export const fetchVoucherScope = (scopeId) => (dispatch) => {
  const accessToken = localStorage.getItem("access-token");
  let config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios(`${API_URL.voucherScopes}/${scopeId}`, config)
    .then((res) => res.status === 200 && res.data)
    .then(({ data }) => dispatch({ type: FETCH_VOUCHER_SCOPE_SUCCESS, data }))
    .catch((error) => console.log("error", error));
};

export const fetchRedeemedVouchers = () => (dispatch) => {
  dispatch({ type: FETCH_REDEEMED_VOUCHERS_LOADING });
  const accessToken = localStorage.getItem("access-token");
  let config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios(`${API_URL.vouchers}/redemptions`, config)
    .then((res) => res.status === 200 && res.data)
    .then(({ data }) =>
      dispatch({ type: FETCH_REDEEMED_VOUCHERS_SUCCESS, data })
    )
    .catch((error) => console.log("error", error));
};

export const deleteVoucher = (voucherId) => (dispatch) => {
  const accessToken = localStorage.getItem("access-token");
  let config = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios(`${API_URL.vouchers}/${voucherId}`, config)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: DELETE_VOUCHER_SUCCESS });
        return Promise.resolve(res);
      }
    })
    .catch((error) => Promise.reject(error));
};

export const createVoucherCategory = (formData) => async (dispatch) => {
  const accessToken = localStorage.getItem("access-token");
  const icon = await getBase64(formData.categoryIcon[0]);
  return axios(API_URL.voucherCategories, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    data: {
      category_name: formData?.categoryName,
      voucher_category_icon: icon,
    },
  })
    .then((res) => {
      dispatch({ type: CREATE_VOUCHER_CATEGORY_SUCCESS });
      return Promise.resolve(res);
    })
    .catch((error) => Promise.reject(error));
};

export const editVoucherCategory =
  (categoryId, categoryName, categoryIcon, categoryData) =>
  async (dispatch) => {
    const accessToken = localStorage.getItem("access-token");
    const icon = categoryIcon[0]
      ? await getBase64(categoryIcon[0])
      : categoryData?.categoryIcon;

    return axios(`${API_URL.voucherCategories}/${categoryId}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        category_name: categoryName,
        voucher_category_icon: icon,
      },
    })
      .then((res) => {
        dispatch({ type: EDIT_VOUCHER_CATEGORY_SUCCESS });
        return Promise.resolve(res);
      })
      .catch((error) => Promise.reject(error));
  };

export const deleteVoucherCategory = (voucherId) => (dispatch) => {
  const accessToken = localStorage.getItem("access-token");
  let config = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios(`${API_URL.voucherCategories}/${voucherId}`, config)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: DELETE_VOUCHER_CATEGORY_SUCCESS });
        return Promise.resolve(res);
      }
    })
    .catch((error) => Promise.reject(error));
};

export const redeemVoucher = (voucherId, otp) => (dispatch) => {
  const accessToken = localStorage.getItem("access-token");

  return axios(`${API_URL.vouchers}/${voucherId}/redeem`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    data: {
      voucher_id: voucherId,
      merchant_pin: otp,
    },
  })
    .then((res) => {
      dispatch({ type: REDEEM_VOUCHER_SUCCESS });
      return Promise.resolve(res);
    })
    .catch((error) => Promise.reject(error));
};

export const approveVoucher = (voucherId) => (dispatch) => {
  const accessToken = localStorage.getItem("access-token");

  return axios(`${API_URL.vouchers}/${voucherId}/approve`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((res) => {
      dispatch({ type: APPROVE_VOUCHER_SUCCESS });
      return Promise.resolve(res);
    })
    .catch((error) => Promise.reject(error));
};

export const createVoucherScope = (formData) => (dispatch) => {
  const accessToken = localStorage.getItem("access-token");
  return axios(API_URL.voucherScopes, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    data: {
      voucher_scope_name: formData?.scopeName,
      voucher_scope_code: formData?.scopeCode,
    },
  })
    .then((res) => {
      dispatch({ type: CREATE_VOUCHER_SCOPE_SUCCESS });
      return Promise.resolve(res);
    })
    .catch((error) => Promise.reject(error));
};

export const deleteVoucherScope = (scopeId) => (dispatch) => {
  const accessToken = localStorage.getItem("access-token");

  return axios(`${API_URL.voucherScopes}/${scopeId}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((res) => {
      dispatch({ type: DELETE_VOUCHER_SCOPE_SUCCESS });
      return Promise.resolve(res);
    })
    .catch((error) => Promise.reject(error));
};

export const editVoucherScope = (formData, scopeId) => (dispatch) => {
  const accessToken = localStorage.getItem("access-token");

  return axios(`${API_URL.voucherScopes}/${scopeId}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    data: {
      voucher_scope_name: formData?.scopeName,
      voucher_scope_code: formData?.scopeCode,
    },
  })
    .then((res) => {
      dispatch({ type: EDIT_VOUCHER_SCOPE_SUCCESS });
      return Promise.resolve(res);
    })
    .catch((error) => Promise.reject(error));
};

export const fetchBrandVouchers = (userId) => (dispatch) => {
  dispatch({ type: FETCH_VOUCHERS_LOADING });
  const accessToken = localStorage.getItem("access-token");
  let config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios(`${API_URL.brands}/${userId}/vouchers`, config)
    .then((res) => res.status === 200 && res.data)
    .then(({ data }) => dispatch({ type: FETCH_BRAND_VOUCHERS_SUCCESS, data }))
    .catch((error) => console.log("error", error));
};

export const fetchClientVouchers = (userId) => (dispatch) => {
  dispatch({ type: FETCH_VOUCHERS_LOADING });
  const accessToken = localStorage.getItem("access-token");
  let config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios(`${API_URL.clients}/${userId}/vouchers`, config)
    .then((res) => res.status === 200 && res.data)
    .then(({ data }) => dispatch({ type: FETCH_CLIENT_VOUCHERS_SUCCESS, data }))
    .catch((error) => console.log("error", error));
};

export const fetchBrandUserVouchers = (brandId) => (dispatch) => {
  dispatch({ type: FETCH_VOUCHERS_LOADING });
  const accessToken = localStorage.getItem("access-token");
  let config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios(`${API_URL.brands}/${brandId}/vouchers`, config)
    .then((res) => res.status === 200 && res.data)
    .then(({ data }) =>
      dispatch({ type: FETCH_BRAND_USER_VOUCHERS_SUCCESS, data })
    )
    .catch((error) => console.log("error", error));
};

export const fetchMerchantVouchers = (userId) => (dispatch) => {
  dispatch({ type: FETCH_VOUCHERS_LOADING });
  const accessToken = localStorage.getItem("access-token");
  let config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios(`${API_URL.merchants}/${userId}/vouchers`, config)
    .then((res) => res.status === 200 && res.data)
    .then(({ data }) =>
      dispatch({ type: FETCH_MERCHANT_VOUCHERS_SUCCESS, data })
    )
    .catch((error) => console.log("error", error));
};

export const fetchMerchantUserVouchers = (merchant_id) => (dispatch) => {
  dispatch({ type: FETCH_VOUCHERS_LOADING });
  const accessToken = localStorage.getItem("access-token");
  let config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios(`${API_URL.merchants}/${merchant_id}/vouchers`, config)
    .then((res) => res.status === 200 && res.data)
    .then(({ data }) =>
      dispatch({ type: FETCH_MERCHANT_USER_VOUCHERS_SUCCESS, data })
    )
    .catch((error) => console.log("error", error));
};

export const fetchClientUserVouchers = (clientId) => (dispatch) => {
  dispatch({ type: FETCH_VOUCHERS_LOADING });
  const accessToken = localStorage.getItem("access-token");
  let config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios(`${API_URL.clients}/${clientId}/vouchers`, config)
    .then((res) => res.status === 200 && res.data)
    .then(({ data }) =>
      dispatch({ type: FETCH_CLIENT_USER_VOUCHERS_SUCCESS, data })
    )
    .catch((error) => console.log("error", error));
};

export const fetchCustomerVouchers = (userId) => (dispatch) => {
  dispatch({ type: FETCH_VOUCHERS_LOADING });
  const accessToken = localStorage.getItem("access-token");
  let config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios(`${API_URL.customers}/${userId}/vouchers`, config)
    .then((res) => res.status === 200 && res.data)
    .then(({ data }) =>
      dispatch({ type: FETCH_CUSTOMER_VOUCHERS_SUCCESS, data })
    )
    .catch((error) => console.log("error", error));
};
