import React from "react";
import Skeleton from "react-loading-skeleton";

import { Card, Grid } from "@vms-admin/components";

const EditVoucherLoader = () => (
  <div className="cards_wrapper">
    <Card>
      <div className="input_holder" style={{ marginBottom: 40 }}>
        <Skeleton width={100} height={15} style={{ marginBottom: 10 }} />
        <Skeleton width="100%" height={40} />
      </div>
      <div className="input_holder" style={{ marginBottom: 40 }}>
        <Skeleton width={100} height={15} style={{ marginBottom: 10 }} />
        <Skeleton width="100%" height={250} />
      </div>
      <Grid columns={2}>
        <div className="input_holder" style={{ marginBottom: 40 }}>
          <Skeleton width={100} height={15} style={{ marginBottom: 10 }} />
          <Skeleton width="100%" height={40} />
        </div>
        <div className="input_holder" style={{ marginBottom: 40 }}>
          <Skeleton width={100} height={15} style={{ marginBottom: 10 }} />
          <Skeleton width="100%" height={40} />
        </div>
      </Grid>
    </Card>
    <Card title={<Skeleton width={150} height={22} />}>
      <div className="input_holder" style={{ marginBottom: 15 }}>
        <Skeleton width={100} height={15} style={{ marginBottom: 10 }} />
        <Skeleton width="100%" height={40} />
      </div>
    </Card>
    <Card title={<Skeleton width={150} height={22} />}>
      <Skeleton width={100} height={15} style={{ marginBottom: 10 }} />
      <Grid columns={2}>
        <Skeleton width="100%" height={200} />
        <div className="flex justify" style={{ padding: "10px 0" }}>
          <Skeleton width={34} height={34} borderRadius={7} />
          <Skeleton width={80} height={20} />
        </div>
      </Grid>
    </Card>
  </div>
);

export default EditVoucherLoader;
