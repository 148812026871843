import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { VouchersTable, MainContent } from "@vms-admin/components";
import {
  fetchVouchers,
  fetchClientVouchers,
  fetchBrandVouchers,
  fetchMerchantVouchers,
  fetchClientUserVouchers,
  fetchBrandUserVouchers,
  fetchMerchantUserVouchers,
  fetchCustomerVouchers,
} from "../../redux/actions";

export const Vouchers = () => {
  const [userVouchers, setUserVouchers] = useState([]);
  const vouchers = useSelector(({ voucher }) => voucher);
  const userInfo = useSelector(({ auth }) => auth.userInfo);
  const userRole = useSelector(({ auth }) => auth.role);

  const dispatch = useDispatch();

  useEffect(() => {
    userRole.is_client && dispatch(fetchClientVouchers(userInfo?.id));
    userRole.is_client_user &&
      dispatch(fetchClientUserVouchers(userInfo?.client_id));

    userRole.is_brand && dispatch(fetchBrandVouchers(userInfo?.id));
    userRole.is_brand_user &&
      dispatch(fetchBrandUserVouchers(userInfo?.brand_id));

    userRole.is_merchant && dispatch(fetchMerchantVouchers(userInfo?.id));
    userRole.is_merchant_user &&
      dispatch(fetchMerchantUserVouchers(userInfo?.merchant_id));

    userRole.is_admin && dispatch(fetchVouchers());

    userRole.is_customer && dispatch(fetchCustomerVouchers(userInfo?.id));
  }, [dispatch, userRole, userInfo]);

  useEffect(() => {
    userRole.is_client && setUserVouchers(vouchers.clientVouchers);
    userRole.is_client_user && setUserVouchers(vouchers.clientUserVouchers);

    userRole.is_brand && setUserVouchers(vouchers.brandVouchers);
    userRole.is_brand_user && setUserVouchers(vouchers.brandUserVouchers);

    userRole.is_merchant && setUserVouchers(vouchers.merchantVouchers);
    userRole.is_merchant_user && setUserVouchers(vouchers.merchantUserVouchers);

    userRole.is_admin && setUserVouchers(vouchers?.vouchers);

    userRole.is_customer && setUserVouchers(vouchers?.customerVouchers);
  }, [userRole, vouchers]);

  return (
    <MainContent>
      <VouchersTable
        vouchers={userVouchers}
        title="Voucher Management"
        vouchersLoading={vouchers.vouchersLoading}
      />
    </MainContent>
  );
};

export default Vouchers;
