import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  MainContent,
  TableCard,
  Pagination,
  TableCardLoader,
} from "@vms-admin/components";
import { fetchSubscriptions } from "../../redux/actions";
import {
  formatDate,
  formatTime,
  inActive,
  active as activeHelper,
} from "../../helpers";
import { usePagination } from "../../hooks/usePagination";

export const Subscriptions = () => {
  const dispatch = useDispatch();
  const subscriptions = useSelector(({ subscriptions }) => subscriptions);

  const {
    goToNextPage,
    goToPrevPage,
    pagesArray,
    canGoNext,
    canGoPrev,
    currentPage,
    pageDecrementBtn,
    pageIncementBtn,
  } = usePagination(subscriptions?.pages);

  useEffect(
    () =>
      dispatch(fetchSubscriptions(currentPage))
        .then((res) => console.log("ahahah", res))
        .catch((error) => console.log("Errr", error)),
    [dispatch, currentPage]
  );

  return (
    <MainContent>
      <TableCard title="Subscriptions Management">
        {!subscriptions?.subscriptions?.data?.length &&
          !subscriptions.loading && (
            <tbody>
              <tr>
                <td className="empty_list_message" colSpan={7}>
                  No Subscriptions found
                </td>
              </tr>
            </tbody>
          )}
        {subscriptions.loading ? (
          <TableCardLoader />
        ) : (
          <>
            <thead>
              <tr>
                <td>#</td>
                <td>Customer Name</td>
                <td>Customer Email</td>
                <td>Subscribed At</td>
                <th>Plan Name</th>
                <th>Payment Status</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {subscriptions?.subscriptions?.data?.map(
                (
                  {
                    id,
                    active,
                    payment_status,
                    customer,
                    subscription_plan,
                    valid_from,
                  },
                  index
                ) => (
                  <tr key={id}>
                    <td>{index + 1}</td>
                    <td>
                      {customer?.firstname} {customer?.lastname}
                    </td>
                    <td>{customer?.email}</td>
                    <td>
                      {valid_from && new Date(valid_from).toLocaleString()}
                    </td>
                    <td>{subscription_plan?.plan_name}</td>
                    <td>{payment_status}</td>
                    <td>
                      {active ? activeHelper("Active") : inActive("InActive")}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </>
        )}
      </TableCard>
      {subscriptions?.pages > 1 && (
        <Pagination
          canGoNext={canGoNext}
          canGoPrev={canGoPrev}
          goToNextPage={goToNextPage}
          goToPrevPage={goToPrevPage}
          pagesArray={pagesArray}
          pageDecrementBtn={pageDecrementBtn}
          pageIncementBtn={pageIncementBtn}
        />
      )}
    </MainContent>
  );
};

export default Subscriptions;
