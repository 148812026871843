import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import toast from "react-hot-toast";

import {
  Card,
  Input,
  Grid,
  MainContent,
  Button,
  TableCard,
} from "@vms-admin/components";
import { Mastercard, Visa } from "@vms-admin/icons";
import API_URL from "../../api/api.constants";
import { dismissToast, getCardType, somethignWentWrong } from "../../helpers";
import * as Styled from "./payment.style";
import { Link } from "react-router-dom";
import axios from "axios";

export const Payment = () => {
  const accessToken = localStorage.getItem("access-token");
  const subscriptionId = localStorage.getItem("subscription_id");

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const history = useHistory();

  const handleCardType = useCallback(() => {
    if (getCardType(`${watch("cardNumber")}`).toLowerCase() === "visa") {
      return <Visa />;
    }

    if (getCardType(`${watch("cardNumber")}`).toLowerCase() === "mastercard") {
      return <Mastercard />;
    }
  }, [watch]);

  const pay = useCallback(() => {
    dismissToast();
    axios(`${API_URL.subscriptions}/${subscriptionId}/pay`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 200) {
          setTimeout(() => history.push("/profile"), [2000]);
          localStorage.removeItem("subscription_id");
          toast.success("You have successfully subscribed to this plan");
        }
      })
      .catch(() => somethignWentWrong());
  }, [accessToken, subscriptionId, history]);

  const footerContent = (
    <div class="flex justify">
      <div className="kmmrce-radio radio">
        <input
          type="checkbox"
          name="getSubscriptionPlan"
          value="country"
          register={register}
          id="radioCountry"
          {...register("getSubscriptionPlan")}
          className="radio_input"
        />
        <label htmlFor="radioCountry" className="radio_label">
          Save card now to enable express payments
        </label>
      </div>

      <Button variant="primary" type="submit">
        Make Payment
      </Button>
    </div>
  );

  return (
    <MainContent>
      <Grid columns={2} style={{ marginBottom: 20 }}>
        <TableCard>
          <tbody>
            <tr>
              <td>Order ID</td>
              <td className="value_td align_right">JK659776</td>
            </tr>
          </tbody>
        </TableCard>
        <TableCard>
          <tbody>
            <tr>
              <td>Amount</td>
              <td className="value_td align_right">AED 12.00</td>
            </tr>
          </tbody>
        </TableCard>
      </Grid>
      <form onSubmit={handleSubmit(pay)}>
        <Card title="Payment" footerContent={footerContent}>
          <Grid columns={2}>
            <Input
              label="Card holder’s name"
              type="text"
              placeholder="Name here"
              name="nameOnCard"
              register={register}
              errors={errors}
            />
            <Input
              label="Card Number"
              type="text"
              placeholder="Card Number"
              name="cardNumber"
              register={register}
              errors={errors}
              icon={handleCardType()}
            />
          </Grid>
          <Grid columns={2}>
            <Grid columns={2}>
              <Input
                label="Card Expiry"
                type="text"
                placeholder="12/12"
                name="cardExpiry"
                register={register}
                errors={errors}
              />
              <Input
                label="CCV"
                type="text"
                placeholder="CCV"
                name="ccv"
                register={register}
                errors={errors}
              />
            </Grid>
          </Grid>
        </Card>
      </form>
      <Styled.HelperTextWrapper>
        <Styled.HelperText>
          * Convenience fee of AED 0.00 has been levied by <span>KMMRCE</span>{" "}
          Test Merchant.
        </Styled.HelperText>
        <Styled.HelperText>
          * Your transaction will appear on your bank or credit statement as
          ‘airpay’ or <span>‘KMMRCE</span> Test Merchant’. To Cancel this
          transaction, please
          <Link to="/"> click here</Link>
        </Styled.HelperText>
      </Styled.HelperTextWrapper>
    </MainContent>
  );
};

export default Payment;
