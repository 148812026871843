import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useInView } from "react-intersection-observer";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";

import {
  MainContent,
  Card,
  Switch,
  Grid,
  InputRangeField,
  Button,
  Buttons,
} from "@vms-admin/components";
import {
  fetchMerchant,
  fetchMerchantAmenities,
  fetchMerchantAttributes,
  updateFormData,
} from "../../../redux/actions";
import { dismissToast, getBase64, somethignWentWrong } from "../../../helpers";
import API_URL from "../../../api/api.constants";
import axios from "axios";

const weekdays = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const EditFinalStep = ({ prevStep }) => {
  const accessToken = localStorage.getItem("access-token");
  const { register, handleSubmit } = useForm({ mode: "onChange" });
  const merchant = useSelector(({ merchant }) => merchant);
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  console.log("merchant?.formData", merchant.merchant);
  const [amenities, setAmenities] = useState(
    merchant?.merchant?.amenities || []
  );
  const [attributes, setAttributes] = useState(
    merchant?.merchant?.attributes || []
  );
  const { ref, inView } = useInView();

  const handleAmenitiesChange = ({ target: { checked } }, amenityId) => {
    if (checked && !amenities?.includes(amenityId)) {
      setAmenities([...amenities, amenityId]);
    } else {
      let remaining = amenities?.filter((amenity) => amenity !== amenityId);
      setAmenities(remaining);
    }
  };

  const handleAttributeChange = ({ target: { checked } }, attributeId) => {
    if (checked && !attributes?.includes(attributeId)) {
      setAttributes([...attributes, attributeId]);
    } else {
      let remaining = attributes?.filter(
        (attribute) => attribute !== attributeId
      );
      setAttributes(remaining);
    }
  };

  const initialOpeningHours = Object.fromEntries(
    merchant?.merchant?.opening_hours?.map((weekday) => [
      weekday.name,
      {
        min: weekday.value.open,
        max: weekday.value.close,
      },
    ])
  );
  const [openingHours, setOpeningHours] = useState(initialOpeningHours);

  const handleOpeningHoursChange = (value, name) =>
    setOpeningHours({ ...openingHours, [name]: { ...value } });

  const amenitiesIds = useMemo(
    () => merchant?.merchant?.amenities?.map(({ id }) => id),
    [merchant?.merchant?.amenities]
  );

  const attributesIds = useMemo(
    () => merchant?.merchant?.attributes?.map(({ id }) => id),
    [merchant?.merchant?.attributes]
  );

  const OpeningHoursData = useMemo(
    () =>
      weekdays.map((weekday) => ({
        name: weekday,
        value: {
          close: openingHours[weekday].max,
          open: openingHours[weekday].min,
        },
      })),
    [openingHours]
  );

  const editMerchant = useCallback(async () => {
    let base64Image = "";
    try {
      base64Image = await getBase64(merchant?.formData?.logo?.[0]);

      dismissToast();
      const image = base64Image || merchant?.merchant?.image;
      axios(`${API_URL.merchants}/${id}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Access-Control-Allow-Origin": "*",
        },
        data: {
          merchant_name: merchant.formData.name,
          category_id: merchant.formData.category.id,
          email: merchant.formData.email,
          country: merchant.formData.countryName?.value,
          city: merchant.formData.cityName?.value,
          brand_id: merchant.formData.brandName?.id,
          telephone: merchant.formData.mobileNumber,
          po_box: merchant.formData.poBox,
          subscription_plan_id: merchant.formData?.plans?.id,
          description: merchant.formData.desc,
          address: merchant.formData.address,
          lat: merchant.formData.lat,
          long: merchant.formData.lng,
          image,
          amenities: amenities?.map((amenity) => ({
            id: amenity.id,
          })),
          attributes: attributes?.map((attribute) => ({
            id: attribute.id,
          })),
          opening_hours: OpeningHoursData,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            setTimeout(() => history.push("/merchant-management"), [2000]);
            toast.success(
              `${merchant.formData.name} has been edited Successfully`
            );
            dispatch(updateFormData({}));
          } else if (res.status === 409) {
            toast.error("User already exist");
          } else if (res.status === 401) {
            history.push("/");
          } else {
            somethignWentWrong();
          }
        })
        .then((data) => console.log("dataaaa", data))
        .catch(() => somethignWentWrong());
    } catch (error) {
      console.log("error", error);
    }
  }, [
    merchant,
    id,
    accessToken,
    amenities,
    attributes,
    OpeningHoursData,
    dispatch,
    history,
  ]);

  useEffect(() => {
    dispatch(fetchMerchant(id));
    dispatch(fetchMerchantAmenities());
    dispatch(fetchMerchantAttributes());
  }, [dispatch, id]);

  return (
    <MainContent>
      <form onSubmit={handleSubmit(editMerchant)}>
        <div className="container_mobile">
          <div className="page-header" ref={ref}>
            <h1>Merchant Management</h1>
            <div className={inView ? `` : `sticky-action-bar`}>
              <Buttons noMargin>
                <Button handleClick={prevStep} variant="secondary">
                  Back
                </Button>
                <Button type="submit" variant="primary">
                  Save Changes
                </Button>
              </Buttons>
            </div>
          </div>
        </div>
        <div className="cards_wrapper">
          <Card title="Merchant Amenities">
            <Grid columns={2}>
              {merchant?.amenities?.map(
                ({ amenity_name, amenity_icon, id }) => {
                  return (
                    <Switch
                      handleChange={(e) => handleAmenitiesChange(e, id)}
                      name={amenity_name}
                      icon={amenity_icon}
                      register={register}
                      label={amenity_name}
                      key={id}
                      defaultValue={amenitiesIds?.includes(id)}
                    />
                  );
                }
              )}
            </Grid>
          </Card>
          <Card title="Merchant Attributes">
            <Grid columns={2}>
              {merchant?.attributes?.map(
                ({ attribute_name, attribute_icon, id }) => (
                  <Switch
                    label={attribute_name}
                    icon={attribute_icon}
                    register={register}
                    name={attribute_name}
                    key={id}
                    handleChange={(e) => handleAttributeChange(e, id)}
                    defaultValue={attributesIds?.includes(id)}
                  />
                )
              )}
            </Grid>
          </Card>
          <Card title="Merchant Opening Hours">
            {weekdays.map((weekday, index) => {
              return (
                <InputRangeField
                  key={index}
                  step={1}
                  maxValue={24}
                  minValue={0}
                  label={weekday}
                  name={weekday.toLowerCase()}
                  handleChange={(value) =>
                    handleOpeningHoursChange(value, weekday)
                  }
                  value={openingHours[weekday]}
                />
              );
            })}
          </Card>
        </div>
      </form>
    </MainContent>
  );
};

export default EditFinalStep;
