import axios from "axios";
import API_URL from "../../api/api.constants";
import { getBase64 } from "../../helpers";
import {
  FETCH_PLANS_SUCCESS,
  FETCH_PLANS_BY_COUNTRY_SUCCESS,
  FETCH_PLANS_BY_CITY_SUCCESS,
  SET_SUBSCRIPTION_ID,
  CREATE_PLAN_SUCCESS,
  FETCH_PLAN_SUCCESS,
  EDIT_PLAN_SUCCESS,
  DELETE_PLAN_SUCCESS,
  SUBSCRIBE_PLAN_SUCCESS,
  FETCH_PLANS_LOADING,
  FETCH_PLAN_LOADING,
} from "./actions.constants";

export const fetchPlans =
  (page = 1) =>
  (dispatch) => {
    dispatch({ type: FETCH_PLANS_LOADING });
    return axios
      .get(`${API_URL.plans}?page=${page}&per_page=50`)
      .then(
        (res) =>
          res.status === 200 &&
          dispatch({ type: FETCH_PLANS_SUCCESS, plans: res.data.plans })
      )
      .catch((error) => console.log("error", error));
  };

export const fetchPlan = (planId) => (dispatch) => {
  dispatch({ type: FETCH_PLAN_LOADING });

  return axios
    .get(`${API_URL.plans}/${planId}`)
    .then(
      (res) =>
        res.status === 200 &&
        dispatch({ type: FETCH_PLAN_SUCCESS, plan: res.data.plan })
    )
    .catch((error) => console.log("error", error));
};

export const fetchPlansByCountryCode = (userCountry) => (dispatch) => {
  const accessToken = localStorage.getItem("access-token");
  let config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios(`${API_URL.plans}?country=${userCountry}&is_active=True`, config)
    .then((res) => res.status === 200 && res.data)
    .then(({ plans }) =>
      dispatch({ type: FETCH_PLANS_BY_COUNTRY_SUCCESS, plans })
    )
    .catch((error) => console.log("error", error));
};

export const fetchPlansByCityCode = (useCity) => (dispatch) => {
  const accessToken = localStorage.getItem("access-token");
  let config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios(`${API_URL.plans}?city=${useCity}&is_active=True`, config)
    .then((res) => res.status === 200 && res.data)
    .then(({ plans }) => dispatch({ type: FETCH_PLANS_BY_CITY_SUCCESS, plans }))
    .catch((error) => console.log("error", error));
};

export const createPlan = (formData) => async (dispatch) => {
  const accessToken = localStorage.getItem("access-token");
  const image = await getBase64(formData.image[0]);

  return axios(API_URL.plans, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    data: {
      plan_name: formData.planName,
      plan_description: formData.planDesc,
      max_members: formData.maxMembers,
      plan_price_per_day: formData.perDay,
      plan_price_per_month: formData.perMonth,
      plan_price_per_year: formData.perYear,
      start_date: formData.startDate,
      end_date: formData.endDate,
      country: formData.countryName.value,
      city: formData.cityName.value,
      currency_code: formData.currencyName.value,
      image: image,
      plan_features: formData.planFeatures,
    },
  })
    .then((res) => {
      dispatch({ type: CREATE_PLAN_SUCCESS });
      return Promise.resolve(res);
    })
    .catch((error) => Promise.reject(error));
};

export const editPlan =
  (formData, planData, planId, startDate, endDate) => async (dispatch) => {
    const accessToken = localStorage.getItem("access-token");
    const image = formData.image[0]
      ? await getBase64(formData.image[0])
      : planData?.image;
    return axios(`${API_URL.plans}/${planId}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        plan_name: formData.planName,
        plan_description: formData.planDesc,
        max_members: formData.maxMembers,
        plan_price_per_day: formData.perDay,
        plan_price_per_month: formData.perMonth,
        plan_price_per_year: formData.perYear,
        start_date: startDate,
        end_date: endDate,
        country: formData.countryName.value,
        city: formData.cityName.value,
        currency_code: formData.currencyName.value,
        image,
        plan_features: formData.planFeatures,
      },
    })
      .then((res) => {
        dispatch({ type: EDIT_PLAN_SUCCESS });
        return Promise.resolve(res);
      })
      .catch((error) => Promise.reject(error));
  };

export const deletePlan = (planId) => (dispatch) => {
  const accessToken = localStorage.getItem("access-token");
  let config = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios(`${API_URL.plans}/${planId}`, config)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: DELETE_PLAN_SUCCESS });
        return Promise.resolve(res);
      }
    })
    .catch((error) => Promise.reject(error));
};

export const subscribe = (planId) => (dispatch) => {
  const accessToken = localStorage.getItem("access-token");
  return axios(`${API_URL.plans}/${planId}/subscribe`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((res) => res.status === 200 && res.data)
    .then(({ data }) => {
      if (data) {
        dispatch({ type: SUBSCRIBE_PLAN_SUCCESS });
        return Promise.resolve(data);
      }
    })
    .catch((error) => Promise.reject(error));
};

export const saveSubscriptionId = (id) => (dispatch) =>
  dispatch({ type: SET_SUBSCRIPTION_ID, id });
