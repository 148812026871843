import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMerchantAmenities,
  fetchMerchantAttributes,
} from "../../redux/actions";
import Swiper from "swiper";
import { Autoplay } from "swiper/modules";
import * as Styled from "./mobile.style";
import "swiper/swiper.css";
import "swiper/modules/pagination.min.css";
import "swiper/modules/autoplay.min.css";

export const Mobile = ({
  voucherName,
  voucherDesc,
  voucherCategory,
  voucherImages,
}) => {
  const dispatch = useDispatch();
  const merchant = useSelector(({ merchant }) => merchant?.merchant);
  const [swiper, setSwiper] = useState(null);

  useEffect(() => {
    dispatch(fetchMerchantAmenities());
    dispatch(fetchMerchantAttributes());
  }, [dispatch]);

  useEffect(() => {
    const swiper = new Swiper(".swiper-container", {
      slidesPerView: 1,
      spaceBetween: 30,
      loop: true,
      autoplay: {
        delay: 2000,
        pauseOnMouseEnter: true,
      },
      modules: [Autoplay],
    });

    setSwiper(swiper);
  }, []);

  useEffect(() => {
    swiper?.update();
  }, [swiper, voucherImages, merchant]);

  return (
    <Styled.Mobile>
      <Styled.SwiperWrapper>
        <div className="swiper swiper-container">
          <div className="swiper-wrapper">
            <div className="swiper-slide">
              <div
                style={{
                  maxWidth: "100%",
                  height: "200px",
                }}
              >
                <img
                  src={merchant?.image}
                  alt={merchant?.merchant_name}
                  style={{
                    objectFit: "cover",
                    height: "100%",
                  }}
                />
              </div>
            </div>
            {voucherImages?.map((voucherImage, index) => (
              <div key={index} className="swiper-slide">
                <div
                  style={{
                    maxWidth: "100%",
                    height: "200px",
                  }}
                >
                  <img
                    src={voucherImage}
                    alt={voucherName}
                    style={{
                      objectFit: "cover",
                      height: "100%",
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </Styled.SwiperWrapper>
      <Styled.Body>
        <Styled.MerchantName>{merchant?.merchant_name}</Styled.MerchantName>
        <Styled.MerchantAddress>{merchant?.address}</Styled.MerchantAddress>
        {voucherCategory && (
          <Styled.Category>
            <Styled.CategoryText>
              {voucherCategory?.category_name}
            </Styled.CategoryText>
            <Styled.CategoryImg
              src={voucherCategory?.voucher_category_icon}
              alt={voucherCategory?.category_name}
            />
          </Styled.Category>
        )}
        <Styled.VoucherDetails>
          <Styled.Heading>Voucher Details</Styled.Heading>
          <Styled.Wrapper>
            <Styled.Title>Voucher name</Styled.Title>
            <Styled.Text>{voucherName}</Styled.Text>
          </Styled.Wrapper>
          <Styled.Wrapper>
            <Styled.Title>Voucher description</Styled.Title>
            <Styled.Text>{voucherDesc}</Styled.Text>
          </Styled.Wrapper>
        </Styled.VoucherDetails>
        {merchant?.amenities?.length > 0 && (
          <Styled.Features>
            <Styled.Heading>Amenities</Styled.Heading>
            <Styled.List>
              {merchant.amenities.map(({ amenity_name, amenity_icon, id }) => (
                <Styled.Item
                  key={id}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: 16,
                    marginBottom: 8,
                    padding: 8,
                    borderRadius: 7,
                  }}
                >
                  <img
                    src={amenity_icon}
                    alt={amenity_name}
                    style={{
                      width: "30px",
                      marginBottom: 8,
                    }}
                  />
                  <p>{amenity_name}</p>
                </Styled.Item>
              ))}
            </Styled.List>
          </Styled.Features>
        )}
        {merchant?.attributes?.length > 0 && (
          <Styled.Features>
            <Styled.Heading>Attributes</Styled.Heading>
            <Styled.List>
              {merchant?.attributes?.map(
                ({ attribute_name, attribute_icon, id }) => (
                  <Styled.Item
                    key={id}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: 16,
                      marginBottom: "8px",
                      padding: 8,
                      borderRadius: 7,
                    }}
                  >
                    <img
                      src={attribute_icon}
                      alt={attribute_name}
                      style={{ width: "30px", marginBottom: 8 }}
                    />
                    <p>{attribute_name}</p>
                  </Styled.Item>
                )
              )}
            </Styled.List>
          </Styled.Features>
        )}
      </Styled.Body>
    </Styled.Mobile>
  );
};

export default Mobile;
