import React from "react";
import { Link } from "react-router-dom";

import { TableCard, Button } from "@vms-admin/components";

export const UsersTable = ({ users, deleteUser, hasActions = true }) => {
  return (
    <TableCard
      title="Users"
      btnText="Create New User"
      btnUrl="/create-user"
      hasLink={hasActions}
    >
      {!users?.length && (
        <tbody>
          <tr>
            <td className="empty_list_message">No Users found</td>
          </tr>
        </tbody>
      )}
      {users?.length > 0 && (
        <>
          <thead>
            <tr>
              <td>#</td>
              <td>First Name</td>
              <td>Last Name</td>
              <td>Email Address</td>
              <td>Is Admin</td>
              <td>Avatar</td>
              {hasActions && <td>Actions</td>}
            </tr>
          </thead>
          <tbody>
            {users?.map(
              (
                { first_name, last_name, email, avatar, id, user_admin },
                index
              ) => (
                <tr initial={{ opacity: 0 }} animate={{ opacity: 1 }} key={id}>
                  <td>{index + 1}</td>
                  <td>{first_name}</td>
                  <td>{last_name}</td>
                  <td>{email}</td>
                  <td>{user_admin ? "Yes" : "No"}</td>
                  <td>
                    <img src={avatar} width="16px" alt={first_name} />
                  </td>
                  {hasActions && (
                    <td>
                      <Link className="btn btn-tertiary" to={`/user/${id}`}>
                        View
                      </Link>
                      <Link
                        className="btn btn-tertiary"
                        to={`/edit-user/${id}`}
                      >
                        Edit
                      </Link>
                      <Button
                        variant="danger"
                        handleClick={() => deleteUser(id)}
                      >
                        Delete
                      </Button>
                    </td>
                  )}
                </tr>
              )
            )}
          </tbody>
        </>
      )}
    </TableCard>
  );
};

export default UsersTable;
