import axios from "axios";
import API_URL from "../../api/api.constants";
import {
  FETCH_ACTIVITY_LOGS_SUCCESS,
  FETCH_ACTIVITY_LOG_SUCCESS,
} from "./actions.constants";

export const fetchActivityLogs =
  (page, limit = 50) =>
  (dispatch) => {
    return axios
      .get(`${API_URL.activityLogs}?page=${page}&per_page=${limit}`)
      .then(
        (res) =>
          res.status === 200 &&
          dispatch({ type: FETCH_ACTIVITY_LOGS_SUCCESS, data: res.data.data })
      )
      .catch((error) => console.log("error", error));
  };

export const fetchActivityLog = (activityId) => (dispatch) => {
  return axios
    .get(`${API_URL.activityLogs}/${activityId}`)
    .then((res) =>
      dispatch({ type: FETCH_ACTIVITY_LOG_SUCCESS, data: res.data.data })
    )
    .catch((error) => console.log("error", error));
};
