import React, { useMemo, useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useInView } from "react-intersection-observer";

import {
  Switch,
  MultipleUpload,
  Mobile,
  Card,
  Grid,
  ErrorMessage,
  InputRangeField,
  Input,
  Textarea,
  Button,
  MainContent,
  Buttons,
  CustomDatePicker,
  MobileLoader,
} from "@vms-admin/components";
import API_URL from "../../../api/api.constants";
import {
  fetchVoucherCategories,
  fetchVoucherScopes,
  fetchPlans,
  fetchPlansByCityCode,
  fetchPlansByCountryCode,
  fetchBrand,
  fetchBrandMerchants,
  fetchBrandUserMerchants,
  fetchVoucher,
} from "../../../redux/actions";

import { getPlansOptions, getVoucherScopesOptions } from "../../../utils";
import { editVoucherSchema } from "../../../schema";
import { dismissToast, somethignWentWrong } from "../../../helpers";
import EditVoucherLoader from "./edit-voucher-loader";
import axios from "axios";
import { useHistory } from "react-router";

const weekdays = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const EditVoucher = () => {
  const accessToken = localStorage.getItem("access-token");
  const [tabIndex, setTabIndex] = useState(0);
  const dispatch = useDispatch();
  const [voucherImages, setVoucherImages] = useState([]);
  const [getPlanBy, setGetPlansBy] = useState(null);
  const userInfo = useSelector(({ auth }) => auth.userInfo);
  const voucherCategories = useSelector(({ voucher }) => voucher.categories);
  const userRole = useSelector(({ auth }) => auth.role);
  const { id } = useParams();

  const {
    watch,
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(editVoucherSchema),
  });

  const voucherDetailsIsInvalid =
    !watch("voucherName") ||
    !watch("voucherDesc") ||
    !watch("voucherCount") ||
    !watch("pushTo") ||
    !watch("subscriptionPlans") ||
    !watch("voucherCategory");

  const voucherName = watch("voucherName");
  const voucherDesc = watch("voucherDesc");
  const selectedVoucherCategory = watch("voucherCategory");
  const voucherCategory = useMemo(
    () =>
      voucherCategories?.find(
        (category) => category.id === parseInt(selectedVoucherCategory)
      ),
    [voucherCategories, selectedVoucherCategory]
  );
  const history = useHistory();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const voucher = useSelector(({ voucher }) => voucher);
  const voucherDetails = useSelector(({ voucher }) => voucher?.voucher);
  const [openingHours, setOpeningHours] = useState(null);
  const { ref, inView } = useInView();

  const initialOpeningHours = useMemo(
    () =>
      voucherDetails?.voucher_set_details &&
      Object.fromEntries(
        voucherDetails?.voucher_set_details?.map((weekday) => [
          weekday.name,
          {
            min: weekday.value.open,
            max: weekday.value.close,
          },
        ])
      ),
    [voucherDetails?.voucher_set_details]
  );

  useEffect(() => {
    setOpeningHours(initialOpeningHours);
  }, [initialOpeningHours]);

  const plansByCountry = useSelector(
    ({ plans }) => plans?.plansByCountry?.data
  );
  const plansByCity = useSelector(({ plans }) => plans?.plansByCity?.data);
  const plans = useSelector(({ plans }) => plans.plans?.data);

  const activePlans = useMemo(
    () => plans?.filter((plan) => plan.is_active === true),
    [plans]
  );

  const handleChange = (value, name) =>
    setOpeningHours({ ...openingHours, [name]: { ...value } });

  const OpeningHoursData = useCallback(
    () =>
      weekdays.map((weekday) => ({
        name: weekday,
        value: {
          close: openingHours[weekday]?.max,
          open: openingHours[weekday]?.min,
        },
      })),
    [openingHours]
  );

  const selectedPlansId = useCallback(
    (plans) => getPlansOptions(plans)?.map(({ id }) => id),
    []
  );

  const setSubscriptionPlanOptions = useCallback(() => {
    if (getPlanBy === "country") return getPlansOptions(plansByCountry);
    if (getPlanBy === "city") return getPlansOptions(plansByCity);
    if (!getPlanBy && userRole.is_admin) return getPlansOptions(activePlans);
  }, [getPlanBy, userRole, activePlans, plansByCity, plansByCountry]);

  useEffect(() => {
    dispatch(fetchVoucher(id));
  }, [dispatch, id]);

  useEffect(() => {
    voucherDetails?.start_datetime &&
      setStartDate(new Date(voucherDetails.start_datetime));
    voucherDetails?.end_datetime &&
      setEndDate(new Date(voucherDetails.end_datetime));
  }, [voucherDetails]);

  useEffect(() => {
    setValue("voucherName", voucherDetails?.voucher_set_name);
    setValue("voucherDesc", voucherDetails?.description);
    setValue("voucherCount", voucherDetails?.count);
    setValue(
      "subscriptionPlans",
      voucherDetails?.subscription_plans?.map(({ plan_name, id }) => ({
        label: plan_name,
        value: plan_name,
        id,
      }))
    );
    // setValue("voucherCategory", voucherDetails?.category?.id);
    setValue("pushTo", {
      label: voucherDetails?.scope?.voucher_scope_name,
      value: voucherDetails?.scope?.voucher_scope_code,
    });
    voucherDetails?.start_datetime &&
      setValue("startDate", new Date(voucherDetails.start_datetime));
    voucherDetails?.end_datetime &&
      setValue("endDate", new Date(voucherDetails.end_datetime));
    voucherDetails?.category?.id &&
      setValue("voucherCategory", voucherDetails?.category?.id);
  }, [setValue, voucherDetails]);

  const editVoucher = useCallback(
    (data) => {
      dismissToast();
      axios(`${API_URL.vouchers}/${id}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        data: {
          voucher_set_name: data.voucherName,
          voucher_set_details: OpeningHoursData(),
          voucher_set_category: parseInt(data.voucherCategory),
          merchant_id: voucherDetails?.merchant?.id,
          start_datetime: startDate,
          end_datetime: endDate,
          description: data.voucherDesc,
          push_to: data.pushTo.id,
          images: voucherImages,
          count: parseInt(data.voucherCount),
          subscription_plans: selectedPlansId(data.subscriptionPlans),
          exclude_holidays: data.excludeHolidays,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            setTimeout(() => history.push("/vouchers"), [2000]);
            toast.success(`Voucher has been created Successfully`);
          }
          if (res.status === 401) {
            localStorage.removeItem("access-token");
            history.push("/");
          }
        })
        .catch(() => somethignWentWrong());
    },
    [
      id,
      accessToken,
      OpeningHoursData,
      voucherDetails?.merchant?.id,
      startDate,
      endDate,
      voucherImages,
      selectedPlansId,
      history,
    ]
  );

  useEffect(() => {
    userRole.isBrand && dispatch(fetchBrand(userInfo?.id));
    userRole.isBrand && dispatch(fetchBrandMerchants(userInfo?.id));
    userRole.is_admin && dispatch(fetchPlans());
    if (!userRole.is_admin) {
      getPlanBy === "city"
        ? dispatch(fetchPlansByCityCode(userInfo?.city_code))
        : dispatch(fetchPlansByCountryCode(userInfo?.country_code));
    }
  }, [
    dispatch,
    userInfo?.country_code,
    userInfo?.city_code,
    getPlanBy,
    userRole,
    userInfo?.id,
    userInfo?.brand_id,
    tabIndex,
  ]);

  useEffect(() => {
    dispatch(fetchVoucherCategories());
    dispatch(fetchVoucherScopes());
  }, [dispatch]);

  useEffect(() => {
    if (userRole.is_brand_user) {
      dispatch(fetchBrandUserMerchants(userInfo?.brand_id));
    }
  }, [dispatch, userRole.is_brand_user, userInfo?.brand_id]);

  return (
    <MainContent>
      <form onSubmit={handleSubmit(editVoucher)}>
        <div className="container_mobile">
          <div className="page-header" ref={ref}>
            <h1>Edit Voucher</h1>
            <div className={inView ? `` : `sticky-action-bar`}>
              <Buttons noMargin>
                <Button type="submit" variant="primary">
                  Save Changes
                </Button>
              </Buttons>
            </div>
          </div>
        </div>
        <Grid>
          <div className="cards_wrapper">
            <Tabs
              selectedIndex={tabIndex}
              onSelect={(tabIndex) => setTabIndex(tabIndex)}
            >
              <TabList>
                <Tab>Voucher Details</Tab>
                <Tab disabled={voucherDetailsIsInvalid}>Status</Tab>
              </TabList>

              <TabPanel>
                {voucher.voucherLoading ? (
                  <EditVoucherLoader />
                ) : (
                  <div className="cards_wrapper">
                    <Card>
                      <Input
                        type="text"
                        name="voucherName"
                        register={register}
                        placeholder="Voucher Name"
                        label="Voucher Name"
                        errors={errors}
                      />
                      <Textarea
                        type="text"
                        name="voucherDesc"
                        register={register}
                        placeholder="Voucher Description"
                        label="Voucher Description"
                        errors={errors}
                        value={voucherDetails?.description}
                      />
                      <Grid columns={2}>
                        <Input
                          type="number"
                          register={register}
                          name="voucherCount"
                          placeholder="Number of Vouchers"
                          label="Number of Vouchers"
                          errors={errors}
                        />
                        <div className="input_holder">
                          <label>Push To</label>
                          <Controller
                            name="pushTo"
                            errors={errors}
                            control={control}
                            render={({ field }) => (
                              <Select
                                menuPortalTarget={document.body}
                                {...field}
                                options={getVoucherScopesOptions(
                                  voucher.scopes
                                )}
                                placeholder="Select Category"
                                className="kmmrce-select-container"
                                classNamePrefix="kmmrce-select"
                              />
                            )}
                          />
                          {errors && errors["pushTo"] && (
                            <ErrorMessage text="Push to is Required" />
                          )}
                        </div>
                      </Grid>
                    </Card>
                    <Card title="Upload Voucher Images">
                      {!userRole.is_admin && (
                        <div
                          style={{
                            display: "flex",
                            marginTop: 10,
                            marginBottom: 40,
                          }}
                        >
                          <div className="kmmrce-radio radio">
                            <input
                              type="radio"
                              name="getSubscriptionPlan"
                              value="country"
                              register={register}
                              id="radioCountry"
                              {...register("getSubscriptionPlan")}
                              className="radio_input"
                            />
                            <label
                              htmlFor="radioCountry"
                              style={{ display: "flex", alignItems: "center" }}
                              className="radio_label"
                            >
                              By Country
                            </label>
                          </div>

                          <div
                            className="kmmrce-radio radio"
                            style={{ marginLeft: "16px" }}
                          >
                            <input
                              type="radio"
                              name="getSubscriptionPlan"
                              value="city"
                              register={register}
                              id="radioCity"
                              {...register("getSubscriptionPlan", {
                                onChange: ({ target: { value } }) =>
                                  setGetPlansBy(value),
                              })}
                              className="radio_input"
                            />
                            <label
                              htmlFor="radioCity"
                              style={{ display: "flex", alignItems: "center" }}
                              className="radio_label"
                            >
                              By City
                            </label>
                          </div>
                        </div>
                      )}
                      {errors && errors["getSubscriptionPlan"] && (
                        <ErrorMessage text="Please select a filter" />
                      )}
                      <div className="input_holder">
                        <label htmlFor="">Select Subscription Plans</label>
                        <Controller
                          name="subscriptionPlans"
                          errors={errors}
                          control={control}
                          render={({ field }) => (
                            <Select
                              menuPortalTarget={document.body}
                              {...field}
                              isMulti
                              options={setSubscriptionPlanOptions()}
                              placeholder="Select Subscription Plan"
                              className="kmmrce-select-container"
                              classNamePrefix="kmmrce-select"
                              isDisabled={!getPlanBy && !userRole.is_admin}
                            />
                          )}
                        />
                        {errors && errors["subscriptionPlans"] && (
                          <ErrorMessage text="Subscription Plans are Required" />
                        )}
                      </div>
                    </Card>
                    <Card title="Upload Voucher Images">
                      <MultipleUpload
                        label="Voucher Images"
                        name="voucherImages"
                        register={register}
                        getImages={(images) =>
                          images &&
                          setVoucherImages(
                            (images[0] && images) || voucherDetails?.images
                          )
                        }
                        defaultValue={voucherDetails?.images}
                      />
                    </Card>
                    <Card title="Categories">
                      <div className="input_holder">
                        <Grid columns={2}>
                          {voucherCategories?.map(
                            ({ category_name, voucher_category_icon, id }) => {
                              return (
                                <>
                                  <Switch
                                    name="voucherCategory"
                                    value={id}
                                    icon={voucher_category_icon}
                                    register={register}
                                    label={category_name}
                                    key={id}
                                    asRadio
                                    defaultValue={
                                      voucherDetails?.category?.id === id
                                    }
                                  />
                                </>
                              );
                            }
                          )}
                          {errors && errors["voucherCategory"] && (
                            <ErrorMessage text="Voucher Category is Required" />
                          )}
                        </Grid>
                      </div>
                    </Card>
                  </div>
                )}
              </TabPanel>
              <TabPanel>
                <div className="cards_wrapper">
                  <Card>
                    <Switch
                      label="Exclude National Holidays"
                      register={register}
                      name="excludeHolidays"
                      defaultValue={voucherDetails?.exclude_holidays}
                    />
                  </Card>
                  <Card>
                    <Grid columns={2}>
                      <div>
                        <Controller
                          name="startDate"
                          control={control}
                          render={({ field }) => (
                            <DatePicker
                              {...field}
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              customInput={
                                <CustomDatePicker
                                  label={<label>Start at</label>}
                                />
                              }
                            />
                          )}
                        />
                        {errors && errors["startDate"] && (
                          <ErrorMessage text="Start Date is Required" />
                        )}
                      </div>
                      <div>
                        <Controller
                          name="endDate"
                          control={control}
                          render={({ field }) => (
                            <DatePicker
                              {...field}
                              selected={endDate}
                              onChange={(date) => setEndDate(date)}
                              customInput={
                                <CustomDatePicker
                                  label={<label>End at</label>}
                                />
                              }
                            />
                          )}
                        />
                        {errors && errors["endDate"] && (
                          <ErrorMessage text="End Date is Required" />
                        )}
                      </div>
                    </Grid>
                  </Card>
                  <Card>
                    {weekdays.map((weekday, index) => (
                      <InputRangeField
                        key={index}
                        step={1}
                        maxValue={24}
                        minValue={0}
                        label={weekday}
                        name={weekday?.toLowerCase()}
                        handleChange={(value) => handleChange(value, weekday)}
                        value={openingHours && openingHours[weekday]}
                      />
                    ))}
                  </Card>
                </div>
              </TabPanel>
            </Tabs>
          </div>
          <div>
            {voucher.voucherLoading ? (
              <MobileLoader />
            ) : (
              <Mobile
                voucherName={voucherName || voucherDetails?.voucher_set_name}
                voucherDesc={voucherDesc || voucherDetails?.description}
                voucherImages={
                  (voucherImages.length > 0 && voucherImages) ||
                  voucherDetails?.images
                }
                voucherCategory={voucherCategory}
              />
            )}
          </div>
        </Grid>
      </form>
    </MainContent>
  );
};

export default EditVoucher;
