import axios from "axios";
import toast from "react-hot-toast";
import API_URL from "../api/api.constants";

export const formatDate = (timestamp) => {
  let x = new Date(timestamp);
  let dd = x.getDate();
  let mm = x.getMonth() + 1;
  let yy = x.getFullYear();

  return mm + "-" + dd + "-" + yy;
};

export const formatTime = (timestamp) => {
  let x = new Date(timestamp);
  let hh = x.getHours() < 10 ? `0${x.getHours()}` : x.getHours();
  let mm = x.getMinutes() < 10 ? `0${x.getMinutes()}` : x.getMinutes();

  return hh + ":" + mm;
};

export const capitalize = (str) => str?.charAt(0).toUpperCase() + str?.slice(1);

export const getBase64 = (file) => {
  if (!file) return Promise.resolve(null);
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
    }
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const removeCharacters = (input) => {
  let forbiddenChars = ["/", "?", "&", "=", ".", '"', "{", "}"];

  for (let char of forbiddenChars) {
    input = input?.split(char).join("");
  }
  return input;
};

export const dismissToast = () => setTimeout(() => toast.dismiss(), [2000]);

export const somethignWentWrong = () =>
  toast.error("Something went wrong. Try again in a few minutes");

export const active = (text) => (
  <div className="item-status active">
    <span className="bullet"></span>
    <span className="text">{text}</span>
  </div>
);

export const inActive = (text) => (
  <div className="item-status in-active">
    <span className="bullet"></span>
    <span className="text">{text}</span>
  </div>
);

export const getCardType = (number) => {
  var re = new RegExp("^4");
  if (number.match(re) != null) {
    return "Visa";
  }

  if (
    /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
      number
    )
  ) {
    return "Mastercard";
  }
  return "";
};

const JWTInterceptor = () =>
  axios.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("access-token");
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

axios.interceptors.response.use(
  (config) => {
    return config;
  },
  (error) => {
    if (error?.response?.status === 401) {
      return handleUnauthorized(error);
    } else if (error?.response?.status === 400) {
      return handleInvalidRequest(error);
    }

    return error?.response;
  }
);

const handleUnauthorized = async (error) => {
  const logout = () => {
    localStorage.removeItem("access-token");
    localStorage.removeItem("refresh-token");
    if (window.location.pathname !== "/") {
      window.open(`/`, "_self");
    }

    return error?.response;
  };

  if (error.config._retry) {
    return logout();
  }

  const refreshToken = localStorage.getItem("refresh-token");
  if (!refreshToken) {
    return logout();
  } else {
    return await fetch(API_URL.refreshToken, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${refreshToken}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem("access-token", res.headers.get("access-token"));
          localStorage.setItem(
            "refresh-token",
            res.headers.get("refresh-token")
          );
          error.config._retry = true;
          axios.defaults.headers.common["Authorization"] =
            res.headers.get("access-token");
          return axios(error.config);
        } else {
          throw new Error("Refresh token failed");
        }
      })
      .catch((_) => {
        console.log(_);
        logout();
      });
  }
};

const handleInvalidRequest = (error) => {
  if (error?.response?.data?.errors) {
    const errors = error?.response?.data?.errors;

    for (const key in errors) {
      for (const error of errors[key]) {
        toast.error(error);
      }
    }
  } else if (
    error?.response?.data?.description ||
    error?.response?.data?.message
  ) {
    const message =
      error?.response?.data?.description ||
      error?.response?.data?.message ||
      error?.response?.data?.msg;
    if (
      message.includes("400") ||
      message.includes("401") ||
      message.includes("403") ||
      message.includes("404") ||
      message.includes("500")
    ) {
      // Handle on request level
      return;
    } else {
      toast.error(message);
    }
  }
  return error?.response;
};

JWTInterceptor();
