import React, { useCallback, useState } from "react";
import OtpInput from "react-otp-input";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import toast from "react-hot-toast";

import { MainContent, Card, Button, Buttons } from "@vms-admin/components";
import { redeemVoucher } from "../../redux/actions";
import { dismissToast, somethignWentWrong } from "../../helpers";

export const Otp = () => {
  const voucherId = localStorage.getItem("voucher_id");
  const [otp, setOtp] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();

  const handleChange = useCallback((otp) => setOtp(otp), []);

  const handleRedeemVoucher = useCallback(() => {
    dismissToast();
    dispatch(redeemVoucher(voucherId, otp))
      .then((res) => {
        if (res.status === 200) {
          setTimeout(() => history.push("/profile"), [2000]);
          toast.success("You have successfully redeemed this voucher");
          localStorage.removeItem("voucher_id");
        }
        res.status === 301 && toast.error("Voucher is expired");
      })
      .catch(() => somethignWentWrong());
  }, [dispatch, history, otp, voucherId]);

  return (
    <MainContent>
      <Card title="OTP">
        <p style={{ marginBottom: 16 }}>
          Please enter 6-digits sent to your phone
        </p>
        <OtpInput
          value={otp}
          onChange={handleChange}
          numInputs={6}
          separator={<span>-</span>}
          inputStyle={{
            width: "60px",
            height: "60px",
          }}
        />
        <Buttons>
          <Button variant="primary" handleClick={handleRedeemVoucher}>
            Redeem
          </Button>
        </Buttons>
      </Card>
    </MainContent>
  );
};

export default Otp;
