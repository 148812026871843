import styled from "styled-components";

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${(props) => props.theme.space[3]}px 40px;
  background-color: ${(props) => props.theme.colors.white};
  border-bottom: 1px solid ${(props) => props.theme.colors.gray};
  button span {
    @media screen and (max-width: 500px) {
      font-size: 14px;
    }
  }
  @media screen and (max-width: 1024px) {
    padding: ${(props) => props.theme.space[3]}px;
  }
  a {
    height: 25px;
  }
`;
