import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";

import {
  Card,
  Input,
  Button,
  ErrorMessage,
  Grid,
  Textarea,
  MainContent,
  Buttons,
} from "@vms-admin/components";
import { getClientOptions } from "@vms-admin/utils";
import {
  fetchMerchants,
  fetchClient,
  fetchClients,
  fetchBrand,
  fetchBrands,
} from "../../../redux/actions";
import { getBrandOptions, getMerchantOptions } from "../../../utils";
import { createVoucherSchema } from "../../../schema";

export const CreateVoucherStepOne = ({
  nextStep,
  voucherDetails,
  setVoucherDetails,
}) => {
  const dispatch = useDispatch();
  const clients = useSelector(({ clients }) => clients.clients);
  const client = useSelector(({ clients }) => clients.client);
  const brand = useSelector(({ brands }) => brands.brand);

  const {
    control,
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ mode: "onChange", resolver: yupResolver(createVoucherSchema) });

  const handleClientChange = useCallback(
    (selectedOption) => {
      dispatch(fetchClient(selectedOption.id));
      setValue("brandName", "");
      setValue("merchantName", "");
    },
    [dispatch, setValue]
  );

  const handleBrandChange = useCallback(
    (selectedOption) => {
      dispatch(fetchBrand(selectedOption.id));
      setValue("merchantName", "");
    },
    [dispatch, setValue]
  );

  const goToNextStep = useCallback(
    (data) => {
      setVoucherDetails(data);
      nextStep();
    },
    [nextStep, setVoucherDetails]
  );

  useEffect(() => {
    dispatch(fetchClients());
    dispatch(fetchBrands());
    dispatch(fetchMerchants());
  }, [dispatch]);

  return (
    <MainContent>
      <Card title="Create Voucher">
        <form
          noValidate
          style={{ width: "100%" }}
          onSubmit={handleSubmit(goToNextStep)}
        >
          <Input
            type="text"
            name="voucherName"
            register={register}
            placeholder="Voucher Name"
            label="Voucher Name"
            errors={errors}
            defaultValue={voucherDetails?.voucherName}
          />
          <Grid columns={3}>
            <div className="input_holder">
              <label style={{ marginBottom: "8px", display: "block" }}>
                Clients
              </label>
              <Controller
                name="clientName"
                control={control}
                defaultValue={
                  voucherDetails?.clientName
                    ? {
                        label: voucherDetails?.clientName?.label,
                        value: voucherDetails?.clientName?.value,
                      }
                    : null
                }
                render={({ field: { onChange, value } }) => (
                  <Select
                    menuPortalTarget={document.body}
                    options={getClientOptions(clients)}
                    placeholder="Select Client"
                    className={`kmmrce-select-container ${
                      errors && errors["clientName"] && `error`
                    }`}
                    classNamePrefix="kmmrce-select"
                    value={
                      voucherDetails?.clientName
                        ? {
                            label: voucherDetails?.clientName?.label,
                            value: voucherDetails?.clientName?.value,
                            id: voucherDetails?.clientName?.id,
                          }
                        : value
                    }
                    onChange={(e) => {
                      onChange(e);
                      handleClientChange(e);
                    }}
                  />
                )}
              />
              {errors && errors["clientName"] && (
                <ErrorMessage text="Client Name is Required" />
              )}
            </div>

            <div className="input_holder">
              <label style={{ marginBottom: "8px", display: "block" }}>
                Brands
              </label>
              <Controller
                name="brandName"
                control={control}
                defaultValue={
                  voucherDetails?.brandName
                    ? {
                        label: voucherDetails?.brandName?.label,
                        value: voucherDetails?.brandName?.value,
                        id: voucherDetails?.brandName?.id,
                      }
                    : null
                }
                render={({ field: { onChange, value } }) => (
                  <Select
                    menuPortalTarget={document.body}
                    options={getBrandOptions(client?.brands)}
                    placeholder="Select Brand"
                    className={`kmmrce-select-container ${
                      errors && errors["brandName"] && `error`
                    }`}
                    isDisabled={!watch("clientName")}
                    classNamePrefix="kmmrce-select"
                    value={
                      voucherDetails?.brandName
                        ? {
                            label: voucherDetails?.brandName?.label,
                            value: voucherDetails?.brandName?.value,
                            id: voucherDetails?.brandName?.id,
                          }
                        : value
                    }
                    onChange={(e) => {
                      onChange(e);
                      handleBrandChange(e);
                    }}
                  />
                )}
              />
              {errors && errors["brandName"] && (
                <ErrorMessage text="Brand Name is Required" />
              )}
            </div>
            <div className="input_holder">
              <label style={{ marginBottom: "8px", display: "block" }}>
                Merchants
              </label>
              <Controller
                name="merchantName"
                control={control}
                defaultValue={
                  voucherDetails?.merchantName
                    ? {
                        label: voucherDetails?.merchantName?.label,
                        value: voucherDetails?.merchantName?.value,
                        id: voucherDetails?.merchantName?.id,
                      }
                    : null
                }
                render={({ field: { value, onChange } }) => (
                  <Select
                    menuPortalTarget={document.body}
                    options={getMerchantOptions(brand?.merchants)}
                    placeholder="Select Merchant"
                    className={`kmmrce-select-container ${
                      errors && errors["merchantName"] && `error`
                    }`}
                    classNamePrefix="kmmrce-select"
                    isDisabled={!watch("brandName")}
                    value={
                      voucherDetails?.merchantName
                        ? {
                            label: voucherDetails?.merchantName?.label,
                            value: voucherDetails?.merchantName?.value,
                            id: voucherDetails?.merchantName?.id,
                          }
                        : value
                    }
                    onChange={(e) => onChange(e)}
                  />
                )}
              />
              {errors && errors["merchantName"] && (
                <ErrorMessage text="Merchant Name is Required" />
              )}
            </div>
          </Grid>
          <Textarea
            type="text"
            name="voucherDesc"
            register={register}
            placeholder="Voucher Description"
            label="Voucher Description"
            errors={errors}
            defaultValue={voucherDetails?.voucherDesc}
          />

          <Buttons>
            <Button type="submit" variant="primary">
              Next
            </Button>
          </Buttons>
        </form>
      </Card>
    </MainContent>
  );
};

export default CreateVoucherStepOne;
