import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { Link } from "react-router-dom";
import { useInView } from "react-intersection-observer";

import { createMerchantSchema } from "../../../schema";
import {
  Input,
  Button,
  Map,
  Textarea,
  UploadField,
  ErrorMessage,
  Card,
  Grid,
  MainContent,
  Buttons,
} from "@vms-admin/components";
import {
  fetchCountries,
  fetchCountryInfo,
  fetchMerchantCategories,
  updateFormData,
  fetchPlans,
  fetchBrands,
} from "../../../redux/actions";
import { getCountryOptions, getCityOptions } from "@vms-admin/utils";
import { getBrandOptions, getCategoryOptions } from "../../../utils";

export const CreateMerchantStepOne = ({ nextStep }) => {
  const [address, setAddress] = useState("");
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);
  const dispatch = useDispatch();
  const merchant = useSelector(({ merchant }) => merchant);
  const brands = useSelector(({ brands }) => brands.brands);
  const countryManagementState = useSelector(
    ({ countryManagement }) => countryManagement
  );
  const { ref, inView } = useInView();

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(createMerchantSchema),
  });

  const handleChange = useCallback(
    (selectedOption) => {
      dispatch(fetchCountryInfo(selectedOption.id));
      setValue("cityName", "");
    },
    [dispatch, setValue]
  );

  const saveData = useCallback(
    (data) => {
      const formData = { ...data, lat, lng, address };
      dispatch(updateFormData(formData));
      nextStep();
    },
    [dispatch, nextStep, address, lat, lng]
  );

  useEffect(() => {
    dispatch(fetchCountries());
    dispatch(fetchPlans());
    dispatch(fetchBrands());
    dispatch(fetchMerchantCategories());
  }, [dispatch]);

  return (
    <MainContent>
      <form onSubmit={handleSubmit(saveData)} noValidate>
        <div className="container_mobile">
          <div className="page-header" ref={ref}>
            <h1>Merchant Management</h1>
            <div className={inView ? `` : `sticky-action-bar`}>
              <Buttons noMargin>
                <Link to="/merchant-management" className="btn btn-secondary">
                  Back
                </Link>
                <Button type="submit" variant="primary">
                  Next
                </Button>
              </Buttons>
            </div>
          </div>
        </div>
        <div className="cards_wrapper">
          <Card title="Create Merchant">
            <Grid columns={2}>
              <Input
                label="Merchant Name"
                type="text"
                placeholder="Merchant Name"
                name="name"
                register={register}
                errors={errors}
                defaultValue={merchant?.formData?.name}
              />
              <Input
                label="Email Address"
                type="email"
                placeholder="Email Address"
                name="email"
                register={register}
                errors={errors}
                defaultValue={merchant?.formData?.email}
              />
            </Grid>
            <Grid columns={3}>
              <div className="input_holder">
                <label>Merchant Category</label>
                <Controller
                  name="category"
                  errors={errors}
                  control={control}
                  defaultValue={
                    merchant?.formData?.category
                      ? {
                          label: merchant?.formData?.category?.label,
                          value: merchant?.formData?.category?.value,
                        }
                      : null
                  }
                  render={({ field: { value, onChange } }) => (
                    <Select
                      menuPortalTarget={document.body}
                      value={
                        merchant?.formData?.category
                          ? {
                              label: merchant?.formData?.category?.label,
                              value: merchant?.formData?.category?.value,
                            }
                          : value
                      }
                      options={getCategoryOptions(merchant?.categories)}
                      placeholder="Select Merchant Category"
                      className="kmmrce-select-container"
                      classNamePrefix="kmmrce-select"
                      onChange={(e) => onChange(e)}
                    />
                  )}
                />
                {errors && errors["category"] && (
                  <ErrorMessage text="Category is Required" />
                )}
              </div>
              <div className="input_holder">
                <label>Brand Name</label>
                <Controller
                  name="brandName"
                  errors={errors}
                  control={control}
                  defaultValue={
                    merchant?.formData?.brandName
                      ? {
                          label: merchant?.formData?.brandName?.label,
                          value: merchant?.formData?.brandName?.value,
                        }
                      : null
                  }
                  render={({ field: { onChange, value } }) => (
                    <Select
                      menuPortalTarget={document.body}
                      value={
                        merchant?.formData?.brandName
                          ? {
                              label: merchant?.formData?.brandName?.label,
                              value: merchant?.formData?.brandName?.value,
                            }
                          : value
                      }
                      placeholder="Select Brand Name"
                      options={getBrandOptions(brands)}
                      className="kmmrce-select-container"
                      classNamePrefix="kmmrce-select"
                      onChange={(e) => onChange(e)}
                    />
                  )}
                />
                {errors && errors["brandName"] && (
                  <ErrorMessage text="Brand Name is Required" />
                )}
              </div>
              <Input
                label="Merchant Mobile Number"
                type="tel"
                placeholder="Merchant Mobile Number"
                name="mobileNumber"
                register={register}
                errors={errors}
                defaultValue={merchant?.formData?.mobileNumber}
              />
            </Grid>

            <Textarea
              label="Description"
              type="text"
              placeholder="Description"
              name="desc"
              register={register}
              errors={errors}
              defaultValue={merchant?.formData?.desc}
            />
            <UploadField
              label="Merchant Logo"
              name="logo"
              errors={errors}
              register={register}
            />
          </Card>
          <Card title="Location">
            <Grid columns={3}>
              <div className="input_holder">
                <label>Country Name</label>
                <Controller
                  name="countryName"
                  errors={errors}
                  control={control}
                  defaultValue={
                    merchant?.formData?.countryName
                      ? {
                          label: merchant?.formData?.countryName?.label,
                          value: merchant?.formData?.countryName?.value,
                        }
                      : null
                  }
                  render={({ field: { onChange, value } }) => (
                    <Select
                      menuPortalTarget={document.body}
                      options={getCountryOptions(
                        countryManagementState.countries
                      )}
                      placeholder="Select Country"
                      className="kmmrce-select-container"
                      classNamePrefix="kmmrce-select"
                      onChange={(e) => {
                        onChange(e);
                        handleChange(e);
                      }}
                      value={
                        merchant?.formData?.countryName
                          ? {
                              label: merchant?.formData?.countryName?.label,
                              value: merchant?.formData?.countryName?.value,
                            }
                          : value
                      }
                    />
                  )}
                />
                {errors && errors["countryName"] && (
                  <ErrorMessage text="Country is Required" />
                )}
              </div>
              <div className="input_holder">
                <label>City Name</label>
                <Controller
                  name="cityName"
                  control={control}
                  defaultValue={
                    merchant?.formData?.cityName
                      ? {
                          label: merchant?.formData?.cityName?.label,
                          value: merchant?.formData?.cityName?.value,
                        }
                      : null
                  }
                  render={({ field: { onChange, value } }) => (
                    <Select
                      menuPortalTarget={document.body}
                      options={getCityOptions(
                        countryManagementState?.countryInfo?.cities
                      )}
                      value={
                        merchant?.formData?.cityName
                          ? {
                              label: merchant?.formData?.cityName?.label,
                              value: merchant?.formData?.cityName?.value,
                            }
                          : value
                      }
                      isLoading={countryManagementState?.countryInfoLoading}
                      loadingMessage={() => "Loading..."}
                      onChange={(e) => onChange(e)}
                      placeholder="Select City"
                      className="kmmrce-select-container"
                      classNamePrefix="kmmrce-select"
                    />
                  )}
                />
                {errors && errors["cityName"] && (
                  <ErrorMessage text="City is Required" />
                )}
              </div>
              <Input
                label="Merchant P.O. Box"
                type="text"
                placeholder="Merchant P.O. Box"
                name="poBox"
                register={register}
                errors={errors}
                defaultValue={merchant?.formData?.poBox}
              />
            </Grid>

            <Map
              addressInfo={(address, lat, lng) => {
                setAddress(address);
                setLat(lat);
                setLng(lng);
              }}
            />
          </Card>
        </div>
      </form>
    </MainContent>
  );
};

export default CreateMerchantStepOne;
