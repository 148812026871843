import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { INControl, LogoFull, Profile } from "@vms-admin/icons";
import { Button } from "@vms-admin/components";
import * as Styled from "./header.style";

export const Header = () => {
  const [instance, setInstance] = useState("");

  useEffect(() => {
    if (window.location.href.includes("incontrol")) {
      setInstance("in-control");
    }
    if (window.location.href.includes("rokit")) {
      setInstance("rokit");
    }
    if (window.location.href.includes("afritech")) {
      setInstance("starlink");
    }
  }, []);

  return (
    <Styled.Header className="header">
      <Link to="/">
        {instance === "starlink" && (
          <img
            src="/images/starlink-logo-white.png"
            width="180"
            alt="starlink logo"
          />
        )}
        {instance === "in-control" && <INControl />}
        {instance === "rokit" && (
          <img src="/images/rokit-logo.png" width="120" alt="rokit logo" />
        )}
        {instance !== "rokit" &&
          instance !== "in-control" &&
          instance !== "starlink" && <LogoFull />}
      </Link>
      <Button variant="secondary" className="with_icon" icon={<Profile />}>
        Contact Support
      </Button>
    </Styled.Header>
  );
};

export default Header;
