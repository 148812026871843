import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { fetchPlan } from "../../redux/actions";
import { MainContent, TableCard } from "@vms-admin/components";
import { formatDate, formatTime } from "../../helpers";
import PlanDetailsLoader from "./plan-details-loader";

export const PlanDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const plan = useSelector(({ plans }) => plans.plan);
  const planLoading = useSelector(({ plans }) => plans.planLoading);

  useEffect(() => dispatch(fetchPlan(id)), [dispatch, id]);

  return (
    <MainContent>
      {planLoading ? (
        <PlanDetailsLoader />
      ) : (
        <TableCard title={`${plan?.plan_name} Details`}>
          <tbody>
            <tr>
              <td>
                <strong>Plan UID</strong>
              </td>
              <td>{plan?.plan_id}</td>
            </tr>
            <tr>
              <td>
                <strong>Plan Name</strong>
              </td>
              <td>{plan?.plan_name}</td>
            </tr>
            <tr>
              <td>
                <strong>Plan Description</strong>
              </td>
              <td>{plan?.plan_description}</td>
            </tr>
            <tr>
              <td>
                <strong>Plan Features</strong>
              </td>
              <td>{plan?.plan_features}</td>
            </tr>
            <tr>
              <td>
                <strong>Plan Category</strong>
              </td>
              <td>{plan?.plan_category?.category_name}</td>
            </tr>
            <tr>
              <td>
                <strong>Plan Price per Day</strong>
              </td>
              <td>
                {plan?.plan_price_per_day} {plan?.currency_code}
              </td>
            </tr>
            <tr>
              <td>
                <strong>Plan Price per Month</strong>
              </td>
              <td>
                {plan?.plan_price_per_month} {plan?.currency_code}
              </td>
            </tr>
            <tr>
              <td>
                <strong>Plan Price per Year</strong>
              </td>
              <td>
                {plan?.plan_price_per_year} {plan?.currency_code}
              </td>
            </tr>
            <tr>
              <td>
                <strong>Plan Currency</strong>
              </td>
              <td>{plan?.currency_code}</td>
            </tr>
            <tr>
              <td>
                <strong>Plan Start Date</strong>
              </td>
              <td>
                {formatDate(plan?.start_date)} {formatTime(plan?.start_date)}
              </td>
            </tr>
            <tr>
              <td>
                <strong>Plan End Date</strong>
              </td>
              <td>
                {formatDate(plan?.end_date)} {formatTime(plan?.end_date)}
              </td>
            </tr>
            <tr>
              <td>
                <strong>Confirmed</strong>
              </td>
              <td>
                {plan?.is_active ? (
                  <span className="label label-create">Active</span>
                ) : (
                  <span className="label label-update">InActive</span>
                )}
              </td>
            </tr>

            <tr>
              <td>
                <strong>Plan Max Members</strong>
              </td>
              <td>{plan?.max_members}</td>
            </tr>
          </tbody>
        </TableCard>
      )}
    </MainContent>
  );
};

export default PlanDetails;
