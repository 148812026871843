import {
  FETCH_MERCHANT_AMENITIES_SUCCESS,
  FETCH_MERCHANT_ATTRIBUTES_SUCCESS,
  FETCH_MERCHANT_CATEGORIES_SUCCESS,
  SET_MERCHANT_FORM_DATA,
  SET_MERCHANT_ATTRIBUTES,
  SET_MERCHANT_AMENITIES,
  FETCH_MERCHANTS_SUCCESS,
  FETCH_MERCHANT_SUCCESS,
  FETCH_MERCHANT_USERS_SUCCESS,
  FETCH_MERCHANT_LOADING,
  FETCH_MERCHANTS_LOADING,
  FETCH_BRAND_MERCHANTS_SUCCESS,
  FETCH_BRAND_USER_MERCHANTS_SUCCESS,
  FETCH_CLIENT_MERCHANTS_SUCCESS,
  FETCH_CLIENT_USER_MERCHANTS_SUCCESS,
  FETCH_MERCHANT_AMENITY_SUCCESS,
  FETCH_MERCHANT_ATTRIBUTE_SUCCESS,
} from "../actions/actions.constants";

const initialState = {
  merchants: [],
  merchantsLoading: true,
  merchant: {},
  merchantLoading: false,
  categories: [],
  brandMerchants: [],
  users: [],
  amenities: [],
  attributes: [],
  formData: {},
  brandUserMerchants: [],
  clientMerchants: [],
  clientUserMerchants: [],
};

const merchantReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MERCHANTS_SUCCESS:
      return { ...state, merchants: action.data, merchantsLoading: false };
    case FETCH_MERCHANTS_LOADING:
      return { ...state, merchantsLoading: true };
    case FETCH_MERCHANT_SUCCESS:
      return { ...state, merchant: action.data, merchantLoading: false };
    case FETCH_MERCHANT_LOADING:
      return { ...state, merchantLoading: true };
    case FETCH_MERCHANT_CATEGORIES_SUCCESS:
      return { ...state, categories: action.data.data };
    case FETCH_BRAND_MERCHANTS_SUCCESS:
      return { ...state, brandMerchants: action.data, merchantsLoading: false };
    case FETCH_MERCHANT_USERS_SUCCESS:
      return { ...state, users: action.data };

    case FETCH_MERCHANT_AMENITIES_SUCCESS:
      return { ...state, amenities: action.data.data };
    case FETCH_MERCHANT_AMENITY_SUCCESS:
      return { ...state, selectedAmenity: action.data.data };
    case FETCH_MERCHANT_ATTRIBUTES_SUCCESS:
      return { ...state, attributes: action.data.data };
    case FETCH_MERCHANT_ATTRIBUTE_SUCCESS:
      return { ...state, selectedAttribute: action.data.data };
    case SET_MERCHANT_FORM_DATA:
      return { ...state, formData: action.data };
    case SET_MERCHANT_AMENITIES:
      return {
        ...state,
        formData: { ...state.formData, amenities: action.data },
      };
    case SET_MERCHANT_ATTRIBUTES:
      return {
        ...state,
        formData: { ...state.formData, attributes: action.data },
      };
    case FETCH_BRAND_USER_MERCHANTS_SUCCESS:
      return {
        ...state,
        brandUserMerchants: action.data,
        merchantsLoading: false,
      };
    case FETCH_CLIENT_MERCHANTS_SUCCESS:
      return {
        ...state,
        clientMerchants: action.data,
        merchantsLoading: false,
      };
    case FETCH_CLIENT_USER_MERCHANTS_SUCCESS:
      return {
        ...state,
        clientUserMerchants: action.data,
        merchantsLoading: false,
      };
    default:
      return state;
  }
};

export default merchantReducer;
