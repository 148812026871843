import axios from "axios";
import toast from "react-hot-toast";
import API_URL from "../../api/api.constants";
import { getBase64 } from "../../helpers";
import {
  FETCH_CLIENTS_SUCCESS,
  FETCH_CLIENT_SUCCESS,
  FETCH_CLIENT_CATEGORIES_SUCCESS,
  FETCH_CLIENT_USERS_SUCCESS,
  CREATE_CLIENT_SUCCESS,
  EDIT_CLIENT_SUCCESS,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_CATEGORY_SUCCESS,
  CREATE_CLIENT_CATEGORY_SUCCESS,
  EDIT_CLIENT_CATEGORY_SUCCESS,
  FETCH_CLIENTS_LOADING,
  FETCH_CLIENT_LOADING,
} from "./actions.constants";

export const fetchClients = () => (dispatch) => {
  dispatch({ type: FETCH_CLIENTS_LOADING });
  return axios
    .get(API_URL.clients)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: FETCH_CLIENTS_SUCCESS, clients: res.data });
      }
      return Promise.resolve(res);
    })
    .catch((error) => toast.error(`${error.code}: ${error.message}`));
};

export const fetchClient = (id) => (dispatch) => {
  dispatch({ type: FETCH_CLIENT_LOADING });
  return axios
    .get(`${API_URL.clients}/${id}`)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: FETCH_CLIENT_SUCCESS, client: res.data });
      }
      return Promise.resolve(res);
    })
    .catch((error) => toast.error(`${error.code}: ${error.message}`));
};

export const createClient =
  (formData, address, lat, lng) => async (dispatch) => {
    const accessToken = localStorage.getItem("access-token");
    const image = await getBase64(formData?.logo[0]);
    return axios(API_URL.clients, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        client_name: formData.name,
        email: formData.email,
        country: formData.countryName.value,
        city: formData.cityName.value,
        telephone: formData.mobileNumber,
        category_id: formData.category.id,
        po_box: formData.poBox,
        address,
        lat,
        long: lng,
        image,
      },
    })
      .then((res) => {
        dispatch({ type: CREATE_CLIENT_SUCCESS });
        return Promise.resolve(res);
      })
      .catch((error) => Promise.reject(error));
  };

export const editClient =
  (formData, clientData, clientId, address, lat, lng) => async (dispatch) => {
    const accessToken = localStorage.getItem("access-token");
    const image = formData.logo[0]
      ? await getBase64(formData.logo[0])
      : clientData?.client?.image;
    return axios(`${API_URL.clients}/${clientId}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        client_name: formData.name,
        email: formData.email,
        country: formData.countryName.value,
        city: formData.cityName.value,
        telephone: formData.mobileNumber,
        po_box: formData.poBox,
        category_id: formData.category.id,
        address: address ? address : clientData?.client?.address,
        lat: lat ? lat : clientData?.client?.lat,
        long: lng ? lng : clientData?.client?.long,
        image: image,
      },
    })
      .then((res) => {
        dispatch({ type: EDIT_CLIENT_SUCCESS });
        return Promise.resolve(res);
      })
      .catch((error) => Promise.reject(error));
  };

export const deleteClient = (clientId) => (dispatch) => {
  const accessToken = localStorage.getItem("access-token");
  let config = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios(`${API_URL.clients}/${clientId}`, config)
    .then((res) => {
      if (res.status === 204) {
        dispatch({ type: DELETE_CLIENT_SUCCESS });
        return Promise.resolve(res);
      }
    })
    .catch((error) => Promise.reject(error));
};

// Client Category
export const fetchClientCategories = () => (dispatch) => {
  const accessToken = localStorage.getItem("access-token");
  let config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios
    .get(`${API_URL.clientCategories}`, config)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: FETCH_CLIENT_CATEGORIES_SUCCESS, data: res.data });
      }
    })
    .catch((error) => toast.error(`${error.code}: ${error.message}`));
};

export const createClientCategory = (formData) => async (dispatch) => {
  const accessToken = localStorage.getItem("access-token");
  return axios(API_URL.clientCategories, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    data: {
      category_name: formData?.categoryName,
    },
  })
    .then((res) => {
      dispatch({ type: CREATE_CLIENT_CATEGORY_SUCCESS });
      return Promise.resolve(res);
    })
    .catch((error) => Promise.reject(error));
};

export const editClientCategory = (categoryId, categoryName) => (dispatch) => {
  const accessToken = localStorage.getItem("access-token");
  return axios(`${API_URL.clientCategories}/${categoryId}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    data: {
      category_name: categoryName,
    },
  })
    .then((res) => {
      dispatch({ type: EDIT_CLIENT_CATEGORY_SUCCESS });
      return Promise.resolve(res);
    })
    .catch((error) => Promise.reject(error));
};

export const deleteClientCategory = (clientId) => (dispatch) => {
  const accessToken = localStorage.getItem("access-token");
  let config = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios(`${API_URL.clientCategories}/${clientId}`, config)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: DELETE_CLIENT_CATEGORY_SUCCESS });
        return Promise.resolve(res);
      }
    })
    .catch((error) => Promise.reject(error));
};

// Client Users
export const fetchClientUsers = (userId) => (dispatch) => {
  const accessToken = localStorage.getItem("access-token");
  let config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios
    .get(`${API_URL.clients}/${userId}/users`, config)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: FETCH_CLIENT_USERS_SUCCESS, data: res.data.data });
      }
    })
    .catch((error) => toast.error(`${error.code}: ${error.message}`));
};
