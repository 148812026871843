const REACT_APP_API_URL = "https://api.vms.felixcorp.co/";

const API_URL = {
  refreshToken: `${REACT_APP_API_URL}/api/v1/auth/refresh`,
  login: `${REACT_APP_API_URL}/api/v1/auth/login`,
  logout: `${REACT_APP_API_URL}/api/v1/auth/logout`,
  userInfo: `${REACT_APP_API_URL}/api/v1/users/info`,
  dashboard: `${REACT_APP_API_URL}/api/v1/dashboard`,
  users: `${REACT_APP_API_URL}/api/v1/users`,
  brands: `${REACT_APP_API_URL}/api/v1/brands`,
  clients: `${REACT_APP_API_URL}/api/v1/clients`,
  merchants: `${REACT_APP_API_URL}/api/v1/merchants`,
  countries: `https://countriesnow.space/api/v0.1/countries`,
  cities: `${REACT_APP_API_URL}/api/v1/admin/cities`,
  customers: `${REACT_APP_API_URL}/api/v1/customers`,
  register: `${REACT_APP_API_URL}/api/v1/auth/signup`,
  currencies: `${REACT_APP_API_URL}/api/v1/admin/currencies`,
  brandCategories: `${REACT_APP_API_URL}/api/v1/admin/brand_categories`,
  clientCategories: `${REACT_APP_API_URL}/api/v1/admin/client_categories`,
  merchantCategories: `${REACT_APP_API_URL}/api/v1/admin/merchant_categories`,
  merchantAmenities: `${REACT_APP_API_URL}/api/v1/admin/merchant-amenities`,
  merchantAttributes: `${REACT_APP_API_URL}/api/v1/admin/merchant-attributes`,
  voucherCategories: `${REACT_APP_API_URL}/api/v1/admin/voucher_categories`,
  plans: `${REACT_APP_API_URL}/api/v1/plans`,
  voucherScopes: `${REACT_APP_API_URL}/api/v1/admin/voucher_scopes`,
  vouchers: `${REACT_APP_API_URL}/api/v1/vouchers`,
  confirmEmail: `${REACT_APP_API_URL}/api/v1/auth/confirm`,
  resendEmail: `${REACT_APP_API_URL}/api/v1/auth/resend`,
  changePassword: `${REACT_APP_API_URL}/api/v1/auth/change-password`,
  usersOverview: `${REACT_APP_API_URL}/api/v1/users/overview`,
  subscriptions: `${REACT_APP_API_URL}/api/v1/subscriptions`,
  activityLogs: `${REACT_APP_API_URL}/api/v1/admin/audit_logs`,
};

export default API_URL;
