import React, { useState, useCallback } from "react";
import { useSelector } from "react-redux";

import CreateVoucherStepOne from "./step-one";
import CreateVoucherStepTwo from "./step-two";

export const CreateVoucherForm = () => {
  const [formStep, setFormStep] = useState(1);
  const userRole = useSelector(({ auth }) => auth.role);

  const [voucherDetails, setVoucherDetails] = useState({});

  const goToNext = useCallback(() => setFormStep(formStep + 1), [formStep]);
  const goToPrev = useCallback(() => setFormStep(formStep - 1), [formStep]);

  return (
    <>
      {formStep === 1 && userRole.is_admin && (
        <CreateVoucherStepOne
          voucherDetails={voucherDetails}
          setVoucherDetails={setVoucherDetails}
          nextStep={goToNext}
        />
      )}
      {formStep === 2 && userRole.is_admin && (
        <CreateVoucherStepTwo
          voucherDetails={voucherDetails}
          prevStep={goToPrev}
        />
      )}
      {userRole.is_brand && (
        <CreateVoucherStepTwo voucherDetails={voucherDetails} />
      )}
      {userRole.is_brand_user && (
        <CreateVoucherStepTwo voucherDetails={voucherDetails} />
      )}
      {userRole.is_merchant && (
        <CreateVoucherStepTwo voucherDetails={voucherDetails} />
      )}
      {userRole.is_merchant_user && (
        <CreateVoucherStepTwo voucherDetails={voucherDetails} />
      )}
    </>
  );
};

export default CreateVoucherForm;
