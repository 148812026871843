import styled from "styled-components";

export const Note = styled.div`
  width: 218px;
  background-color: ${(props) => props.theme.colors.lightGray};
  color: #8e8e8e;
  padding: ${(props) => props.theme.space[3]}px;
  border-radius: 12px;
  height: auto;
`;
