import React from "react";
import { createPortal } from "react-dom";

import * as Styled from "./modal.style";
import { AnimatePresence } from "framer-motion";

export const Modal = ({ title, children }) => {
  return createPortal(
    <AnimatePresence>
      <Styled.Backdrop
        initial={{ opacity: 0.3 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Styled.Modal
          initial={{ opacity: 0.3, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0 }}
        >
          {title && <Styled.Title>{title}</Styled.Title>}
          <Styled.Body>{children}</Styled.Body>
        </Styled.Modal>
      </Styled.Backdrop>
    </AnimatePresence>,
    document.getElementById("modal")
  );
};

export default Modal;
