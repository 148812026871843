import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";
import { Link, useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import { useInView } from "react-intersection-observer";

import { createPlanSchema } from "../../schema";
import {
  Input,
  Button,
  Grid,
  Textarea,
  UploadField,
  ErrorMessage,
  Card,
  MainContent,
  Buttons,
  Datepicker,
} from "@vms-admin/components";
import {
  createPlan,
  fetchCountries,
  fetchCountryInfo,
  fetchCurrencies,
} from "../../redux/actions";
import {
  getCountryOptions,
  getCityOptions,
  getCurrencyOptions,
} from "../../utils";
import { dismissToast, somethignWentWrong } from "../../helpers";

export const CreatePlan = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const countryManagementState = useSelector(
    ({ countryManagement }) => countryManagement
  );
  const { ref, inView } = useInView();
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(createPlanSchema),
  });

  const handleChange = useCallback(
    (selectedOption) => {
      dispatch(fetchCountryInfo(selectedOption.id));
      setValue("cityName", "");
      setValue("currencyName", "");
    },
    [setValue, dispatch]
  );

  const handleCreatePlan = useCallback(
    (data) => {
      dismissToast();
      dispatch(createPlan(data))
        .then((res) => {
          if (res.status === 200) {
            setTimeout(() => history.push("/subscription-plans"), [2000]);
            toast.success(`${data.planName} has been created successfully`);
          } else if (res.status === 409) {
            toast.error("User already exist");
          } else if (res.status === 401) {
            history.push("/");
          } else {
            somethignWentWrong();
          }
        })
        .catch(() => somethignWentWrong());
    },
    [dispatch, history]
  );

  useEffect(() => {
    dispatch(fetchCountries());
  }, [dispatch]);

  return (
    <MainContent>
      <form onSubmit={handleSubmit(handleCreatePlan)} noValidate>
        <div className="container_mobile">
          <div className="page-header" ref={ref}>
            <h1>Subscription Management</h1>
            <div className={inView ? `` : `sticky-action-bar`}>
              <Buttons noMargin>
                <Link to="/subscription-plans" className="btn btn-secondary">
                  Back
                </Link>
                <Button type="submit" variant="primary">
                  Create
                </Button>
              </Buttons>
            </div>
          </div>
        </div>
        <Card title="Create Subscription Plan">
          <Input
            label="Plan Name"
            type="text"
            placeholder="Plan Name"
            name="planName"
            register={register}
            errors={errors}
          />
          <Textarea
            label="Plan Description"
            type="text"
            placeholder="Plan Description"
            name="planDesc"
            register={register}
            errors={errors}
          />
          <Input
            label="Plan Features"
            type="text"
            placeholder="Plan Features"
            name="planFeatures"
            register={register}
            errors={errors}
          />
          <UploadField
            label="Plan Image"
            name="image"
            errors={errors}
            register={register}
          />
          <Grid columns={4}>
            <Input
              type="number"
              name="maxMembers"
              placeholder="20"
              register={register}
              errors={errors}
              label="Max members"
            />
            <Input
              type="number"
              name="perDay"
              placeholder="3.99"
              register={register}
              errors={errors}
              label="Plan price per day"
            />
            <Input
              type="number"
              name="perMonth"
              placeholder="23.99"
              register={register}
              errors={errors}
              label="Plan price per month"
            />
            <Input
              type="number"
              name="perYear"
              placeholder="130.99"
              register={register}
              errors={errors}
              label="Plan price per year"
            />
          </Grid>
          <Grid columns={3}>
            <div className="input_holder">
              <label>Country Name</label>
              <Controller
                name="countryName"
                errors={errors}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    menuPortalTarget={document.body}
                    options={getCountryOptions(
                      countryManagementState.countries
                    )}
                    placeholder="Select Country"
                    className="kmmrce-select-container"
                    classNamePrefix="kmmrce-select"
                    onChange={(e) => {
                      onChange(e);
                      handleChange(e);
                    }}
                    value={value ? value : ""}
                  />
                )}
              />
              {errors && errors["countryName"] && (
                <ErrorMessage text="Country is Required" />
              )}
            </div>
            <div className="input_holder">
              <label>City Name</label>
              <Controller
                name="cityName"
                control={control}
                render={({ field }) => (
                  <Select
                    menuPortalTarget={document.body}
                    {...field}
                    options={getCityOptions(
                      countryManagementState?.countryInfo?.cities
                    )}
                    isLoading={countryManagementState?.countryInfoLoading}
                    loadingMessage={() => "Loading..."}
                    placeholder="Select City"
                    className="kmmrce-select-container"
                    classNamePrefix="kmmrce-select"
                  />
                )}
              />
              {errors && errors["cityName"] && (
                <ErrorMessage text="City is Required" />
              )}
            </div>
            <div className="input_holder">
              <label>Currency</label>
              <Controller
                name="currencyName"
                control={control}
                render={({ field }) => (
                  <Select
                    menuPortalTarget={document.body}
                    {...field}
                    options={getCurrencyOptions(
                      countryManagementState?.countryInfo?.currencies
                    )}
                    placeholder="Select Currency"
                    className="kmmrce-select-container"
                    classNamePrefix="kmmrce-select"
                  />
                )}
              />
              {errors && errors["currencyName"] && (
                <ErrorMessage text="Currency is Required" />
              )}
            </div>
          </Grid>
          <Grid columns={2}>
            <Datepicker
              label="Start at"
              name="startDate"
              errors={errors}
              control={control}
              errorMessage="Start Date is Required"
            />
            <Datepicker
              label="End at"
              name="endDate"
              errors={errors}
              control={control}
              errorMessage="End Date is Required"
            />
          </Grid>
        </Card>
      </form>
    </MainContent>
  );
};

export default CreatePlan;
