import React from "react";

export const ShowPassword = () => (
  <svg
    className="state1"
    id="Group_20383"
    data-name="Group 20383"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <circle
      id="Ellipse_464"
      data-name="Ellipse 464"
      cx="16"
      cy="16"
      r="16"
      fill="#fff"
    ></circle>
    <g id="Group_20382" data-name="Group 20382" transform="translate(7.295 10)">
      <path
        id="Path_25565"
        data-name="Path 25565"
        d="M48.082,44.825C47.9,44.6,44.047,39.1,39.15,39.1s-8.751,5.5-8.932,5.725a.591.591,0,0,0,0,.55c.181.22,4.035,5.725,8.932,5.725s8.751-5.5,8.932-5.725A.591.591,0,0,0,48.082,44.825Zm-8.932,5.45c-3.99,0-7.345-4.073-8.207-5.174.816-1.1,4.217-5.174,8.207-5.174S46.5,44,47.357,45.1C46.5,46.2,43.14,50.274,39.15,50.274Z"
        transform="translate(-30.15 -39.1)"
        fill="#8a8a8f"
      ></path>
      <path
        id="Path_25566"
        data-name="Path 25566"
        d="M45.75,42.5A3.25,3.25,0,1,0,49,45.75,3.274,3.274,0,0,0,45.75,42.5Zm0,5.85a2.6,2.6,0,1,1,2.6-2.6A2.608,2.608,0,0,1,45.75,48.35Z"
        transform="translate(-36.75 -39.75)"
        fill="#8a8a8f"
        stroke="#8a8a8f"
        strokeWidth="0.3"
      ></path>
    </g>
  </svg>
);
