import {
  FETCH_CLIENTS_SUCCESS,
  FETCH_CLIENT_SUCCESS,
  FETCH_CLIENT_CATEGORIES_SUCCESS,
  FETCH_CLIENT_USERS_SUCCESS,
  FETCH_CLIENTS_LOADING,
  FETCH_CLIENT_LOADING,
} from "../actions/actions.constants";

const initialState = {
  clients: [],
  client: {},
  categories: [],
  users: [],
  loadingClients: false,
  loadingClient: false,
};

const clientsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CLIENTS_SUCCESS:
      return { ...state, clients: action.clients.data, loadingClients: false };
    case FETCH_CLIENTS_LOADING:
      return { ...state, loadingClients: true };
    case FETCH_CLIENT_SUCCESS:
      return { ...state, client: action.client.data, loadingClient: false };
    case FETCH_CLIENT_LOADING:
      return { ...state, loadingClient: true };
    case FETCH_CLIENT_CATEGORIES_SUCCESS:
      return { ...state, categories: action.data.data };
    case FETCH_CLIENT_USERS_SUCCESS:
      return { ...state, users: action.data };
    default:
      return state;
  }
};

export default clientsReducer;
