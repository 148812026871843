import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { fetchClient } from "../../redux/actions";
import {
  MainContent,
  TableCard,
  Map,
  UsersTable,
  BrandsTable,
} from "@vms-admin/components";
import ClientDetailsLoader from "./client-details-loader";

export const ClientDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const client = useSelector(({ clients }) => clients.client);
  const loadingClient = useSelector(({ clients }) => clients.loadingClient);

  useEffect(
    () =>
      dispatch(fetchClient(id)).then((res) => {
        if (res.status === 401) {
          localStorage.removeItem("access-token");
          history.push("/");
        }
      }),
    [dispatch, id, history]
  );

  return (
    <MainContent>
      <div className="cards_wrapper">
        {loadingClient ? (
          <ClientDetailsLoader />
        ) : (
          <TableCard title={`${client?.client_name} Details`}>
            <tbody>
              <tr>
                <td>
                  <strong>Client UID</strong>
                </td>
                <td>{client?.client_uid}</td>
              </tr>
              <tr>
                <td>
                  <strong>Client Name</strong>
                </td>
                <td>{client?.client_name}</td>
              </tr>
              <tr>
                <td>
                  <strong>Client Email</strong>
                </td>
                <td>{client?.email}</td>
              </tr>
              <tr>
                <td>
                  <strong>Client Category</strong>
                </td>
                <td>{client?.client_category?.category_name}</td>
              </tr>
              <tr>
                <td>
                  <strong>Confirmed</strong>
                </td>
                <td>
                  {client?.confirmed ? (
                    <span className="label label-create">True</span>
                  ) : (
                    <span className="label label-delete">False</span>
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Client P.O Box</strong>
                </td>
                <td>{client?.po_box}</td>
              </tr>
              <tr>
                <td>
                  <strong>Client Telephone</strong>
                </td>
                <td>{client?.telephone}</td>
              </tr>
              <tr>
                <td>
                  <strong>Client Country</strong>
                </td>
                <td>{client?.country}</td>
              </tr>
              <tr>
                <td>
                  <strong>Client city</strong>
                </td>
                <td>{client?.city}</td>
              </tr>
              <tr>
                <td>
                  <strong>Client Image</strong>
                </td>
                <td>
                  <img
                    src={client?.image}
                    alt={client?.client_name}
                    width="150"
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <Map
                    userLat={parseInt(client?.lat)}
                    userLng={parseInt(client?.long)}
                    noAddressField
                  />
                </td>
              </tr>
            </tbody>
          </TableCard>
        )}
        {client?.client_users && (
          <UsersTable users={client?.client_users} hasActions={false} />
        )}

        {client?.brands && (
          <BrandsTable brands={client?.brands} hasActions={false} />
        )}
      </div>
    </MainContent>
  );
};

export default ClientDetails;
