import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchVoucher } from "../../redux/actions";

import { MainContent, TableCard, Grid } from "@vms-admin/components";
import { formatDate, formatTime } from "../../helpers";

export const WhatsNew = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const voucher = useSelector(({ voucher }) => voucher.voucher);

  useEffect(() => dispatch(fetchVoucher(id)), [dispatch, id]);

  return (
    <MainContent>
      <Grid columns={2}>
        <TableCard title={`${voucher?.voucher_set_name} Voucher Details`}>
          <tbody>
            <tr>
              <td>
                {voucher?.images?.map((image) => (
                  <img
                    src={image}
                    alt={voucher?.voucher_set_name}
                    width="100px"
                    style={{ marginBottom: 24, display: "inline-block" }}
                  />
                ))}
              </td>
            </tr>
            <tr>
              <td>Voucher Name</td>
              <td>{voucher?.voucher_set_name}</td>
            </tr>
            <tr>
              <td>Voucher Description</td>
              <td>{voucher?.description}</td>
            </tr>
            <tr>
              <td>Start Date</td>
              <td>
                {formatDate(voucher?.start_datetime)}{" "}
                {formatTime(voucher?.start_datetime)}
              </td>
            </tr>
            <tr>
              <td>End Date</td>
              <td>
                {formatDate(voucher?.end_datetime)}{" "}
                {formatTime(voucher?.end_datetime)}
              </td>
            </tr>
          </tbody>
        </TableCard>
        <TableCard title={`${voucher?.voucher_set_name} Merchant Details`}>
          <tbody>
            <tr>
              <td>Merchant Name</td>
              <td>{voucher?.merchant?.merchant_name}</td>
            </tr>
            <tr>
              <td>Merchant Email</td>
              <td>{voucher?.merchant?.email}</td>
            </tr>
          </tbody>
        </TableCard>
      </Grid>
      <TableCard title={`${voucher?.voucher_set_name} Subscription Plans`}>
        <tbody>
          <table>
            <tr>
              <td>Subscription Plans</td>
              <td>
                {voucher?.subscription_plans?.map((subscription_plan) => (
                  <>
                    <span>{subscription_plan.plan_name} </span>
                    {voucher.subscription_plans.length > 1 && <span>, </span>}
                  </>
                ))}
              </td>
            </tr>
          </table>
        </tbody>
      </TableCard>
    </MainContent>
  );
};

export default WhatsNew;
