import React from "react";

export const LeftArrow = ({ width = 70, height = 70 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.607"
    height="15.896"
    viewBox="0 0 17.607 15.896"
  >
    <g
      id="Group_20842"
      data-name="Group 20842"
      transform="translate(-901.793 -522.577)"
    >
      <path
        id="Path_25570"
        data-name="Path 25570"
        d="M15.256,8.8a.73.73,0,0,0,0-.983L8.18.2a.612.612,0,0,0-.914,0L.189,7.819a.733.733,0,0,0,0,.983.614.614,0,0,0,.914,0l6.62-7.13,6.62,7.13a.612.612,0,0,0,.914,0Z"
        transform="translate(901.993 538.223) rotate(-90)"
        fill="#8e8e8e"
        stroke="#8e8e8e"
        strokeWidth="0.4"
      />
      <line
        id="Line_3991"
        data-name="Line 3991"
        x2="15"
        transform="translate(903.5 530.5)"
        fill="none"
        stroke="#8e8e8e"
        stroke-linecap="round"
        strokeWidth="1.8"
      />
    </g>
  </svg>
);
