import React from "react";
import { Link } from "react-router-dom";

import { Button } from "@vms-admin/components";

export const TableCard = ({
  children,
  title,
  btnText,
  btnUrl,
  hasLink,
  hasAction,
  btnAction,
  className,
}) => (
  <div className={`card fullpage_card ${className || ""}`}>
    {title && (
      <div className="table-header">
        <h3>{title}</h3>
        {hasLink && (
          <Link to={btnUrl} className="btn btn-primary">
            {btnText}
          </Link>
        )}
        {hasAction && (
          <Button variant="tertiary" handleClick={btnAction}>
            {btnText}
          </Button>
        )}
      </div>
    )}

    <div className="table-content">
      <table className="table table-bordered table-hover">{children}</table>
    </div>
  </div>
);

export default TableCard;
