import React from "react";
import { Link } from "react-router-dom";
import { KMMRCELoading } from "@vms-admin/icons";

import * as Styled from "./not-found.styles";

export const NotFound = () => (
  <Styled.Wrapper>
    <Styled.Icon>
      <KMMRCELoading />
    </Styled.Icon>
    <Styled.Title>Page not found!</Styled.Title>
    <Styled.Text>
      Looks like the page you are looking for does not exist
    </Styled.Text>
    <Link className="btn btn-primary" to="/">
      Home
    </Link>
  </Styled.Wrapper>
);

export default NotFound;
