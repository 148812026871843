import React from "react";

export const Organisation = ({ width = 19, height = 18 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
  >
    <g
      id="Group_19936"
      data-name="Group 19936"
      transform="translate(20194 4190)"
    >
      <rect
        id="Rectangle_5824"
        data-name="Rectangle 5824"
        width="25"
        height="25"
        transform="translate(-20194 -4190)"
        fill="rgba(255,255,255,0)"
      />
      <g
        id="noun-briefcase-1038140"
        transform="translate(-20342.355 -4307.397)"
      >
        <path
          id="Path_25694"
          data-name="Path 25694"
          d="M170.323,123.761h-6.449l-.857-1.964a.363.363,0,0,0-.333-.218H158.77a.364.364,0,0,0-.333.218l-.857,1.964h-6.166a.364.364,0,0,0-.364.364v12.727a.363.363,0,0,0,.364.364h18.909a.363.363,0,0,0,.364-.364V124.125a.363.363,0,0,0-.363-.364Zm-11.315-1.455h3.437l.635,1.455h-4.707Zm-7.231,2.182h3.273v12h-3.273Zm4,12v-12H165.96v12Zm14.182,0h-3.273v-12h3.273Z"
          transform="translate(0 0)"
          fill="#333"
          stroke="#333"
          strokeWidth="0.3"
        />
      </g>
    </g>
  </svg>
);
