import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory } from "react-router";
import toast from "react-hot-toast";

import {
  MainContent,
  Card,
  Input,
  Grid,
  ErrorMessage,
  Map,
  Button,
  UploadField,
  Buttons,
  Header,
} from "@vms-admin/components";
import {
  createCustomer,
  fetchCountries,
  fetchCountryInfo,
} from "../../redux/actions";
import { getCountryOptions, getCityOptions } from "@vms-admin/utils";
import { dismissToast, somethignWentWrong } from "../../helpers";
import { registerSchema } from "../../schema";

export const Register = () => {
  const [address, setAddress] = useState("");
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();
  const countryManagementState = useSelector(
    ({ countryManagement }) => countryManagement
  );
  const {
    register,
    setValue,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange", resolver: yupResolver(registerSchema) });

  const handleChange = useCallback(
    (selectedOption) => {
      dispatch(fetchCountryInfo(selectedOption.id));
      setValue("cityName", "");
    },
    [setValue, dispatch]
  );

  const handleCreateCustomer = useCallback(
    (data) => {
      dismissToast();
      dispatch(createCustomer(data, address, lat, lng))
        .then((res) => {
          if (res.status === 200) {
            history.push("/");
            toast.success(
              `${data.firstName} ${data.lastName} has been created Successfully`
            );
          }
          res.status === 409 &&
            toast.error("Customer already exists, Please login");
        })
        .catch(() => somethignWentWrong());
    },
    [dispatch, history, address, lat, lng]
  );

  useEffect(() => dispatch(fetchCountries()), [dispatch]);

  return (
    <>
      <Header />
      <MainContent>
        <Card title="Register">
          <form onSubmit={handleSubmit(handleCreateCustomer)}>
            <Grid columns={2}>
              <Input
                type="text"
                placeholder="First Name"
                label="First Name"
                name="firstName"
                register={register}
                errors={errors}
              />
              <Input
                type="text"
                placeholder="Last Name"
                label="Last Name"
                name="lastName"
                register={register}
                errors={errors}
              />
            </Grid>
            <Grid columns={2}>
              <Input
                type="email"
                placeholder="Email Address"
                label="Email Address"
                name="emailAddress"
                register={register}
                errors={errors}
              />
              <Input
                type="tel"
                placeholder="Contact Number"
                label="Contact Number"
                name="contactNumber"
                register={register}
                errors={errors}
              />
            </Grid>
            <UploadField
              label="User image"
              name="userImage"
              errors={errors}
              register={register}
            />
            <Grid columns={2}>
              <div className="input_holder">
                <label>Country Name</label>
                <Controller
                  name="countryName"
                  errors={errors}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      menuPortalTarget={document.body}
                      options={getCountryOptions(
                        countryManagementState.countries
                      )}
                      placeholder="Select Country"
                      onChange={(e) => {
                        onChange(e);
                        handleChange(e);
                      }}
                      value={value ? value : ""}
                      className="kmmrce-select-container"
                      classNamePrefix="kmmrce-select"
                    />
                  )}
                />
                {errors && errors["countryName"] && (
                  <ErrorMessage text="Country is Required" />
                )}
              </div>
              <div className="input_holder">
                <label>City Name</label>
                <Controller
                  name="cityName"
                  control={control}
                  render={({ field }) => (
                    <Select
                      menuPortalTarget={document.body}
                      {...field}
                      options={getCityOptions(
                        countryManagementState?.countryInfo?.cities
                      )}
                      isLoading={countryManagementState?.countryInfoLoading}
                      loadingMessage={() => "Loading..."}
                      placeholder="Select City"
                      className="kmmrce-select-container"
                      classNamePrefix="kmmrce-select"
                    />
                  )}
                />
                {errors && errors["cityName"] && (
                  <ErrorMessage text="City is Required" />
                )}
              </div>
            </Grid>
            <Map
              addressInfo={(address, lat, lng) => {
                setAddress(address);
                setLat(lat);
                setLng(lng);
              }}
            />
            <Buttons>
              <Button variant="primary">Register</Button>
            </Buttons>
          </form>
        </Card>
      </MainContent>
    </>
  );
};

export default Register;
