import React, { useCallback, useState } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";

import {
  TableCard,
  Button,
  Modal,
  ToolbarButton,
  Buttons,
  TableCardLoader,
} from "@vms-admin/components";
import {
  deleteVoucher,
  fetchClientUserVouchers,
  fetchClientVouchers,
  fetchBrandVouchers,
  fetchBrandUserVouchers,
  fetchMerchantVouchers,
  fetchMerchantUserVouchers,
  fetchVouchers,
} from "../../redux/actions";
import {
  active,
  dismissToast,
  inActive,
  somethignWentWrong,
} from "../../helpers";
import { Link } from "react-router-dom";

export const VouchersTable = ({
  vouchers,
  title,
  showUsed = false,
  whatsNew,
  vouchersLoading,
}) => {
  const [target, setTarget] = useState({ name: "", id: 0 });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const userInfo = useSelector(({ auth }) => auth.userInfo);
  const userRole = useSelector(({ auth }) => auth.role);

  const dispatch = useDispatch();
  const history = useHistory();

  const toggleModal = useCallback(
    () => setIsModalOpen(!isModalOpen),
    [isModalOpen]
  );

  const handleDeleteVoucher = useCallback(
    (id) => {
      dismissToast();
      dispatch(deleteVoucher(id))
        .then((res) => {
          if (res.status === 204) {
            toggleModal();
            toast.success("Voucher deleted Successfully");
            userRole.is_client && dispatch(fetchClientVouchers(userInfo?.id));
            userRole.is_client_user &&
              dispatch(fetchClientUserVouchers(userInfo?.id));
            userRole.is_brand && dispatch(fetchBrandVouchers(userInfo?.id));
            userRole.is_brand_user &&
              dispatch(fetchBrandUserVouchers(userInfo?.id));
            userRole.is_merchant &&
              dispatch(fetchMerchantVouchers(userInfo?.id));
            userRole.is_merchant_user &&
              dispatch(fetchMerchantUserVouchers(userInfo?.id));
            userRole.is_admin && dispatch(fetchVouchers());
          }
        })
        .catch(() => somethignWentWrong());
    },
    [dispatch, userInfo, userRole, toggleModal]
  );

  return (
    <>
      <TableCard
        title={title}
        btnText="Create New Voucher"
        btnUrl="/create-voucher"
        hasLink={!userRole.is_client && !userRole.is_customer}
      >
        {!vouchers?.length && !vouchersLoading && (
          <tbody>
            <tr>
              <td className="empty_list_message" colSpan={6}>
                No Vouchers found
              </td>
            </tr>
          </tbody>
        )}

        {!vouchersLoading ? (
          <>
            <thead>
              <tr>
                <th>#</th>
                <th>Voucher name</th>
                {userRole.is_customer && showUsed && <th>Used Vouchers</th>}
                <th>Merchant Name</th>
                <th>Merchant Email</th>
                <th>Voucher Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {vouchers?.map(
                (
                  { voucher_set_name, merchant, id, count, used, is_active },
                  index
                ) => (
                  <tr key={id}>
                    <td>{index + 1}</td>
                    <td>
                      <span title={voucher_set_name} className="text-ellipsis">
                        {voucher_set_name}
                      </span>
                    </td>
                    {userRole.is_customer && showUsed && (
                      <td>
                        {used}/{count}
                      </td>
                    )}
                    <td>
                      <span
                        title={merchant?.merchant_name}
                        className="text-ellipsis"
                      >
                        {merchant?.merchant_name}
                      </span>
                    </td>
                    <td>
                      <span title={merchant?.email} className="text-ellipsis">
                        {merchant?.email}
                      </span>
                    </td>
                    <td>
                      {is_active ? active("Active") : inActive("In Active")}
                    </td>

                    {!userRole.is_customer && (
                      <td>
                        <ToolbarButton
                          btns={[
                            { btnUrl: `/voucher/${id}`, btnText: "View" },
                            {
                              btnUrl: `/edit-voucher/${id}`,
                              btnText: "Edit",
                            },
                            {
                              handleClick: () => {
                                toggleModal();
                                setTarget({ id, name: voucher_set_name });
                              },
                              btnText: "Delete",
                            },
                          ]}
                        />
                      </td>
                    )}

                    {userRole.is_customer && !whatsNew && (
                      <td>
                        {used === count ? (
                          <span className="btn btn-tertiary">Redeemed</span>
                        ) : (
                          <Button
                            variant="tertiary"
                            handleClick={() => {
                              history.push("/otp");
                              localStorage.setItem("voucher_id", id);
                            }}
                          >
                            Redeem
                          </Button>
                        )}
                      </td>
                    )}
                    {whatsNew && (
                      <td>
                        <Link
                          to={`/whatsNew/${id}`}
                          className="btn btn-tertiary"
                        >
                          Details
                        </Link>
                      </td>
                    )}
                  </tr>
                )
              )}
            </tbody>
          </>
        ) : (
          <TableCardLoader />
        )}
        {isModalOpen && (
          <Modal title="Delete">
            Are you sure you want to delete {target.name}?
            <Buttons>
              <Button variant="secondary" handleClick={toggleModal}>
                Cancel
              </Button>
              <Button
                variant="primary"
                handleClick={() => handleDeleteVoucher(target.id)}
              >
                Confirm
              </Button>
            </Buttons>
          </Modal>
        )}
      </TableCard>
    </>
  );
};

export default VouchersTable;
