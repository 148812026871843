import React from "react";

export const Envelope = ({ width = 70, height = 70 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 70 70"
  >
    <g
      id="Group_20841"
      data-name="Group 20841"
      transform="translate(-738 -107)"
    >
      <g id="Group_20840" data-name="Group 20840" transform="translate(-200 5)">
        <circle
          id="Ellipse_1145"
          data-name="Ellipse 1145"
          cx="35"
          cy="35"
          r="35"
          transform="translate(938 102)"
          fill="#4b42ae"
          opacity="0.1"
        />
        <circle
          id="Ellipse_1146"
          data-name="Ellipse 1146"
          cx="25"
          cy="25"
          r="25"
          transform="translate(948 112)"
          fill="#4b42ae"
          opacity="0.2"
        />
        <g id="noun-mail-1103184" transform="translate(757.171 -31.093)">
          <path
            id="Path_25677"
            data-name="Path 25677"
            d="M208.491,177.232h14.675a3.625,3.625,0,0,0,3.62-3.621V162.575a3.625,3.625,0,0,0-3.62-3.621H208.491a3.625,3.625,0,0,0-3.62,3.621v11.037a3.625,3.625,0,0,0,3.62,3.621Zm17-3.62h0a2.321,2.321,0,0,1-2.317,2.317h-14.68a2.321,2.321,0,0,1-2.317-2.317V162.575a2.306,2.306,0,0,1,.383-1.273l7.421,6.109a2.915,2.915,0,0,0,3.7,0L225.1,161.3a2.306,2.306,0,0,1,.383,1.273Zm-2.321-13.359a2.3,2.3,0,0,1,.92.194l-7.236,5.959a1.612,1.612,0,0,1-2.048,0l-7.232-5.959a2.3,2.3,0,0,1,.92-.194Z"
            transform="translate(0)"
            fill="#4b42ae"
          />
        </g>
      </g>
    </g>
  </svg>
);
