import React, { useState, useEffect } from "react";

import { ErrorMessage } from "@vms-admin/components";

export const Textarea = ({
  type,
  placeholder,
  name,
  value,
  register,
  errors,
  label,
  defaultValue = "",
}) => {
  const [charactersCount, setCharactersCount] = useState(250);

  useEffect(
    () =>
      value?.length
        ? setCharactersCount(250 - value.length)
        : setCharactersCount(250 - defaultValue?.length),
    [value, defaultValue]
  );

  return (
    <div className={`input_holder ${errors && errors[name] ? `error` : ""}`}>
      <label>
        {label}
        <textarea
          type={type}
          placeholder={placeholder}
          value={value}
          defaultValue={defaultValue}
          name={name}
          {...register(name, {
            onChange: ({ target: { value } }) => {
              setCharactersCount(250 - value.length);
            },
          })}
          style={{ marginTop: "10px" }}
          maxLength="250"
        />
      </label>
      <div className="input__characters-count">{charactersCount}/250</div>
      {errors && errors[name] && <ErrorMessage text={errors[name].message} />}
    </div>
  );
};

export default Textarea;
