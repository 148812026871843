import {
  FETCH_REDEEMED_VOUCHERS_SUCCESS,
  FETCH_VOUCHERS_SUCCESS,
  FETCH_VOUCHER_CATEGORIES_SUCCESS,
  FETCH_VOUCHER_SCOPES_SUCCESS,
  FETCH_VOUCHER_SUCCESS,
  FETCH_VOUCHER_SCOPE_SUCCESS,
  FETCH_VOUCHERS_LOADING,
  FETCH_REDEEMED_VOUCHERS_LOADING,
  FETCH_CLIENT_VOUCHERS_SUCCESS,
  FETCH_BRAND_USER_VOUCHERS_SUCCESS,
  FETCH_MERCHANT_VOUCHERS_SUCCESS,
  FETCH_MERCHANT_USER_VOUCHERS_SUCCESS,
  FETCH_CLIENT_USER_VOUCHERS_SUCCESS,
  FETCH_CUSTOMER_VOUCHERS_SUCCESS,
  FETCH_VOUCHER_LOADING,
  FETCH_BRAND_VOUCHERS_SUCCESS,
} from "../actions/actions.constants";

const initialState = {
  vouchers: [],
  vouchersLoading: true,
  categories: [],
  scopes: [],
  voucher: {},
  voucherScope: {},
  redeemedVouchers: [],
  redeemedVouchersLoading: true,
  brandVouchers: [],
  clientVouchers: [],
  brandUserVouchers: [],
  merchantVouchers: [],
  merchantUserVouchers: [],
  clientUserVouchers: [],
  customerVouchers: [],
  voucherLoading: false,
};

const voucherReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VOUCHERS_SUCCESS:
      return { ...state, vouchers: action.data, vouchersLoading: false };
    case FETCH_VOUCHERS_LOADING:
      return { ...state, vouchersLoading: true };
    case FETCH_VOUCHER_SUCCESS:
      return { ...state, voucher: action.data, voucherLoading: false };
    case FETCH_VOUCHER_LOADING:
      return { ...state, voucherLoading: true };
    case FETCH_VOUCHER_CATEGORIES_SUCCESS:
      return { ...state, categories: action.data.data };
    case FETCH_VOUCHER_SCOPES_SUCCESS:
      return { ...state, scopes: action.data };
    case FETCH_VOUCHER_SCOPE_SUCCESS:
      return { ...state, voucherScope: action.data };
    case FETCH_REDEEMED_VOUCHERS_SUCCESS:
      return {
        ...state,
        redeemedVouchers: action.data,
        redeemedVouchersLoading: false,
      };
    case FETCH_REDEEMED_VOUCHERS_LOADING:
      return { ...state, redeemedVouchersLoading: true };
    case FETCH_CLIENT_VOUCHERS_SUCCESS:
      return { ...state, clientVouchers: action.data, vouchersLoading: false };
    case FETCH_BRAND_VOUCHERS_SUCCESS:
      return {
        ...state,
        brandVouchers: action.data,
        vouchersLoading: false,
      };
    case FETCH_BRAND_USER_VOUCHERS_SUCCESS:
      return {
        ...state,
        brandUserVouchers: action.data,
        vouchersLoading: false,
      };
    case FETCH_MERCHANT_VOUCHERS_SUCCESS:
      return {
        ...state,
        merchantVouchers: action.data,
        vouchersLoading: false,
      };
    case FETCH_MERCHANT_USER_VOUCHERS_SUCCESS:
      return {
        ...state,
        merchantUserVouchers: action.data,
        vouchersLoading: false,
      };
    case FETCH_CLIENT_USER_VOUCHERS_SUCCESS:
      return {
        ...state,
        clientUserVouchers: action.data,
        vouchersLoading: false,
      };
    case FETCH_CUSTOMER_VOUCHERS_SUCCESS:
      return {
        ...state,
        customerVouchers: action.data,
        vouchersLoading: false,
      };
    default:
      return state;
  }
};

export default voucherReducer;
