import axios from "axios";
import API_URL from "../../api/api.constants";
import { getBase64 } from "../../helpers";
import {
  CREATE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_SUCCESS,
  FETCH_CUSTOMERS_LOADING,
  FETCH_CUSTOMERS_SUCCESS,
  FETCH_CUSTOMER_NEW_SUCCESS,
  FETCH_CUSTOMER_PLANS_SUCCESS,
  FETCH_CUSTOMER_SUCCESS,
  FETCH_CUSTOMER_LOADING,
} from "./actions.constants";

export const fetchCustomers = () => (dispatch) => {
  dispatch({ type: FETCH_CUSTOMERS_LOADING });
  const accessToken = localStorage.getItem("access-token");
  let config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios(`${API_URL.customers}`, config)
    .then((res) => res.status === 200 && res.data)
    .then(({ data }) => dispatch({ type: FETCH_CUSTOMERS_SUCCESS, data }))
    .catch((error) => console.log("error", error));
};

export const fetchCustomer = (customerId) => (dispatch) => {
  dispatch({ type: FETCH_CUSTOMER_LOADING });
  const accessToken = localStorage.getItem("access-token");
  let config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios(`${API_URL.customers}/${customerId}`, config)
    .then((res) => res.status === 200 && res.data)
    .then(({ customer }) =>
      dispatch({ type: FETCH_CUSTOMER_SUCCESS, data: customer })
    )
    .catch((error) => console.log("error", error));
};

export const fetchCustomerPlans = (userId) => (dispatch) => {
  const accessToken = localStorage.getItem("access-token");
  let config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios(`${API_URL.customers}/${userId}/plans`, config)
    .then((res) => res.status === 200 && res.data)
    .then(({ data }) => dispatch({ type: FETCH_CUSTOMER_PLANS_SUCCESS, data }))
    .catch((error) => console.log("error", error));
};

export const fetchCustomerNew = () => (dispatch) => {
  const accessToken = localStorage.getItem("access-token");
  let config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios(`${API_URL.customers}/new`, config)
    .then((res) => res.status === 200 && res.data)
    .then(({ data }) => dispatch({ type: FETCH_CUSTOMER_NEW_SUCCESS, data }))
    .catch((error) => console.log("error", error));
};

export const deleteCustomer = (customerId) => (dispatch) => {
  const accessToken = localStorage.getItem("access-token");
  let config = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios(`${API_URL.customers}/${customerId}`, config)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: DELETE_CUSTOMER_SUCCESS });
        return Promise.resolve(res);
      }
    })
    .catch((error) => Promise.reject(error));
};

export const createCustomer =
  (formData, address, lat, lng) => async (dispatch) => {
    const accessToken = localStorage.getItem("access-token");
    const image = await getBase64(formData.userImage[0]);

    return axios(API_URL.register, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        firstname: formData.firstName,
        lastname: formData.lastName,
        phone: formData.contactNumber,
        email: formData.emailAddress,
        country: formData.countryName.value,
        city: formData.cityName.value,
        image,
        address,
        lat,
        long: lng,
      },
    })
      .then((res) => {
        dispatch({ type: CREATE_CUSTOMER_SUCCESS });
        return Promise.resolve(res);
      })
      .catch((error) => Promise.reject(error));
  };
