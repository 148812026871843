import React from "react";
import { Chart as GoogleChart } from "react-google-charts";

import { Card, MainContent } from "@vms-admin/components";

const geoData = [
  ["Country", "Brands"],
  ["Germany", 200],
  ["United States", 300],
  ["Brazil", 400],
  ["Canada", 500],
  ["France", 600],
  ["RU", 700],
  ["Egypt", 1000],
];

export const Overview = () => {
  return (
    <MainContent>
      <Card title="Global Stats">
        <GoogleChart
          width="100%"
          height="400px"
          chartType="GeoChart"
          data={geoData}
          // Note: you will need to get a mapsApiKey for your project.
          // See: https://developers.google.com/chart/interactive/docs/basic_load_libs#load-settings
          mapsApiKey="YOUR_MAP_API_KEY_GOES_HERE"
          rootProps={{ "data-testid": "1" }}
          options={{
            colors: ["#6ADC9E", "#49D488", "#2FC673", "#27A560"],
          }}
        />
      </Card>
    </MainContent>
  );
};

export default Overview;
