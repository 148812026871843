import {
  FETCH_CUSTOMERS_SUCCESS,
  FETCH_CUSTOMER_SUCCESS,
  FETCH_CUSTOMER_NEW_SUCCESS,
  FETCH_CUSTOMER_PLANS_SUCCESS,
  FETCH_CUSTOMERS_LOADING,
  FETCH_CUSTOMER_LOADING,
} from "../actions/actions.constants";

const initialState = {
  customers: [],
  customersLoading: true,
  customer: {},
  customerLoading: false,
  vouchers: [],
  plans: [],
  new: [],
};

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CUSTOMERS_SUCCESS:
      return {
        ...state,
        customers: action?.data?.data,
        customersLoading: false,
      };
    case FETCH_CUSTOMERS_LOADING:
      return { ...state, customersLoading: true };
    case FETCH_CUSTOMER_SUCCESS:
      return { ...state, customer: action.data, customerLoading: false };
    case FETCH_CUSTOMER_LOADING:
      return { ...state, customerLoading: true };
    case FETCH_CUSTOMER_PLANS_SUCCESS:
      return { ...state, plans: action.data };
    case FETCH_CUSTOMER_NEW_SUCCESS:
      return { ...state, new: action.data.data };
    default:
      return state;
  }
};

export default customerReducer;
