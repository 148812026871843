import * as Styled from "../dashboard-content/dashboard-content.style";
import { Grid, Card } from "@vms-admin/components";
import CountUp from "react-countup";
import styled from "styled-components";
import Chart from "react-apexcharts";
import { Button } from "@vms-admin/components";
import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboardItems } from "../../redux/actions/dashboard.actions";
import { useHistory } from "react-router";

export const DashboardRevenue = () => {
  const dispatch = useDispatch();

  useEffect(() => dispatch(fetchDashboardItems()), [dispatch]);
  const history = useHistory();

  const [instance, setInstance] = useState("");

  useEffect(() => {
    if (window.location.href.includes("incontrol")) {
      setInstance("in-control");
    }
    if (window.location.href.includes("rokit")) {
      setInstance("rokit");
    }
    if (window.location.href.includes("afritech")) {
      setInstance("starlink");
    }
  }, []);

  const revenueDashboard = useSelector(({ dashboard }) => {
    return dashboard?.revenue;
  });

  const barOptions = {
    chart: {
      type: "bar",
      fontFamily: "Yellix",
    },
    plotOptions: {
      bar: {
        columnWidth: "30%",
      },
    },
    colors: instance === "starlink" ? ["#fff"] : ["#3CD17F"],
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      customLegendItems: ["Subscriptions"],
      markers: {
        fillColors: instance === "starlink" ? ["#fff"] : ["#3CD17F"],
      },
    },
  };
  const data = useMemo(() => {
    return revenueDashboard?.subscriptions?.reduce((acc, val) => {
      const date = new Date(val.date);

      const year = date.getFullYear();
      const month = date.getMonth();

      const index = acc.findIndex((item) => item.x === `${year}-${month}`);

      if (index === -1) {
        acc.push({
          x: `${year}-${month}`,
          y: val.count,
        });
      } else {
        acc[index].y += val.count;
      }

      return acc;
    }, []);
  }, [revenueDashboard?.subscriptions]);

  const barData = [
    {
      name: "Subscriptions",
      data: data,
    },
  ];

  const overviewChartOptions = {
    colors:
      instance === "starlink" ? ["#262626", "#4c4c4c"] : ["#4B42AD", "#3CD17F"],
    legend: {
      position: "bottom",
      horizontalAlign: "left",
    },
    chart: {
      type: "donut",
    },
    labels: ["Total", "Active"],
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  };

  const overviewChartData = [
    revenueDashboard?.total_subscriptions || 0,
    revenueDashboard?.active_subscriptions || 0,
  ];

  return (
    <main className="main_page">
      <Styled.Title>Dashboard</Styled.Title>
      <Grid columns={2}>
        <div>
          <Card
            title="Total Subscriptions"
            subtitle={
              <span
                style={{
                  fontSize: "30px",
                  fontWeight: "normal",
                }}
              >
                <CountUp
                  className="animate_number_decima"
                  end={revenueDashboard?.total_subscriptions}
                />
              </span>
            }
            className="height_full"
          >
            <ChartWrapper>
              <Chart
                options={barOptions}
                series={barData}
                height={370}
                type="bar"
                width="100%"
              />
            </ChartWrapper>
          </Card>
          <Card title="Subscriptions" className="mt20">
            <Chart
              options={overviewChartOptions}
              series={overviewChartData}
              height={330}
              width="100%"
              type="donut"
            />
          </Card>
        </div>
        <div>
          <Card
            title="Most Redeemed Vouchers"
            footerContent={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="tertiary"
                  handleClick={() => history.push("/vouchers")}
                >
                  View All
                </Button>
              </div>
            }
          >
            <div className="mt20">
              {revenueDashboard?.most_active_vouchers?.map((item, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingBottom: "15px",
                    marginBottom:
                      index + 1 ===
                      revenueDashboard?.most_active_vouchers?.length
                        ? ""
                        : "30px",
                    borderBottom:
                      index + 1 !==
                      revenueDashboard?.most_active_vouchers?.length
                        ? "1px solid #E5E5E5"
                        : "",
                  }}
                >
                  <h5>{item.name}</h5>
                  <h5>{item.count}</h5>
                </div>
              ))}
            </div>
          </Card>
          <Card
            className="mt20"
            title="Most Active Subscription Plans"
            footerContent={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="tertiary"
                  handleClick={() => history.push("/subscription-plans")}
                >
                  View All
                </Button>
              </div>
            }
          >
            <div className="mt20">
              {revenueDashboard?.most_active_subscriptions?.map(
                (item, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingBottom: "15px",
                      marginBottom:
                        index + 1 ===
                        revenueDashboard?.most_active_subscriptions?.length
                          ? ""
                          : "30px",
                      borderBottom:
                        index + 1 !==
                        revenueDashboard?.most_active_subscriptions?.length
                          ? "1px solid #E5E5E5"
                          : "",
                    }}
                  >
                    <h5>{item.name}</h5>
                    <h5>{item.count}</h5>
                  </div>
                )
              )}
            </div>
          </Card>
        </div>
      </Grid>
    </main>
  );
};

export const ChartWrapper = styled.div`
  padding: 24px 0;
  padding-right: ${(props) => props.theme.space[4]}px;
`;
