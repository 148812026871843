import React from "react";

export const KMMRCELogo = ({ width = 140, height = 34 }) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 3749.000000 391.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,391.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
    >
      <path d="M27785 3886 c-375 -99 -597 -366 -654 -789 -6 -43 -11 -152 -11 -243 l0 -164 -250 0 -250 0 0 -285 0 -285 248 -2 247 -3 3 -1027 2 -1028 300 0 300 0 2 1028 3 1027 338 3 337 2 0 285 0 285 -340 0 -340 0 0 128 c0 71 5 155 11 188 24 134 109 248 225 301 105 48 282 64 412 37 l32 -6 0 275 c0 252 -1 276 -17 283 -10 4 -128 9 -263 11 -231 3 -250 2 -335 -21z" />
      <path d="M30997 3900 c-200 -35 -340 -233 -308 -435 20 -128 102 -239 215 -292 54 -25 72 -28 161 -28 91 0 106 3 167 32 131 62 208 179 216 328 5 96 -17 168 -73 245 -84 114 -236 174 -378 150z" />
      <path d="M16974 3854 c-891 -121 -1541 -837 -1603 -1764 -77 -1165 751 -2081 1882 -2080 536 0 994 187 1335 545 156 164 277 359 352 570 27 77 70 234 70 258 0 4 -157 7 -350 7 l-349 0 -12 -42 c-16 -63 -81 -197 -127 -264 -207 -302 -576 -478 -967 -461 -256 11 -474 89 -677 242 -547 412 -678 1252 -287 1850 68 104 242 278 344 344 428 279 981 266 1376 -31 151 -114 284 -293 329 -445 l13 -43 349 0 349 0 -7 38 c-22 130 -73 274 -143 409 -248 471 -759 798 -1361 873 -115 14 -393 11 -516 -6z" />
      <path d="M50 1940 l0 -1880 315 0 315 0 0 911 c0 502 4 909 9 907 4 -1 363 -411 796 -911 l788 -907 413 0 c228 0 414 2 414 5 0 3 -396 449 -880 992 l-880 987 43 45 c25 26 374 408 777 851 403 443 749 822 769 843 l35 37 -405 0 -405 0 -730 -810 c-401 -445 -732 -810 -736 -810 -5 0 -8 365 -8 810 l0 810 -315 0 -315 0 0 -1880z" />
      <path d="M3310 1940 l0 -1880 315 0 315 0 2 1249 3 1248 545 -1249 544 -1248 233 2 234 3 552 1242 c304 683 556 1246 560 1249 4 4 7 -556 7 -1244 l0 -1252 315 0 315 0 0 1880 0 1880 -325 -2 -326 -3 -638 -1403 c-351 -772 -641 -1404 -646 -1404 -5 0 -288 633 -629 1406 l-620 1406 -378 0 -378 0 0 -1880z" />
      <path d="M7880 1940 l0 -1880 315 0 315 0 2 1253 3 1253 545 -1250 546 -1251 234 -2 235 -3 555 1251 555 1250 3 -1250 2 -1251 315 0 315 0 0 1880 0 1880 -323 0 -322 0 -640 -1410 c-352 -775 -642 -1410 -645 -1410 -3 0 -285 633 -627 1408 l-621 1407 -381 3 -381 2 0 -1880z" />
      <path d="M12450 1940 l0 -1880 315 0 315 0 2 753 3 752 273 3 272 2 48 -74 c26 -40 236 -380 467 -754 l420 -681 383 -1 c210 0 382 3 381 8 0 4 -229 358 -508 787 l-508 780 31 14 c195 91 282 148 382 253 185 194 280 437 291 748 6 173 -9 279 -61 425 -129 361 -426 619 -826 718 -82 20 -113 21 -882 24 l-798 4 0 -1881z m1538 1255 c96 -23 179 -70 253 -144 76 -76 112 -145 135 -251 47 -225 -72 -468 -279 -570 -114 -56 -163 -61 -609 -58 l-403 3 -3 518 -2 517 422 0 c338 0 436 -3 486 -15z" />
      <path d="M19520 1940 l0 -1880 1110 0 1110 0 0 300 0 300 -795 0 -795 0 0 535 0 535 710 0 710 0 0 300 0 300 -710 0 -710 0 0 445 0 445 795 0 795 0 0 300 0 300 -1110 0 -1110 0 0 -1880z" />
      <path d="M24460 3795 c-29 -75 -1483 -3721 -1487 -3728 -2 -4 148 -6 333 -5 l338 3 145 372 146 373 795 0 795 0 144 -375 144 -375 342 0 343 0 -54 138 c-30 75 -366 920 -748 1877 l-694 1740 -266 3 -266 2 -10 -25z m543 -1648 c147 -380 270 -702 273 -714 l6 -23 -557 0 c-526 0 -557 1 -551 18 57 153 552 1411 556 1411 3 0 126 -311 273 -692z" />
      <path d="M29934 2722 c-85 -22 -201 -75 -281 -130 -65 -44 -183 -164 -210 -214 -18 -33 -18 -32 -21 140 l-2 172 -300 0 -300 0 0 -1315 0 -1315 300 0 300 0 0 844 0 843 50 77 c124 190 336 335 527 361 59 8 196 -8 302 -34 l71 -18 -2 286 -3 286 -50 13 c-27 7 -108 15 -180 18 -104 3 -144 0 -201 -14z" />
      <path d="M32945 2725 c-380 -62 -707 -267 -908 -570 -157 -237 -227 -478 -227 -779 0 -250 39 -426 139 -631 179 -367 498 -614 912 -707 140 -31 444 -33 584 -4 512 107 882 456 969 914 l14 72 -308 0 -308 0 -16 -46 c-56 -164 -245 -320 -451 -370 -101 -25 -276 -23 -378 4 -134 37 -229 93 -337 202 -81 80 -103 110 -143 190 -70 141 -82 197 -82 380 1 149 2 159 33 250 46 133 101 221 196 315 95 93 163 136 278 176 215 73 449 51 638 -61 105 -62 225 -200 245 -282 l7 -28 309 0 c170 0 309 2 309 4 0 25 -35 158 -55 212 -84 222 -235 409 -440 546 -151 100 -281 155 -470 198 -121 28 -383 35 -510 15z" />
      <path d="M35798 2725 c-451 -74 -830 -408 -983 -868 -60 -182 -70 -248 -70 -482 0 -183 3 -223 23 -314 129 -573 526 -963 1063 -1042 124 -18 331 -7 452 25 212 55 410 176 525 321 l31 40 1 -172 0 -173 295 0 295 0 0 1315 0 1315 -295 0 -295 0 0 -170 0 -170 -92 94 c-134 135 -280 215 -491 267 -109 27 -338 34 -459 14z m480 -571 c217 -45 416 -171 527 -336 l35 -53 0 -393 0 -392 -24 -38 c-91 -145 -276 -275 -471 -333 -100 -29 -277 -37 -382 -15 -252 51 -447 204 -557 437 -59 124 -71 185 -71 354 1 143 2 154 33 245 83 241 249 411 483 496 122 44 295 55 427 28z" />
      <path d="M30760 1375 l0 -1315 300 0 300 0 0 1315 0 1315 -300 0 -300 0 0 -1315z" />
      <path d="M22187 650 c-141 -36 -231 -150 -231 -295 0 -172 128 -300 300 -300 60 1 82 6 138 34 221 109 215 435 -9 537 -58 27 -144 37 -198 24z m148 -57 c72 -24 139 -95 161 -169 62 -213 -173 -400 -361 -289 -95 55 -148 164 -127 262 19 92 81 166 168 197 53 20 101 19 159 -1z" />
      <path d="M22140 356 l0 -166 35 0 34 0 3 62 c2 36 8 63 15 65 6 2 29 -25 50 -61 l38 -66 49 0 48 0 -18 28 c-9 15 -28 44 -42 64 l-24 37 21 27 c30 38 29 104 -3 141 -23 26 -30 28 -115 31 l-91 4 0 -166z m149 76 c14 -26 -4 -42 -46 -42 -28 0 -33 4 -33 23 0 31 7 37 41 37 17 0 32 -7 38 -18z" />
    </g>
  </svg>
);
