import React, { useState, useCallback, useEffect } from "react";
import { Upload } from "@vms-admin/icons";
import { ErrorMessage } from "@vms-admin/components";

import * as Styled from "./upload-field.style";
import { getBase64 } from "../../helpers";

export const UploadField = ({
  label,
  name,
  value,
  image,
  errors,
  register,
}) => {
  const [uploadedImg, setUploadedImg] = useState("");
  const [imageName, setImageName] = useState("");

  const getImg = useCallback(
    (fileSrc) => getBase64(fileSrc).then((res) => setUploadedImg(res)),
    []
  );

  useEffect(() => {
    image && setUploadedImg(image);
  }, [image]);

  return (
    <>
      <label>
        {label}
        <Styled.UploadField hasError={errors && errors[name]}>
          <Styled.Input
            type="file"
            name={name}
            value={value}
            {...register(name, {
              onChange: (e) => {
                getImg(e.target.files[0]);
                setImageName(e.target.files[0].name);
              },
            })}
          />
          <Styled.Wrapper>
            <Styled.Icon>
              <Upload />
            </Styled.Icon>
            <Styled.Text>
              Drag and drop the image or click to select files
            </Styled.Text>
          </Styled.Wrapper>
        </Styled.UploadField>
        {uploadedImg && (
          <Styled.ImgWrapper>
            <Styled.ImgInfo>
              <Styled.UploadedImg src={uploadedImg} /> {imageName}
            </Styled.ImgInfo>
          </Styled.ImgWrapper>
        )}
      </label>
      <div style={{ marginBottom: "16px" }}>
        {errors && errors[name] && <ErrorMessage text={errors[name].message} />}
      </div>
    </>
  );
};

export default UploadField;
