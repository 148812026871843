import React from "react";

export const Loyalty = ({ width = 19, height = 18 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
  >
    <g
      id="Group_20852"
      data-name="Group 20852"
      transform="translate(-1663 -737)"
    >
      <rect
        id="Rectangle_5824"
        data-name="Rectangle 5824"
        width="25"
        height="25"
        transform="translate(1663 737)"
        fill="rgba(255,255,255,0)"
      />
      <g id="noun-heart-1216238" transform="translate(1489.802 613.047)">
        <path
          id="Path_25687"
          data-name="Path 25687"
          d="M189.919,128.87a4.552,4.552,0,0,0-3.265,1.381l-1.18,1.207-1.18-1.2a4.559,4.559,0,0,0-6.539,0,4.769,4.769,0,0,0,0,6.658q3.756,3.833,7.517,7.664a.293.293,0,0,0,.412,0l7.508-7.673a4.771,4.771,0,0,0,0-6.658,4.572,4.572,0,0,0-3.274-1.381Zm0,.576a3.987,3.987,0,0,1,2.853,1.216,4.174,4.174,0,0,1,0,5.835l-7.3,7.462q-3.652-3.726-7.3-7.453a4.173,4.173,0,0,1,0-5.835,3.961,3.961,0,0,1,5.707,0l1.381,1.408h0a.293.293,0,0,0,.412,0l1.4-1.418a3.966,3.966,0,0,1,2.844-1.216Z"
          fill="#333"
          stroke="#333"
          strokeWidth="0.6"
        />
      </g>
    </g>
  </svg>
);
