import React from "react";

export const Success = ({ width = 19, height = 18 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="70"
    height="70"
    viewBox="0 0 70 70"
  >
    <g
      id="Group_20841"
      data-name="Group 20841"
      transform="translate(-738 -107)"
    >
      <g id="Group_20840" data-name="Group 20840" transform="translate(-200 5)">
        <circle
          id="Ellipse_1145"
          data-name="Ellipse 1145"
          cx="35"
          cy="35"
          r="35"
          transform="translate(938 102)"
          fill="#3cd17f"
          opacity="0.1"
        />
        <circle
          id="Ellipse_1146"
          data-name="Ellipse 1146"
          cx="25"
          cy="25"
          r="25"
          transform="translate(948 112)"
          fill="#3cd17f"
          opacity="0.2"
        />
      </g>
    </g>
    <g id="noun-tick-1157221" transform="translate(-193.843 -135.914)">
      <path
        id="Path_25678"
        data-name="Path 25678"
        d="M227.335,181.114l-9.6-7.282a.785.785,0,0,1,.948-1.25l8.271,6.273,11.858-17.791a.785.785,0,1,1,1.308.87Z"
        fill="#3cd17f"
        stroke="#3cd17f"
        strokeWidth="1"
      />
    </g>
  </svg>
);
