import {
  FETCH_BRANDS_SUCCESS,
  FETCH_BRAND_SUCCESS,
  FETCH_BRAND_CATEGORIES_SUCCESS,
  FETCH_BRAND_USERS_SUCCESS,
  FETCH_BRANDS_LOADING,
  FETCH_CLIENT_BRANDS_SUCCESS,
  FETCH_CLIENT_USER_BRANDS_SUCCESS,
  FETCH_BRAND_LOADING,
} from "../actions/actions.constants";

const initialState = {
  brands: [],
  brandsLoading: true,
  brand: {},
  categories: [],
  users: [],
  clientBrands: [],
  clientUserBrands: [],
  brandLoading: false,
};

const brandsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BRANDS_SUCCESS:
      return { ...state, brands: action.data, brandsLoading: false };
    case FETCH_BRANDS_LOADING:
      return { ...state, brandsLoading: true };
    case FETCH_BRAND_SUCCESS:
      return { ...state, brand: action.data, brandLoading: false };
    case FETCH_BRAND_LOADING:
      return { ...state, brandLoading: true };
    case FETCH_BRAND_CATEGORIES_SUCCESS:
      return { ...state, categories: action.data.data };
    case FETCH_BRAND_USERS_SUCCESS:
      return { ...state, users: action.data };
    case FETCH_CLIENT_BRANDS_SUCCESS:
      return { ...state, clientBrands: action.data, brandsLoading: false };
    case FETCH_CLIENT_USER_BRANDS_SUCCESS:
      return { ...state, clientUserBrands: action.data, brandsLoading: false };
    default:
      return state;
  }
};

export default brandsReducer;
