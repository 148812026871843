import React, { useCallback, useEffect } from "react";
import { Upload } from "@vms-admin/icons";
import ImageUploading from "react-images-uploading";

import { Button, Grid } from "@vms-admin/components";
import * as Styled from "./multiple-upload.style";

export const MultipleUpload = ({
  name,
  register,
  errors,
  label,
  getImages,
  defaultValue,
}) => {
  const [images, setImages] = React.useState([]);
  const maxNumber = 3;

  const formattedImageList = useCallback(
    (list) => list.map((item) => (item.dataURL ? item.dataURL : item)),
    []
  );
  const onChange = (imageList) => {
    setImages(formattedImageList(imageList));
    getImages(formattedImageList(imageList));
  };
  useEffect(() => {
    if (defaultValue) {
      setImages(defaultValue);
    }
  }, [defaultValue]);

  return (
    <>
      <Styled.Label>{label}</Styled.Label>
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          <Grid columns={2}>
            <Styled.UploadField
              style={isDragging ? { color: "red" } : undefined}
              onClick={onImageUpload}
              {...dragProps}
              role="button"
              register={register}
            >
              <Styled.Wrapper>
                <Styled.Icon>
                  <Upload />
                </Styled.Icon>
                <Styled.Text>
                  Drag and drop the image or click to select files
                </Styled.Text>
              </Styled.Wrapper>
            </Styled.UploadField>

            <Styled.ImagesPreview>
              {imageList &&
                imageList?.map((image, index) => (
                  <Styled.ImageWrapper>
                    <Styled.Image src={image.dataURL || image} alt={image} />
                    <Button
                      variant="danger"
                      handleClick={() => onImageRemove(index)}
                    >
                      Remove
                    </Button>
                  </Styled.ImageWrapper>
                ))}
            </Styled.ImagesPreview>
          </Grid>
        )}
      </ImageUploading>

      {errors && errors[name] && (
        <p style={{ color: "#cd0303" }}>{errors[name].message}</p>
      )}
    </>
  );
};

export default MultipleUpload;
