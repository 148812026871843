import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { BrandsTable, MainContent } from "@vms-admin/components";
import {
  fetchBrands,
  fetchClientBrands,
  fetchClientUserBrands,
} from "../../redux/actions";

export const BrandManagement = () => {
  const [userBrands, setUserBrands] = useState([]);
  const dispatch = useDispatch();
  const brands = useSelector(({ brands }) => brands);
  const userInfo = useSelector(({ auth }) => auth.userInfo);
  const userRole = useSelector(({ auth }) => auth.role);

  useEffect(() => {
    userRole.is_client && dispatch(fetchClientBrands(userInfo?.id));
    userRole.is_client_user &&
      dispatch(fetchClientUserBrands(userInfo?.client_id));

    userRole.is_admin && dispatch(fetchBrands());
  }, [dispatch, userInfo, userRole]);

  useEffect(() => {
    userRole.is_client && setUserBrands(brands.clientBrands);
    userRole.is_client_user && setUserBrands(brands.clientUserBrands);

    userRole.is_admin && setUserBrands(brands?.brands);
  }, [brands, userRole]);

  return (
    <MainContent>
      <BrandsTable brands={userBrands} brandsLoading={brands?.brandsLoading} />
    </MainContent>
  );
};

export default BrandManagement;
