import React, { useEffect } from "react";

import { MainContent, TableCard, Pagination } from "@vms-admin/components";
import { formatDate, formatTime } from "../../helpers";
import { useDispatch, useSelector } from "react-redux";
import { fetchActivityLogs } from "../../redux/actions/admin.actions";
import { usePagination } from "../../hooks/usePagination";
import { Link } from "react-router-dom";

export const ActivityLogs = () => {
  const activityLogs = useSelector(({ admin }) => admin.activityLogs);
  const dispatch = useDispatch();

  const {
    goToNextPage,
    goToPrevPage,
    pagesArray,
    canGoNext,
    canGoPrev,
    currentPage,
    pageDecrementBtn,
    pageIncementBtn,
  } = usePagination(activityLogs?.data?.pages);

  useEffect(
    () => dispatch(fetchActivityLogs(currentPage, 50)),
    [dispatch, currentPage]
  );

  return (
    <MainContent>
      <TableCard title="Activity Logs">
        {!activityLogs?.data?.data?.length && (
          <tbody>
            <tr>
              <td className="empty_list_message">No Logs found</td>
            </tr>
          </tbody>
        )}
        {activityLogs?.data?.data?.length > 0 && (
          <>
            <thead>
              <tr>
                <th>#</th>
                <th>User Email</th>
                <th>User Role</th>
                <th>Action</th>
                <th>target type</th>
                <th>target Id</th>
                <th>Created At</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {activityLogs?.data?.data?.map(
                (
                  {
                    id,
                    user_email,
                    user_role,
                    action,
                    target_type,
                    target_id,
                    created_at,
                  },
                  index
                ) => (
                  <tr key={index}>
                    <td>{id}</td>
                    <td>{user_email}</td>
                    <td>{user_role}</td>
                    <td>
                      {action === "DELETE" && (
                        <span className="label label-delete">{action}</span>
                      )}

                      {action === "UPDATE" && (
                        <span className="label label-update">{action}</span>
                      )}

                      {action === "CREATE" && (
                        <span className="label label-create">{action}</span>
                      )}
                    </td>
                    <td>{target_type}</td>
                    <td>{target_id}</td>
                    <td>
                      {formatDate(created_at)} {formatTime(created_at)}
                    </td>
                    <td>
                      <Link
                        to={`/admin-activity-logs/${id}`}
                        className="btn btn-tertiary"
                      >
                        View
                      </Link>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </>
        )}
      </TableCard>
      {activityLogs?.data?.pages > 1 && (
        <Pagination
          canGoNext={canGoNext}
          canGoPrev={canGoPrev}
          goToNextPage={goToNextPage}
          goToPrevPage={goToPrevPage}
          pagesArray={pagesArray}
          pageDecrementBtn={pageDecrementBtn}
          pageIncementBtn={pageIncementBtn}
        />
      )}
    </MainContent>
  );
};

export default ActivityLogs;
