import axios from "axios";
import API_URL from "../../api/api.constants";
import {
  CREATE_CITY_SUCCESS,
  DELETE_CITY_SUCCESS,
  FETCH_COUNTRIES_INFO_SUCCESS,
  FETCH_COUNTRIES_SUCCESS,
  FETCH_CURRENCIES_SUCCESS,
  FETCH_COUNTRIES_INFO_LOADING,
} from "./actions.constants";

export const fetchCountries = () => (dispatch) => {
  return axios
    .get(API_URL.countries)
    .then(
      (res) =>
        res.status === 200 &&
        dispatch({ type: FETCH_COUNTRIES_SUCCESS, countries: res.data.data })
    )
    .catch((error) => console.log("error", error));
};

export const fetchCurrencies = () => (dispatch) => {
  return axios
    .get(API_URL.currencies)
    .then(
      (res) =>
        res.status === 200 &&
        dispatch({
          type: FETCH_CURRENCIES_SUCCESS,
          currencies: res.data.currencies,
        })
    )
    .catch((error) => console.log("error", error));
};

export const fetchCountryInfo = (countryName) => async (dispatch) => {
  if (!countryName) {
    dispatch({
      type: FETCH_COUNTRIES_INFO_SUCCESS,
      countryInfo: {
        currencies: [],
        cities: [],
      },
    });
    return;
  }
  dispatch({
    type: FETCH_COUNTRIES_INFO_LOADING,
  });

  const r = await Promise.allSettled([
    axios
      .post(`${API_URL.countries}/cities`, {
        country: countryName,
      })
      .then((res) => res.status === 200 && !res.data.error && res.data.data)
      .catch((error) => console.log("error", error)),
    axios
      .post(`${API_URL.countries}/currency`, {
        country: countryName,
      })
      .then(
        (res) => res.status === 200 && !res.data.error && res.data.data.currency
      )
      .catch((error) => console.log("error", error)),
  ]);

  const [cities, currency] = r.map((res) => res.value);
  dispatch({
    type: FETCH_COUNTRIES_INFO_SUCCESS,
    countryInfo: {
      currencies: [currency],
      cities,
    },
  });
};

export const createCity = (formData, seleectedCountryCode) => (dispatch) => {
  const accessToken = localStorage.getItem("access-token");
  return axios(API_URL.cities, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    data: {
      city_name: formData.cityName,
      city_code: formData.cityCode,
      country_code: seleectedCountryCode.country_code,
    },
  })
    .then((res) => {
      dispatch({ type: CREATE_CITY_SUCCESS });
      return Promise.resolve(res);
    })
    .catch((error) => Promise.reject(error));
};

export const deleteCity = (cityId, seleectedCountryCode) => (dispatch) => {
  const accessToken = localStorage.getItem("access-token");
  return axios(`${API_URL.cities}/${cityId}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    data: {
      id: seleectedCountryCode,
    },
  })
    .then((res) => {
      dispatch({ type: DELETE_CITY_SUCCESS });
      return Promise.resolve(res);
    })
    .catch((error) => Promise.reject(error));
};
