import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 420px;
  margin: 60px auto;
`;

export const Icon = styled.div`
  margin-bottom: 30px;
`;

export const Title = styled.h3`
  font-size: 38px;
  font-weight: 600;
`;

export const Form = styled.form`
  width: 100%;
`;

export const Text = styled.span`
  font-size: 18px;
  margin-bottom: 40px;
  display: block;
  line-height: 22px;
  margin-top: 20px;
  text-align: center;
  span {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

export const Link = styled.span`
  font-size: 16px;
  margin-top: 30px;
  display: block;
  color: #8e8e8e;
  display: flex;
  align-items: center;
`;

export const LinkText = styled.span`
  margin-left: 12px;
`;